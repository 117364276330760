import React from 'react';
import { Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { TextBlock } from '@/components/ui';
import type { TextProps } from '@/components/ui';

export type TextSectionProps = {
  data: TextProps;
} & FlexProps;

const TextSection: React.FC<TextSectionProps> = ({ data, ...rest }) => {
  return (
    <Flex {...rest}>
      <TextBlock {...data} maxWidth={{ base: '560px' }} />
    </Flex>
  );
};

export default TextSection;
