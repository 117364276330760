import React from 'react';
import NextLink from 'next/link';
import { Flex, Heading, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps, HeadingProps } from '@chakra-ui/core';

import { Button, ButtonGrid } from '@/components/ui';
import {
  locationTypeRefinements,
  getLocationName,
  generateRefinementAsUrl,
} from '@/utils/search';

import { LocationType } from '@/lib/graphql';
import * as textStyles from '@/theme/textStyles';

export type WhereAndWhenProps = {
  areasServiced: { name: string; type: LocationType }[];
  when?: string;
  formats: string[];
} & FlexProps;

const WhereAndWhen: React.FC<WhereAndWhenProps> = ({
  areasServiced,
  when,
  formats,
  children,
  ...rest
}) => {
  const Subheading: React.FC<HeadingProps> = (props) => (
    <Heading as="h3" {...textStyles.h4} {...props} />
  );

  return (
    <Flex
      backgroundColor="lately.background"
      direction="column"
      width="100%"
      {...rest}
    >
      <Stack spacing={{ base: 6, lg: 10 }}>
        <Heading as="h2" {...textStyles.h2}>
          Where and when
        </Heading>
        <Stack spacing={4}>
          <Subheading>Areas serviced:</Subheading>
          <ButtonGrid>
            {areasServiced.map((area) => (
              <NextLink
                key={area.name}
                href={generateRefinementAsUrl(
                  locationTypeRefinements[area.type],
                  getLocationName(area)
                )}
                passHref
              >
                <Button variant="feature" as={Link}>
                  {area.name}
                </Button>
              </NextLink>
            ))}
          </ButtonGrid>
        </Stack>
        {when && (
          <Stack spacing={2}>
            <Subheading>When:</Subheading>
            <Text {...textStyles.body} wordBreak="break-word">
              {when}
            </Text>
          </Stack>
        )}
        {formats.length > 0 && (
          <Stack spacing={4}>
            <Subheading>This service is provided:</Subheading>
            <ButtonGrid>
              {formats.map((format) => (
                <NextLink
                  key={format}
                  href={generateRefinementAsUrl(
                    'service_delivery_formats.name',
                    format
                  )}
                  passHref
                >
                  <Button variant="feature" as={Link}>
                    {format}
                  </Button>
                </NextLink>
              ))}
            </ButtonGrid>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
};

export default WhereAndWhen;
