import React from 'react';
import type { HeadingProps } from '@chakra-ui/core';
import { Flex, Heading } from '@chakra-ui/core';
import { connectStats } from 'react-instantsearch-dom';

import * as textStyles from '@/theme/textStyles';
import { Button } from '@/components/ui';

type CustomSearchResultsStatsProps = {
  nbHits: number;
  query?: string;
  onOpen: () => void;
} & HeadingProps;

export const CustomSearchResultsStats: React.FC<CustomSearchResultsStatsProps> = ({
  nbHits,
  query,
  onOpen,
  ...rest
}) => {
  if (nbHits === 0) return null;

  const resultsPluralityFor = nbHits === 1 ? 'result' : 'results';
  const resultsPluralityMatches =
    nbHits === 1 ? 'result matches' : 'results match';

  let resultsMessage = null;
  if (!query) {
    resultsMessage = `${nbHits} ${resultsPluralityMatches} your search`;
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-end"
      width="100%"
      marginBottom={6}
      marginTop={{ base: 6, lg: 0 }}
    >
      <Heading as="h5" {...textStyles.h5} fontWeight="normal" {...rest}>
        {resultsMessage || (
          <>
            {`${nbHits} ${resultsPluralityFor} for `}
            <strong>{`'${query}'`}</strong>
          </>
        )}
      </Heading>
      <Button onClick={onOpen} display={{ base: 'none', lg: 'block' }}>
        Map view
      </Button>
    </Flex>
  );
};

const SearchResultsStats = connectStats(CustomSearchResultsStats);

export default SearchResultsStats;
