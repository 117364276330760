/* eslint-disable react/no-array-index-key */
import React from 'react';
import NextLink from 'next/link';
import { Box, Image, Link, Stack, Text, useDisclosure } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { rem } from 'polished';
import gridConfig from '@/theme/grid';
import { Button, SurveyModal } from '@/components/ui';
import { FlexGrid, FlexGridRow, FlexGridItem } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';

export type FooterProps = {
  logoImageUrl: string;
  tagline: string;
  nominateButton: {
    label: string;
    href: string;
  };
  links: {
    leftColumn: {
      url: string;
      label: string;
    }[];
    rightColumn: {
      url: string;
      label: string;
    }[];
  };
  acknowledgement: string;
  awardsText: string;
  awardsBadgeURL: string;
  flagImages: { alt: string; url: string }[];
  copyright: string;
  funding: string;
  phnLogoImageUrl: string;
  surveyUrl: string;
  phnWebsiteUrl?: string;
} & FlexProps;

const Footer: React.FC<FooterProps> = React.memo(function Footer({
  logoImageUrl,
  tagline,
  nominateButton,
  links,
  acknowledgement,
  awardsText,
  awardsBadgeURL,
  flagImages,
  copyright,
  funding,
  phnLogoImageUrl,
  surveyUrl,
  phnWebsiteUrl,
  ...rest
}) {
  const surveyModal = useDisclosure();

  return (
    <>
      <FlexGrid
        as="footer"
        backgroundColor="lately.highlights"
        borderRadius={{ base: 0, lg: '2px' }}
        paddingX={{
          base: gridConfig.margin.base,
          md: gridConfig.margin.md,
          lg: `${parseFloat(gridConfig.margin.lg) / 2}rem`,
        }}
        paddingY={{ base: 8, md: 12, lg: 16 }}
        {...rest}
      >
        <FlexGridRow>
          <FlexGridItem colWidth={{ base: 4, md: 1, lg: 2 }}>
            <Image
              alt="Precious Time Logo"
              src={logoImageUrl}
              ignoreFallback
              height={rem('34px')}
              maxWidth="none"
            />
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 3, lg: 5 }}
            marginTop={{ base: 6, md: 0 }}
          >
            <Text {...textStyles.h4}>{tagline}</Text>
          </FlexGridItem>
          <FlexGridItem
            colOffset={1}
            colWidth={4}
            display={{ base: 'none', lg: 'inline-flex' }}
            flexWrap="wrap"
          >
            <Button
              as={Link}
              alignSelf="flex-start"
              marginRight={6}
              marginBottom={6}
              target="_blank"
              rel="noreferrer"
              {...{ href: nominateButton.href }}
            >
              {nominateButton.label}
            </Button>
            {surveyUrl && (
              <Button
                alignSelf="flex-start"
                variant="secondary"
                onClick={surveyModal.onOpen}
              >
                Give feedback
              </Button>
            )}
          </FlexGridItem>
        </FlexGridRow>
        <FlexGridRow marginTop={{ base: 0, md: 12 }}>
          {[links.leftColumn, links.rightColumn].map((column, i) => {
            return (
              <FlexGridItem
                key={i}
                colWidth={{ base: 2, md: 1, lg: 2 }}
                marginTop={{ base: 10, md: 0 }}
              >
                <Stack spacing={5} width="100%">
                  {column.map((link, j) => (
                    <Box key={j}>
                      <NextLink href={link.url} passHref>
                        <Link
                          {...textStyles.h5}
                          textDecoration="underline"
                          _hover={{ textDecoration: 'none' }}
                        >
                          {link.label}
                        </Link>
                      </NextLink>
                    </Box>
                  ))}
                </Stack>
              </FlexGridItem>
            );
          })}
          <FlexGridItem
            colWidth={{ base: 4, md: 2 }}
            display={{ base: 'inline-flex', lg: 'none' }}
            order={{ base: -1, md: 0 }}
            marginTop={{ base: 10, md: 0 }}
            flexWrap="wrap"
            alignContent="flex-start"
          >
            <Button
              as={Link}
              alignSelf="flex-start"
              marginRight={6}
              marginBottom={6}
              {...{ href: nominateButton.href }}
              target="_blank"
              rel="noreferrer"
            >
              {nominateButton.label}
            </Button>
            {surveyUrl && (
              <Button
                alignSelf="flex-start"
                variant="secondary"
                onClick={surveyModal.onOpen}
              >
                Give feedback
              </Button>
            )}
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 8, lg: 6 }}
            direction="column"
            marginTop={{ base: 6, md: 10, lg: 0 }}
          >
            <FlexGridRow>
              <FlexGridItem colWidth={{ base: 12 }} direction="column">
                <FlexGridRow>
                  <FlexGridItem
                    colWidth={{ base: 2, md: 2, lg: 2 }}
                    direction="column"
                    marginTop={{ base: 6, md: 10, lg: 0 }}
                  >
                    {awardsBadgeURL && (
                      <Image
                        key={awardsBadgeURL}
                        src={awardsBadgeURL}
                        ignoreFallback
                        flexShrink={0}
                        height={rem('90px')}
                        maxWidth={rem('120px')}
                      />
                    )}
                  </FlexGridItem>
                  <FlexGridItem
                    colWidth={{ base: 10, md: 10, lg: 10 }}
                    direction="column"
                    marginTop={{ base: 6, md: 10, lg: 0 }}
                  >
                    <Text>{awardsText}</Text>
                  </FlexGridItem>
                </FlexGridRow>
              </FlexGridItem>
              <FlexGridRow marginTop={{ base: 0, md: 12 }}>
                <FlexGridItem
                  colWidth={{ base: 4, md: 6, lg: 6 }}
                  direction="column"
                >
                  <Stack paddingRight={{ base: 0, md: 12 }} spacing={3}>
                    {acknowledgement.split('\n').map((paragraph, i) => (
                      <Text key={i} {...textStyles.smallData}>
                        {paragraph}
                      </Text>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    marginTop={{ base: 4, md: 6, lg: 6 }}
                    spacing={4}
                  >
                    {flagImages.map((flag) => (
                      <Image
                        key={flag.url}
                        alt={flag.alt}
                        src={flag.url}
                        ignoreFallback
                        flexShrink={0}
                        height={rem('32px')}
                      />
                    ))}
                  </Stack>
                </FlexGridItem>
                <FlexGridItem
                  colWidth={{ base: 4, md: 6, lg: 6 }}
                  direction="column"
                  marginTop={{ base: 8, md: 10, lg: 0 }}
                >
                  {phnWebsiteUrl ? (
                    <Link href={phnWebsiteUrl} target="_blank">
                      <Image
                        alt="North West Melbourne PHN Logo"
                        src={phnLogoImageUrl}
                        ignoreFallback
                        alignSelf="flex-start"
                        flexShrink={0}
                        height={rem('90px')}
                        marginBottom={6}
                      />
                    </Link>
                  ) : (
                    <Image
                      alt="North West Melbourne PHN Logo"
                      src={phnLogoImageUrl}
                      ignoreFallback
                      alignSelf="flex-start"
                      flexShrink={0}
                      height={rem('90px')}
                      marginBottom={6}
                    />
                  )}
                  <Text {...textStyles.smallData} marginBottom={4}>
                    {funding}
                  </Text>
                  <Text fontSize={rem('14px')} lineHeight={1.3}>
                    {copyright}
                  </Text>
                </FlexGridItem>
              </FlexGridRow>
            </FlexGridRow>
          </FlexGridItem>
        </FlexGridRow>
      </FlexGrid>
      {surveyUrl && (
        <SurveyModal
          isOpen={surveyModal.isOpen}
          onClose={surveyModal.onClose}
          surveyUrl={surveyUrl}
        />
      )}
    </>
  );
});

export default Footer;
