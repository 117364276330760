/** @jsx jsx */
import React from 'react';
import { rem } from 'polished';
import { css, jsx } from '@emotion/core';
import { useMediaQuery } from 'react-responsive';
import { Flex, Heading, Icon, ModalContent } from '@chakra-ui/core';

import { breakpoints, spacing } from '@/theme/chakra';
import { Button, PopularCategories, SearchInput } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import type { PopularCategoriesProps } from '@/components/ui';

type Props = {
  onClose: () => void;
  popularCategories: Omit<PopularCategoriesProps, 'numberOfCategoriesToShow'>;
  onSubmit: (query: string) => void;
};

const SearchModalContentDesktop: React.FC<Props> = ({
  onClose,
  popularCategories,
  onSubmit,
  ...rest
}) => {
  const isLgBreakpoint = useMediaQuery({
    query: `(min-width: ${breakpoints.lg})`,
  });
  const numberOfCategoriesToShow = isLgBreakpoint ? 9 : 6;

  // Use CSS calc with the modal margins to calculate the height/width
  // 100% height/width with margins would cause the modal to overflow the screen
  const margin = { base: 8, lg: 24 };
  const size = {
    base: `calc(100% - ${spacing['8']} * 2)`,
    lg: `calc(100% - ${spacing['24']} * 2)`,
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    onSubmit(formData.get('query') as string);
  };

  return (
    <ModalContent
      backgroundColor="lately.background"
      borderRadius="2px"
      marginX="auto"
      marginY={margin}
      height={size}
      maxWidth={rem('1036px')}
      width={size}
      {...rest}
    >
      <Flex
        alignItems="center"
        backgroundColor="lately.core"
        borderTopLeftRadius="2px"
        borderTopRightRadius="2px"
        color="lately.highlights03"
        direction="column"
        flexShrink={0}
        paddingY={{ base: 6, lg: 16 }}
        position="relative"
      >
        <Button
          aria-label="Close"
          onClick={onClose}
          borderRadius="2px"
          height={rem('48px')}
          paddingX={0}
          paddingY={0}
          position="absolute"
          right={{ base: 2, lg: 4 }}
          top={{ base: 2, lg: 4 }}
          width={rem('48px')}
        >
          <Icon name="close" color="white" size={rem('14px')} />
        </Button>
        <Heading as="h1" {...textStyles.h2}>
          What are you looking for?
        </Heading>
        <Flex
          as="form"
          onSubmit={handleSubmit}
          justifyContent="center"
          marginTop={{ base: 4, lg: 6 }}
          width="100%"
        >
          <SearchInput
            onSelectResult={onSubmit}
            inputPlaceholder="Search for service, topics or location"
            justifyContent="center"
            paddingX={{ base: rem('146px'), lg: 40 }}
            width="100%"
            isInModal
          />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        flexGrow={1}
        flexShrink={1}
        marginTop={1}
        overflowY="auto"
        paddingX={{ base: 10, lg: 16 }}
        paddingY={{ base: 4, lg: 8 }}
        css={css`
          // Fix padding not appearing at the end of overflow content in some browsers
          // https://nshki.com/fixing-overflow-padding-in-firefox/
          & > * > *:last-child {
            position: relative;
          }
          & > * > *:last-child::after {
            content: '';
            display: block;
            // Should match padding-bottom above
            height: ${spacing[4]};
            position: absolute;
            top: 100%;
            width: 1px;

            @media (min-width: ${breakpoints.lg}) {
              // Should match padding-bottom above
              height: ${spacing[8]};
            }
          }
        `}
      >
        <PopularCategories
          headingProps={{
            ...textStyles.h4,
            textAlign: 'center',
          }}
          numberOfCategoriesToShow={numberOfCategoriesToShow}
          isModal
          {...popularCategories}
        />
      </Flex>
    </ModalContent>
  );
};

export default SearchModalContentDesktop;
