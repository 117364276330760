import React, { useCallback } from 'react';
import Router from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { Modal, ModalOverlay } from '@chakra-ui/core';

import { breakpoints } from '@/theme/chakra';
import { ServiceAndCategorySearch } from '@/components/ui';
import { generateQueryState, searchStateToUrl } from '@/utils/search';
import type { PopularCategoriesProps } from '@/components/ui';

import {
  SearchModalContentMobile,
  SearchModalContentDesktop,
} from './components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  updateExistingSearch: boolean;
  popularCategories: Omit<PopularCategoriesProps, 'numberOfCategoriesToShow'>;
  mobileDefaultPage?: 1 | 2;
};

const SearchModal: React.FC<Props> = React.memo(function SearchModal({
  isOpen,
  onClose,
  updateExistingSearch,
  popularCategories,
  mobileDefaultPage = 1,
}) {
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.md})` });

  const onSubmit = useCallback(
    (query: string) => {
      let queryState;
      if (updateExistingSearch) {
        queryState = generateQueryState(query, Router.asPath);
      } else {
        queryState = generateQueryState(query, '/');
      }

      Router.push(searchStateToUrl('/search', queryState));
      window.scrollTo({ top: 0 });
      onClose();
    },
    [onClose]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="lately.black40%" />
      <ServiceAndCategorySearch>
        {isDesktop ? (
          <SearchModalContentDesktop
            onClose={onClose}
            popularCategories={popularCategories}
            onSubmit={onSubmit}
          />
        ) : (
          <SearchModalContentMobile
            onClose={onClose}
            popularCategories={popularCategories}
            defaultPage={mobileDefaultPage}
            onSubmit={onSubmit}
          />
        )}
      </ServiceAndCategorySearch>
    </Modal>
  );
});

export default SearchModal;
