import React from 'react';
import { rem } from 'polished';
import NextLink from 'next/link';
import {
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
} from '@chakra-ui/core';
import type { FlexProps, HeadingProps } from '@chakra-ui/core';

import { generateRefinementAsUrl } from '@/utils/search';
import { Button, ButtonGrid } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type VenueInformationProps = {
  name: string;
  address: string;
  accessibility: string[];
  transport?: string;
  hasLocation: boolean;
  onViewMap: () => void;
} & FlexProps;

const VenueInformation: React.FC<VenueInformationProps> = ({
  name,
  address,
  accessibility,
  transport,
  hasLocation,
  onViewMap,
  ...rest
}) => {
  const Subheading: React.FC<HeadingProps> = (props) => (
    <Heading as="h3" {...textStyles.h4} {...props} />
  );

  return (
    <Flex
      backgroundColor="lately.highlights"
      borderRadius={{ base: 0, md: '2px' }}
      color="lately.highlights03"
      direction="column"
      width="100%"
      {...rest}
    >
      <Stack spacing={{ base: 6, lg: 10 }}>
        <Flex>
          <Heading as="h2" flexGrow={1} {...textStyles.h2}>
            Venue information
          </Heading>
          <Icon name="location" size={rem('32px')} />
        </Flex>
        <Stack spacing={4}>
          {hasLocation && (
            <Flex
              backgroundImage={`url(${require('@/images/map-screenshot.png')})`}
              backgroundPosition="center"
              backgroundSize="cover"
              borderRadius="2px"
              padding={4}
            >
              <Button onClick={onViewMap}>View map</Button>
            </Flex>
          )}
          <Stack spacing={1}>
            <Subheading wordBreak="break-word">{name}</Subheading>
            <Text {...textStyles.body} wordBreak="break-word">
              {address}
            </Text>
          </Stack>
        </Stack>
        {accessibility.length > 0 && (
          <Stack spacing={4}>
            <Subheading>Accessibility:</Subheading>
            <ButtonGrid>
              {accessibility.map((a11y) => (
                <NextLink
                  key={a11y}
                  href={generateRefinementAsUrl(
                    'Venue.accessibilities.name',
                    a11y
                  )}
                  passHref
                >
                  <Button variant="feature" as={Link}>
                    {a11y}
                  </Button>
                </NextLink>
              ))}
            </ButtonGrid>
          </Stack>
        )}
      </Stack>
      {transport && (
        <Stack marginTop={rem('36px')} spacing={6}>
          <Divider
            borderColor="lately.highlights03"
            borderBottomWidth="2px"
            marginTop={0}
            opacity={0.2}
          />
          <Stack spacing={1}>
            <Subheading {...textStyles.h5}>Transport:</Subheading>
            <Text {...textStyles.body} wordBreak="break-word">
              {transport}
            </Text>
          </Stack>
        </Stack>
      )}
    </Flex>
  );
};

export default VenueInformation;
