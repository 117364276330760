import React from 'react';
import { rem } from 'polished';
import { connectPagination } from 'react-instantsearch-dom';
import { Flex, Text, Icon } from '@chakra-ui/core';
import { useRouter } from 'next/router';
import * as textStyles from '@/theme/textStyles';
import Button from '../Button';
import type { ButtonProps } from '../Button/Button';

// this function is so that we only every
// display a maximum on 3 pagination buttons
export const getPages = (currentPage: number, nbPages: number): number[] => {
  const pages: number[] = [];

  if (nbPages === 1 || nbPages === 0) return [];
  if (nbPages === 2) return [1, 2];

  // If there are at least 3 pages, figure out the start and end page to display
  let startPage = currentPage === 1 ? currentPage : currentPage - 1;
  const isLastPage = currentPage === nbPages;
  if (isLastPage) startPage = currentPage - 2;
  const endPage = nbPages >= 3 ? startPage + 2 : nbPages;

  // create array of 3 objects or less with page info of whether it is the current page,
  // and the number to display on the page button
  for (let i = startPage; i <= endPage; i += 1) {
    pages.push(i);
  }
  return pages;
};

const PageButton = ({ onClick, children, ...rest }: ButtonProps) => {
  return (
    <Button
      variant="secondary"
      textDecoration="underline"
      backgroundColor="lately.background"
      minWidth="48px"
      minHeight="48px"
      marginX="2px"
      padding={3}
      onClick={onClick}
      _hover={{
        backgroundColor: 'lately.core0210%',
        color: 'lately.highlights03',
        textDecoration: 'none',
      }}
      _active={{
        textDecoration: 'underline',
        backgroundColor: 'lately.highlights03',
        color: 'lately.background',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

type PaginationProps = {
  currentRefinement: number;
  nbPages: number;
  refine: (page: number) => void;
};

export const CustomPagination = ({
  currentRefinement,
  nbPages,
  refine,
  ...rest
}: PaginationProps) => {
  const router = useRouter();
  const goBackOnePage = () => {
    if (currentRefinement > 1) {
      refine(currentRefinement - 1);
      router.replace({
        query: { ...router.query, page: currentRefinement - 1 },
      });
      return;
    }
    refine(1);
  };

  const goForwardOnePage = () => {
    if (currentRefinement < nbPages) {
      refine(Number(currentRefinement) + 1);
      router.replace({
        query: { ...router.query, page: Number(currentRefinement) + 1 },
      });
      return;
    }
    refine(nbPages);
  };

  const pages = getPages(currentRefinement, nbPages);

  if (nbPages === 1 || nbPages === 0) {
    return null;
  }

  return (
    <Flex {...rest}>
      {currentRefinement !== 1 && (
        <PageButton
          aria-label="Go back one page"
          onClick={(event) => {
            event.preventDefault();
            goBackOnePage();
            window.scrollTo({ top: 0 });
          }}
        >
          <Icon name="arrowBack" size={rem('24px')} />
        </PageButton>
      )}
      {pages.map((pageNumber) => {
        return (
          <PageButton
            key={pageNumber}
            isActive={pageNumber === currentRefinement}
            onClick={(event) => {
              event.preventDefault();
              refine(pageNumber);
              router.replace({
                query: { ...router.query, page: pageNumber },
              });
              window.scrollTo({ top: 0 });
            }}
          >
            <Text {...textStyles.body} lineHeight={1}>
              {pageNumber}
            </Text>
          </PageButton>
        );
      })}
      {currentRefinement !== nbPages && (
        <PageButton
          aria-label="Go forward one page"
          onClick={(event) => {
            event.preventDefault();
            goForwardOnePage();
            window.scrollTo({ top: 0 });
          }}
        >
          <Icon name="arrowForward" size={rem('24px')} />
        </PageButton>
      )}
    </Flex>
  );
};

const Pagination = connectPagination(CustomPagination);

export default Pagination;
