import React from 'react';
import { rem } from 'polished';
import NextLink from 'next/link';
import { Flex, Heading, Icon, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import getPlainText from '@/utils/getPlainText';
import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import { useSavedContent } from '@/components/providers';
import { SavedContentState, SavedContentType } from '@/utils/saved-content';

type Props = {
  id: string;
  href: string;
  title: string;
  provider: string;
  description: string;
  onClose: () => void;
} & FlexProps;

const SearchResultsMapCard: React.FC<Props> = React.forwardRef(
  ({ id, href, title, provider, description, onClose, ...rest }, ref) => {
    // Get the saved state of the service
    const savedContent = useSavedContent();
    const [savedState, onToggleSave] = savedContent.getItemState(
      id,
      SavedContentType.Service
    );

    return (
      <Flex
        ref={ref}
        backgroundColor="lately.background"
        direction="column"
        flexShrink={0}
        {...rest}
      >
        <Button
          aria-label="Close"
          variant="unstyled"
          onClick={onClose}
          borderRadius="2px"
          display="flex"
          height={rem('48px')}
          marginLeft="auto"
          width={rem('48px')}
          zIndex={1}
        >
          <Icon name="close" size={rem('14px')} />
        </Button>
        <Flex direction="column" padding={6} paddingTop={0}>
          <Stack spacing={2}>
            <Heading as="h1" {...textStyles.h5}>
              {title}
            </Heading>
            <Heading as="h2" opacity={0.7} {...textStyles.body}>
              Provider: {provider}
            </Heading>
            <Text {...textStyles.body}>{getPlainText(description, 200)}</Text>
          </Stack>
          <Flex marginTop={4}>
            <NextLink href={href} passHref>
              <Button as="a" marginRight={2}>
                View service
              </Button>
            </NextLink>
            <Button
              variant="secondary"
              isLoading={savedState === SavedContentState.Pending}
              onClick={onToggleSave}
            >
              {savedState === SavedContentState.None ? 'Save' : 'Remove'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

export default SearchResultsMapCard;
