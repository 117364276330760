import React from 'react';
import { Box, Flex, Heading, Image } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { rem } from 'polished';
import gridConfig from '@/theme/grid';
import * as textStyles from '@/theme/textStyles';

type Props = {
  title: string;
  illustrationDesktopHeader?: string;
  illustrationMobileHeader?: string;
} & FlexProps;

const HubHeader: React.FC<Props> = ({
  title,
  illustrationDesktopHeader,
  illustrationMobileHeader,
  ...rest
}) => {
  return (
    <Flex
      alignItems="stretch"
      backgroundColor="lately.core"
      borderRadius={{ base: 0, lg: '2px' }}
      color="lately.text"
      direction={{ base: 'column', md: 'row' }}
      width="100%"
      {...rest}
    >
      <Box
        marginRight="auto"
        paddingX={{
          base: gridConfig.margin.base,
          md: gridConfig.margin.md,
          lg: `${parseFloat(gridConfig.margin.lg) / 2}rem`,
        }}
        width="100%"
        paddingY={{ base: 6, md: 8, lg: 10 }}
      >
        <Heading as="h1" {...textStyles.h1} wordBreak="break-word">
          {title}
        </Heading>
      </Box>
      {/* Mobile image */}
      <Flex display={{ base: 'flex', md: 'none' }}>
        <Box
          backgroundImage={
            illustrationMobileHeader
              ? `url(${illustrationMobileHeader})`
              : `url(${require('@/images/illustration-page-header-small.svg')})`
          }
          backgroundPosition="100% 100%"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          height={rem('80px')}
          width="100%"
        />
      </Flex>
      {/* Desktop image */}
      <Flex
        display={{ base: 'none', md: 'flex' }}
        flexShrink={0}
        maxWidth={{ base: '0px', md: rem('263px'), lg: rem('357px') }}
      >
        <Image
          borderTopRightRadius={{ base: 0, lg: '2px' }}
          borderBottomRightRadius={{ base: 0, lg: '2px' }}
          alt=""
          src={
            illustrationDesktopHeader ||
            require('@/images/illustration-page-header-large.svg')
          }
          ignoreFallback
        />
      </Flex>
    </Flex>
  );
};

export default HubHeader;
