import React from 'react';
import type { FlexProps } from '@chakra-ui/core';

import { Image } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import type { ImageProps } from '@/components/ui';

export type ImageSectionProps = {
  data: {
    image?: ImageProps;
  };
} & FlexProps;

const ImageSection: React.FC<ImageSectionProps> = ({
  data: { image },
  ...rest
}) => {
  if (!image) return null;
  return (
    <FlexGridRow {...rest}>
      <FlexGridItem
        colWidth={{ base: 4, md: 2, lg: 7 }}
        pr={{ base: 0, md: 8, lg: 16 }}
        flexDirection="column"
      >
        <Image {...image} />
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default ImageSection;
