import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

type Options =
  | {
      limit: 'none';
    }
  | {
      limit: 'debounce';
      wait: number;
      settings?: {
        leading?: boolean;
        maxWait?: number;
        trailing?: boolean;
      };
    }
  | {
      limit: 'throttle';
      wait: number;
      settings?: {
        leading?: boolean;
        trailing?: boolean;
      };
    };

// This hook returns the current viewport height, to be used in CSS
// It will return "100vh" server-side or if the browser doesn't support the Visual Viewport API
// If it does support the API, this hook will listen to the resize event and return the height in pixels
// The main use is to get the height of the browser on mobile excluding the browser UI (e.g. bottom bar and on-screen keyboard)
// https://developer.mozilla.org/en-US/docs/Web/API/VisualViewport
const useViewportHeight = (options: Options = { limit: 'none' }) => {
  const [viewportHeight, setViewportHeight] = useState('100vh');

  useEffect(() => {
    if (!window.visualViewport) {
      return undefined;
    }

    const onResize = () => {
      const height = window?.visualViewport?.height;
      if (height) {
        setViewportHeight(`${height}px`);
      }
    };
    onResize();

    let cb: () => void;
    switch (options.limit) {
      case 'debounce':
        cb = debounce(onResize, options.wait, options.settings);
        break;
      case 'throttle':
        cb = throttle(onResize, options.wait, options.settings);
        break;
      default:
        cb = onResize;
        break;
    }

    window.visualViewport.addEventListener('resize', cb);
    return () => {
      window?.visualViewport?.removeEventListener('resize', cb);
    };
  }, []);

  return viewportHeight;
};

export default useViewportHeight;
