import React from 'react';
import { Flex, ListIcon, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import { rem } from 'polished';

type Props = FlexProps;

const BulletpointListItem: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex as="li" {...rest}>
      <ListIcon icon="bullet" size={rem('24px')} />
      <Text display="inline">{children}</Text>
    </Flex>
  );
};

export default BulletpointListItem;
