import React from 'react';
import type { ReactHTML } from 'react';
import { Box, Heading, Stack } from '@chakra-ui/core';
import type { StackProps } from '@chakra-ui/core';
import Markdown from 'markdown-to-jsx';

import { assertNever } from '@/utils/types';
import * as textStyles from '@/theme/textStyles';
import { MarkdownComponent } from '@/components/ui';

const getHeadingStyle = (style: TextProps['headingStyle']) => {
  switch (style) {
    case 'h2':
      return { fontStyle: textStyles.h2, marginBottom: 6 };
    case 'h3':
      return { fontStyle: textStyles.h3, marginBottom: 4 };
    case 'h4':
      return { fontStyle: textStyles.h4, marginBottom: 4 };
    default:
      return assertNever(style);
  }
};

export type TextProps = {
  headingStyle: 'h2' | 'h3' | 'h4';
  headingColor?: string;
  heading: string;
  body: string;
};

type TextBlockProps = TextProps & StackProps;

const TextBlock: React.FC<TextBlockProps> = ({
  headingStyle,
  headingColor,
  heading,
  body,
  ...rest
}) => {
  const styles = getHeadingStyle(headingStyle);
  return (
    <Stack
      width="100%"
      flexDirection="column"
      spacing={styles.marginBottom}
      {...rest}
    >
      <Heading as={headingStyle} color={headingColor} {...styles.fontStyle}>
        {heading}
      </Heading>
      <Box marginBottom={-3}>
        <Markdown
          options={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            createElement(type, props: any, children) {
              const { key } = props;
              return (
                <MarkdownComponent
                  type={type as keyof ReactHTML}
                  key={key}
                  props={props}
                  h2Props={{ marginBottom: 6 }}
                  h3Props={{ marginBottom: 4 }}
                >
                  {children}
                </MarkdownComponent>
              );
            },
          }}
        >
          {body}
        </Markdown>
      </Box>
    </Stack>
  );
};

export default TextBlock;
