import React from 'react';
import reactFastCompare from 'react-fast-compare';
import { connectRefinementList } from 'react-instantsearch-dom';
import { Box, Text, Checkbox } from '@chakra-ui/core';
import type { RefinementListProvided } from 'react-instantsearch-core';

import * as textStyles from '@/theme/textStyles';

type RefinementListProps = {
  isOpen: boolean;
} & Pick<RefinementListProvided, 'items' | 'refine'>;

const RefinementList: React.FC<RefinementListProps> = React.memo(
  function RefinementList({ isOpen, items, refine }) {
    if (!isOpen) {
      return null;
    }

    if (items.length === 0) {
      return (
        <Text {...textStyles.body} color="lately.highlights03">
          No applicable filters
        </Text>
      );
    }

    return (
      <Box>
        {items.map((item) => (
          <Box key={item.label}>
            <Checkbox
              isChecked={item.isRefined}
              onChange={() => refine(item.value)}
              variantColor="highlights03Numbered"
              borderColor="lately.highlights03"
              size="lg"
              alignItems="baseline"
              marginY={2}
            >
              <Text {...textStyles.body} color="lately.highlights03">
                {item.label} ({item.count})
              </Text>
            </Checkbox>
          </Box>
        ))}
      </Box>
    );
  },
  reactFastCompare
);

const ConnectedRefinementList = connectRefinementList<RefinementListProps>(
  function ConnectedRefinementList({ isOpen, items, refine }) {
    return <RefinementList isOpen={isOpen} items={items} refine={refine} />;
  }
);

export default ConnectedRefinementList;
