import type { SearchState } from '@/utils/search';

const getFilterState = (
  searchState: SearchState
): { isFiltered: boolean; numberOfFilters: number } => {
  const { refinementList } = searchState;
  if (searchState === null || searchState === undefined || !refinementList) {
    return {
      isFiltered: false,
      numberOfFilters: 0,
    };
  }

  let allFilters: string[] = [];
  // Create one array of all the filter groups as strings
  const filterGroups = Object.keys(refinementList).filter(
    (key) => refinementList[key as keyof typeof refinementList] !== ''
  ) as (keyof typeof refinementList)[];

  filterGroups.forEach((filterCategory) => {
    // when there's only one filter from a group selected it is a string, not an array
    const val = refinementList[filterCategory];
    if (val === undefined) {
      return;
    }
    if (typeof val === 'string') {
      allFilters = [...allFilters, val];
      return;
    }
    allFilters = [...allFilters, ...val];
  });

  return {
    isFiltered: allFilters.length > 0,
    numberOfFilters: allFilters.length,
  };
};

export default getFilterState;
