import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Box, Flex, Text, Icon, Link } from '@chakra-ui/core';
import throttle from 'lodash.throttle';
import type { FlexProps } from '@chakra-ui/core';
import NextLink from 'next/link';
import { rem } from 'polished';

import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import type { AccordionItemDataType, NavItemType } from '@/utils/types';

type NavItemProps = {
  navItem: NavItemType;
  isActive: boolean;
  hasBadge?: boolean;
} & FlexProps;

type NavItemSelectProps = {
  isOpen: boolean;
  onSetOpen: (name: string, open: boolean) => void;
} & NavItemProps;

export const NavItemLink: React.FC<NavItemProps> = React.memo(
  function NavItemLink({ navItem, isActive, hasBadge = false, ...rest }) {
    return (
      <Flex
        key={navItem.name}
        borderBottomWidth="3px"
        borderBottomColor={
          isActive ? 'lately.highlights03' : 'lately.background'
        }
        borderBottomStyle="solid"
        height="100%"
        alignItems="center"
        mx={rem('15px')}
        {...rest}
      >
        <NextLink href={navItem.url ?? '/404'} passHref>
          <Link
            href="/"
            aria-label={navItem.name}
            color="lately.core02"
            _hover={{
              textDecoration: 'none',
              color: 'lately.highlights03',
            }}
            display="flex"
            position="relative"
          >
            <Text {...textStyles.h5} display="inline" whiteSpace="nowrap">
              {navItem.name}
            </Text>
            {hasBadge && (
              <Box
                backgroundColor="lately.secondary01"
                paddingX={rem('3px')}
                height={rem('6px')}
                borderRadius="100%"
                position="absolute"
                right={rem('-8px')}
                width={rem('6px')}
              />
            )}
          </Link>
        </NextLink>
      </Flex>
    );
  }
);

const NavItemSelectList: React.FC<{
  categories: AccordionItemDataType[];
  onClose: () => void;
}> = React.memo(function NavItemSelectList({ categories, onClose }) {
  return (
    <>
      {categories.map((option, index) => {
        const isLastItem = index === categories.length - 1;
        return (
          <Box width="100%" key={option.name}>
            <NextLink href={option.url ?? '/404'} passHref>
              <Link
                onClick={onClose}
                _hover={{
                  backgroundColor: 'lately.core02',
                  color: 'lately.background',
                }}
                _focus={{
                  backgroundColor: 'lately.core02',
                  color: 'lately.background',
                }}
                width="100%"
                height="100%"
                display="block"
                px={6}
                py={rem('14px')}
                {...textStyles.smallData}
                fontSize={rem('15px')}
                borderBottomRightRadius={isLastItem ? '2px' : '0px'}
                borderBottomLeftRadius={isLastItem ? '2px' : '0px'}
              >
                {option.name}
              </Link>
            </NextLink>
          </Box>
        );
      })}
    </>
  );
});

export const NavItemSelect: React.FC<NavItemSelectProps> = React.memo(
  function NavItemSelect({ navItem, isActive, isOpen, onSetOpen, ...rest }) {
    const onToggle = () => {
      const shouldBeOpen = !isOpen;
      onSetOpen(navItem.name, shouldBeOpen);
    };
    const onClose = () => {
      onSetOpen(navItem.name, false);
    };
    // When the dropdown is opened, calculate a maximum height for the scrollable element
    // This should be the combined height of the first 8 items in the list
    const scrollEl = useRef<HTMLDivElement | undefined>(undefined);
    const [dropdownHeight, setDropdownHeight] = useState(0);
    useLayoutEffect(() => {
      if (!scrollEl.current) return;
      if (!isOpen) {
        setDropdownHeight(0);
        return;
      }

      // Get the first 8 elements in the dropdown and combine their heights
      scrollEl.current.scrollTo({ top: 0 });
      const firstEightItems = Array.from(scrollEl.current.children).slice(0, 8);
      setDropdownHeight(
        firstEightItems.reduce((height, el) => height + el.clientHeight, 0)
      );
    }, [isOpen, scrollEl.current]);

    // When the scrollable element is scrolled, calculate whether it can scroll further down or not
    const [canScrollDown, setCanScrollDown] = useState(true);
    useEffect(() => {
      if (!scrollEl.current) return undefined;
      if (!isOpen) return undefined;

      const onScroll = () => {
        if (!scrollEl.current) return;
        const { scrollTop, scrollHeight, offsetHeight } = scrollEl.current;
        setCanScrollDown(scrollTop < scrollHeight - offsetHeight);
      };
      onScroll();

      const throttledScroll = throttle(onScroll, 100);
      scrollEl.current.addEventListener('scroll', throttledScroll);
      return () => {
        scrollEl.current?.removeEventListener('scroll', throttledScroll);
      };
    }, [isOpen, scrollEl.current]);

    // When the scroll button is pressed, scroll further down if we can or scroll back to the top
    const onScrollPressed = useCallback(() => {
      if (!scrollEl.current) return;

      if (canScrollDown) {
        scrollEl.current.scrollBy({ top: dropdownHeight, behavior: 'smooth' });
      } else {
        scrollEl.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, [canScrollDown, dropdownHeight]);

    return (
      <Flex
        position="relative"
        key={navItem.name}
        display="flex"
        alignItems="center"
        borderBottomWidth="3px"
        borderBottomColor={
          isActive ? 'lately.highlights03' : 'lately.background'
        }
        borderBottomStyle="solid"
        height="100%"
        mx={rem('15px')}
        {...rest}
      >
        <Button
          flexDirection="row"
          variant="unstyled"
          color="lately.core02"
          display="flex"
          alignItems="center"
          onClick={onToggle}
          borderRadius="0"
        >
          <Text
            {...textStyles.h5}
            display={{
              sm: 'none',
              md: 'inline',
            }}
            whiteSpace="nowrap"
          >
            {navItem.name}
          </Text>
          <Icon
            name="accordionChevron"
            size="3"
            ml={2}
            display={{
              sm: 'none',
              md: 'inline',
            }}
            transform={isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
            transition="all 0.2s"
          />
        </Button>
        <Flex
          direction="column"
          display={isOpen ? 'block' : 'none'}
          position="absolute"
          top={{ md: rem('80px'), lg: rem('96px') }}
          left="1px"
          width={rem('305px')}
          zIndex={10}
        >
          <Box
            ref={scrollEl}
            border="none"
            borderBottomRightRadius="2px"
            borderBottomLeftRadius="2px"
            maxHeight={`${dropdownHeight}px`}
            overflowY="auto"
            backgroundColor="lately.background"
          >
            {isOpen ? (
              <NavItemSelectList
                categories={navItem.links ?? []}
                onClose={onClose}
              />
            ) : null}
          </Box>
          {navItem.links && navItem.links.length > 8 && (
            <Button
              aria-hidden="true"
              onClick={onScrollPressed}
              borderTopLeftRadius={0}
              borderTopRightRadius={0}
              height={rem('48px')}
              padding={0}
              width="100%"
            >
              <Icon
                name="accordionChevron"
                size={rem('12px')}
                transform={canScrollDown ? 'rotate(180deg)' : 'rotate(0deg)'}
              />
            </Button>
          )}
        </Flex>
      </Flex>
    );
  }
);
