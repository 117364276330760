/** @jsx jsx */
import React from 'react';
import { rem } from 'polished';
import { Flex } from '@chakra-ui/core';
import { css, jsx } from '@emotion/core';
import type { FlexProps } from '@chakra-ui/core';

type Props = FlexProps;

// Component to wrap buttons over multiple lines with 8px between each one
const ButtonGrid: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex
      flexWrap="wrap"
      css={css`
        margin: -${rem('4px')};

        & > * {
          margin: ${rem('4px')};
        }
      `}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default ButtonGrid;
