import React from 'react';
import NextLink from 'next/link';
import { rem } from 'polished';
import { connectStateResults, connectHits } from 'react-instantsearch-dom';
import type {
  HitsProvided,
  StateResultsProvided,
} from 'react-instantsearch-core';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Skeleton,
} from '@chakra-ui/core';
import { parseISO, isAfter } from 'date-fns';
import reactFastCompare from 'react-fast-compare';

import { generateRefinementAsUrl } from '@/utils/search';
import { useSavedContent } from '@/components/providers';
import { SavedContentType } from '@/utils/saved-content';
import type { PopularCategoriesProps } from '@/utils/types';

import * as textStyles from '@/theme/textStyles';
import SearchResultCard, { SearchResultTypes } from './SearchResultCard';

export type ServiceHit = {
  type: 'Services';
  id: number;
  objectID: string;
  title?: string;
  provider?: string;
  description?: string;
  slug?: string;
  published_at?: string;
  _geoloc?: { lat: number; lng: number };
  areas_serviced?: {
    created_at: string;
    created_by: string | null;
    id: number;
    name: string;
    published_at: string;
    updated_at: string;
    updated_by: string | null;
  }[];
  EmailAddresses?: {
    email_address: string;
    id: number;
  }[];
  PhoneNumbers?: {
    phone_number: string;
    id: number;
  }[];
};

type FAQHit = {
  type: 'Answers';
  id: number;
  objectID: string;
  question?: string;
  slug?: string;
};

type CustomHit = ServiceHit | FAQHit;

type HitProps = {
  hit: CustomHit;
  getSavedState: ReturnType<typeof useSavedContent>['getItemState'];
};

const Hit: React.FC<HitProps> = React.memo(function Hit({
  hit,
  getSavedState,
}) {
  switch (hit.type) {
    case 'Services': {
      const email =
        !!hit.EmailAddresses && hit.EmailAddresses.length > 0
          ? hit.EmailAddresses[0].email_address
          : undefined;
      const phone =
        !!hit.PhoneNumbers && hit.PhoneNumbers.length > 0
          ? hit.PhoneNumbers[0].phone_number
          : undefined;

      // Calculate whether the result was published within the past 2 weeks
      const dateTwoWeeksAgo = new Date();
      dateTwoWeeksAgo.setDate(dateTwoWeeksAgo.getDate() - 14);
      const isNewlyAdded =
        !!hit.published_at &&
        isAfter(parseISO(hit.published_at), dateTwoWeeksAgo);

      // Get the saved state of the service
      const [savedState, onToggleSave] = getSavedState(
        String(hit.id),
        SavedContentType.Service
      );

      return (
        <Box width="100%" key={hit.objectID}>
          <SearchResultCard
            resultType={SearchResultTypes.Services}
            isNewlyAdded={isNewlyAdded}
            name={hit.title ?? ''}
            description={hit.description ?? ''}
            url={hit.slug ? `/services/${hit.slug}` : '/404'}
            locations={
              hit.areas_serviced
                ? hit.areas_serviced.map((area) => area.name)
                : []
            }
            email={email}
            phone={phone}
            savedState={savedState}
            onToggleSave={onToggleSave}
          />
        </Box>
      );
    }
    case 'Answers': {
      // Get the saved state of the FAQ
      const [savedState, onToggleSave] = getSavedState(
        String(hit.id),
        SavedContentType.Faq
      );

      return (
        <Box width="100%" key={hit.objectID}>
          <SearchResultCard
            resultType={SearchResultTypes.Questions}
            isNewlyAdded
            name={hit.question ?? ''}
            description=""
            url={hit.slug ? `/faq/${hit.slug}` : '/404'}
            savedState={savedState}
            onToggleSave={onToggleSave}
          />
        </Box>
      );
    }
    default:
      return null;
  }
},
reactFastCompare);

type HitsProps = HitsProvided<CustomHit>;

const Hits: React.FC<HitsProps> = React.memo(function Hits({ hits }) {
  const savedContent = useSavedContent();
  return (
    <>
      {hits.map((h) => {
        return (
          <Hit
            key={h.objectID}
            hit={h}
            getSavedState={savedContent.getItemState}
          />
        );
      })}
    </>
  );
}, reactFastCompare);

const ConnectedHits = connectHits<HitsProps, CustomHit>(function ConnectedHits(
  props
) {
  return <Hits {...props} />;
});

const SkeletonHits: React.FC<{ listLength: number }> = React.memo(
  function SkeletonHits({ listLength }) {
    const skeletonResults = [];

    for (let i = 0; i < listLength; i += 1) {
      skeletonResults.push(
        <Box width="100%" key={i} marginTop={{ base: 6, md: 10 }}>
          <Divider
            color="lately.core0210%"
            marginTop={0}
            marginBottom={{ base: 6, md: 10 }}
          />
          <Flex>
            <Skeleton width="90px" height="30px" marginY={2} mr={2} speed={3} />
            <Skeleton width="120px" height="30px" marginY={2} speed={3} />
          </Flex>

          <Skeleton height="20px" marginY={2} speed={3} />
          <Skeleton height="20px" marginY={2} speed={3} />
          <Skeleton height="20px" marginY={2} speed={3} />

          <Flex marginTop={{ base: 6, md: 10 }} marginBottom={-2}>
            <Skeleton
              width={40}
              height={12}
              marginBottom={2}
              mr={2}
              speed={3}
            />
            <Skeleton width={40} height={12} marginBottom={2} speed={3} />
          </Flex>
        </Box>
      );
    }

    return (
      <>
        <Heading as="h3" {...textStyles.h3} marginY={{ base: 6, md: 10 }}>
          Loading search results...
        </Heading>
        <Box marginTop={{ base: -6, md: -10 }}>{skeletonResults}</Box>
      </>
    );
  }
);

type NoResultsSuggestionsListProps = Pick<
  PopularCategoriesProps,
  'popularCategories'
>;

const NoResultsSuggestionsList: React.FC<NoResultsSuggestionsListProps> = React.memo(
  function NoResultsSuggestionsList({ popularCategories }) {
    return (
      <List styleType="disc">
        {popularCategories.map((category) => {
          return (
            <ListItem key={category.id} marginBottom={4}>
              <NextLink
                href={generateRefinementAsUrl(
                  'service_types.name',
                  category.name
                )}
                passHref
              >
                <Link
                  {...textStyles.body}
                  textDecoration="underline"
                  _hover={{ textDecoration: 'none' }}
                >
                  {category.name}
                </Link>
              </NextLink>
            </ListItem>
          );
        })}
      </List>
    );
  }
);

type StateResultsProps = StateResultsProvided &
  Pick<PopularCategoriesProps, 'popularCategories'>;

// Search results dropdown contents
const StateResults: React.FC<StateResultsProps> = React.memo(
  function StateResults({
    searchState,
    searchResults,
    searching,
    error,
    popularCategories,
  }) {
    const hasResults = searchResults && searchResults.nbHits !== 0;
    const hasQueryNoResults = !!searchState.query && !hasResults;

    if (hasResults) {
      return <ConnectedHits />;
    }

    if (searching) {
      return <SkeletonHits listLength={5} />;
    }

    // Show list of popular category items if there are no matching search results.
    if (hasQueryNoResults) {
      return (
        <>
          <Heading
            as="h4"
            {...textStyles.h4}
            marginTop={{ base: 6, lg: 0 }}
            marginBottom={{ base: 10, lg: 16 }}
          >
            {`Sorry, we did not find anything for '${searchState.query}'. Try a new search or browse:`}
          </Heading>
          <NoResultsSuggestionsList popularCategories={popularCategories} />
        </>
      );
    }

    // No results fallback if there are no matching search results.
    if (!hasResults) {
      return (
        <>
          <Heading
            as="h4"
            {...textStyles.h4}
            marginTop={{ base: 6, lg: 0 }}
            marginBottom={{ base: 10, lg: 16 }}
          >
            Sorry, we did not find any results. Try a new search or browse:
          </Heading>
          <NoResultsSuggestionsList popularCategories={popularCategories} />
        </>
      );
    }

    if (error) {
      return (
        <Box
          width="100%"
          height="100%"
          display="block"
          px={6}
          py={rem('14px')}
          {...textStyles.smallData}
          fontSize={rem('15px')}
          borderBottomRightRadius="0px"
          borderBottomLeftRadius="0px"
        >
          <Heading as="h3" {...textStyles.h3} marginY={{ base: 6, md: 10 }}>
            There was an error while completing your search
          </Heading>
        </Box>
      );
    }
    return null;
  }
);

const ConnectedStateResults = connectStateResults<StateResultsProps>(
  function ConnectedStateResults(props) {
    return <StateResults {...props} />;
  }
);

export default ConnectedStateResults;
