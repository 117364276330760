import React from 'react';
import { Link } from '@chakra-ui/core';
import Button from '../Button';

export type FeedbackButtonProps = {
  url: string;
};
const FeedbackButton = ({ url }: FeedbackButtonProps) => {
  return (
    <Button
      as={Link}
      target="_blank"
      leftIcon="info"
      href={url}
      position="fixed"
      bottom="0"
      right="5"
      borderTopLeftRadius="lg"
      borderTopRightRadius="lg"
    >
      Give Feedback
    </Button>
  );
};

export default FeedbackButton;
