/** @jsx jsx */
import React from 'react';
import NextLink from 'next/link';
import { jsx } from '@emotion/core';
import { Flex, Heading, Link } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { FlexGridRow, FlexGridItem } from '@/components/layout';
import { Button, BlogTile } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import type { BlogTileProps } from '@/components/ui';

type Props = {
  heading?: string;
  blogPosts: BlogTileProps[];
  button: { label?: string; href?: string };
} & FlexProps;

const BlogPostsSection: React.FC<Props> = ({
  heading,
  blogPosts,
  button,
  ...rest
}) => {
  return (
    <FlexGridRow {...rest}>
      <FlexGridItem direction="column">
        {heading && (
          <Heading as="h2" {...textStyles.h2}>
            {heading}
          </Heading>
        )}
        <Flex flexWrap="wrap" marginX={-4} marginY={{ base: 2, md: 4, lg: 5 }}>
          {blogPosts.map((blog, i) => (
            <BlogTile
              // eslint-disable-next-line react/no-array-index-key
              key={`${blog.title}-${i}`}
              {...blog}
              marginX={4}
              marginY={{ base: 6, lg: 10 }}
            />
          ))}
        </Flex>
        {button.href && button.label && (
          <Flex width="100%" justifyContent="start">
            <NextLink href={button.href} passHref>
              <Button as={Link} variant="secondary">
                {button.label}
              </Button>
            </NextLink>
          </Flex>
        )}
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default BlogPostsSection;
