import React, { useState, useEffect } from 'react';
import { rem } from 'polished';
import {
  Box,
  Text,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Flex,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import type { SearchState } from '@/utils/search';
import { Button, FilterResults, ServiceSearch } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import getFilterState from '@/utils/getFilterState';
import useViewportHeight from '@/utils/useViewportHeight';
import CurrentRefinements from '../CurrentRefinements';

type FilterResultsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  updatePageSearchState: (searchState: SearchState) => void;
  pageSearchState: SearchState;
} & FlexProps;

const FilterResultsDrawer: React.FC<FilterResultsDrawerProps> = ({
  isOpen,
  onClose,
  updatePageSearchState,
  pageSearchState,
}) => {
  // Create a local copy of the page search state
  const [searchState, setSearchState] = useState(pageSearchState);

  // Keep the local state current when the page state changes
  useEffect(() => {
    setSearchState(pageSearchState);
  }, [pageSearchState]);

  // Update the page search state when the user presses "View results"
  const onViewResults = () => {
    updatePageSearchState(searchState);
    onClose();
  };

  // Clear any search state changes when the user presses "Cancel"
  const onCancel = () => {
    setSearchState(pageSearchState);
    onClose();
  };

  // Check if the results are filtered
  const { isFiltered } = getFilterState(searchState);

  // Use the viewport height so the footer buttons aren't covered by mobile browser UI
  const viewportHeight = useViewportHeight({ limit: 'debounce', wait: 50 });

  return (
    <Box maxWidth="200px">
      <Drawer placement="left" onClose={onCancel} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          bg="lately.background"
          height={`${viewportHeight} !important`}
          maxWidth={{ base: '100%', md: rem('370px') }}
        >
          <ServiceSearch
            searchState={searchState}
            onSearchStateChange={setSearchState}
          >
            {isFiltered ? (
              <DrawerHeader
                borderBottomWidth="0px"
                borderBottom="none"
                paddingX={4}
                paddingY={8}
              >
                <Text
                  {...textStyles.h5}
                  color="lately.highlights03"
                  marginBottom={4}
                >
                  Active filters:
                </Text>
                <CurrentRefinements />
              </DrawerHeader>
            ) : null}
            <DrawerBody p={4} overflowY="auto">
              <FilterResults />
            </DrawerBody>
            <DrawerFooter>
              <Flex justifyContent="space-between" width="100%">
                <Button variant="secondary" onClick={onCancel}>
                  <Icon name="close" width="14px" marginRight={2} />
                  Cancel
                </Button>
                <Button onClick={onViewResults}>View results</Button>
              </Flex>
            </DrawerFooter>
          </ServiceSearch>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default FilterResultsDrawer;
