import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import type {
  CurrentRefinementsProvided,
  RefinementValue,
} from 'react-instantsearch-core';
import { Flex, Icon } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Button, ClearFiltersButton } from '@/components/ui';

type CustomCurrentRefinementsProps = CurrentRefinementsProvided & FlexProps;

const CustomCurrentRefinements: React.FC<CustomCurrentRefinementsProps> = ({
  items,
  refine,
  ...rest
}) => {
  const itemLabels: { label: string; refineValue: RefinementValue }[] = [];
  items.forEach((item) => {
    if (item.items) {
      item.items.forEach((nested) =>
        itemLabels.push({ label: nested.label, refineValue: nested.value })
      );
    } else {
      itemLabels.push({ label: item.label, refineValue: item.value });
    }
  });

  return (
    <Flex
      flexWrap="wrap"
      marginBottom={-2}
      justifyContent="flex-start"
      {...rest}
    >
      {itemLabels.map((item) => (
        <Button
          key={item.label}
          variant="feature"
          marginRight={2}
          marginBottom={2}
          onClick={() => refine(item.refineValue)}
          aria-label={`Remove "${item.label}" filter`}
        >
          {item.label}
          <Icon name="close" marginLeft={3} size="15px" />
        </Button>
      ))}
      <Flex marginBottom={2}>
        <ClearFiltersButton>Clear filters</ClearFiltersButton>
      </Flex>
    </Flex>
  );
};

const CurrentRefinements = connectCurrentRefinements(CustomCurrentRefinements);

export default CurrentRefinements;
