import React from 'react';
import { Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import ReactPlayer from 'react-player/lazy';

export type VideoSectionProps = {
  data: {
    videoTitle: string;
    videoUrl: string;
  };
} & FlexProps;

const VideoSection: React.FC<VideoSectionProps> = ({
  data: { videoUrl },
  ...rest
}) => {
  return (
    <Flex {...rest}>
      <ReactPlayer url={videoUrl} width="640px" height="360px" controls />
    </Flex>
  );
};

export default VideoSection;
