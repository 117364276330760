import React from 'react';
import NextLink from 'next/link';
import { Flex, Heading, Link, Stack } from '@chakra-ui/core';
import type { FlexProps, HeadingProps } from '@chakra-ui/core';

import { Button, ButtonGrid } from '@/components/ui';
import { generateRefinementAsUrl } from '@/utils/search';
import * as textStyles from '@/theme/textStyles';

export type ServiceMiscDetailsProps = {
  booking?: string;
  referral?: string;
  languages: string[];
} & FlexProps;

const ServiceMiscDetails: React.FC<ServiceMiscDetailsProps> = ({
  booking,
  referral,
  languages,
  ...rest
}) => {
  const hasBookingOrReferral = booking || referral;
  const hasLanguages = languages.length;

  if (!hasBookingOrReferral && !hasLanguages) return null;

  const Subheading: React.FC<HeadingProps> = (props) => (
    <Heading as="h3" {...textStyles.h4} {...props} />
  );

  return (
    <Flex
      backgroundColor="lately.background"
      direction="column"
      width="100%"
      {...rest}
    >
      <Stack spacing={{ base: 6, lg: 10 }}>
        <Heading as="h2" {...textStyles.h2}>
          Details
        </Heading>
        {hasBookingOrReferral && (
          <Stack spacing={4}>
            <Subheading>Booking/Referral:</Subheading>
            <ButtonGrid>
              {booking && (
                <NextLink
                  href={generateRefinementAsUrl('booking_label', booking)}
                  passHref
                >
                  <Button variant="feature" as={Link}>
                    {booking}
                  </Button>
                </NextLink>
              )}
              {referral && (
                <NextLink
                  href={generateRefinementAsUrl('referral_label', referral)}
                  passHref
                >
                  <Button variant="feature" as={Link}>
                    {referral}
                  </Button>
                </NextLink>
              )}
            </ButtonGrid>
          </Stack>
        )}

        {hasLanguages && (
          <Stack spacing={4}>
            <Subheading>Languages:</Subheading>
            <ButtonGrid>
              {languages.map((language) => (
                <NextLink
                  key={language}
                  href={generateRefinementAsUrl('languages.name', language)}
                  passHref
                >
                  <Button variant="feature" as={Link}>
                    {language}
                  </Button>
                </NextLink>
              ))}
            </ButtonGrid>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
};

export default ServiceMiscDetails;
