import React from 'react';
import { Flex, Icon, Link as ChakraLink, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import Link from 'next/link';

import * as textStyles from '@/theme/textStyles';

type SocialShareProps = {
  facebookLink: string;
  twitterLink: string;
  emailLink: string;
} & FlexProps;

const SocialShare: React.FC<SocialShareProps> = ({
  facebookLink,
  twitterLink,
  emailLink,
  ...rest
}) => {
  return (
    <Flex color="lately.core02" alignItems="center" flexWrap="wrap" {...rest}>
      <Text {...textStyles.h5} marginRight={8} marginBottom={4}>
        Share this:
      </Text>
      <Link href={facebookLink} passHref>
        <ChakraLink target="_blank" marginBottom={4}>
          <Icon name="facebook" size="8" />
        </ChakraLink>
      </Link>
      <Link href={twitterLink} passHref>
        <ChakraLink target="_blank" marginLeft={4} marginBottom={4}>
          <Icon name="twitterSolid" size="8" />
        </ChakraLink>
      </Link>
      <Link href={emailLink} passHref>
        <ChakraLink target="_blank" marginLeft={4} marginBottom={4}>
          <Icon name="letter" size="8" />
        </ChakraLink>
      </Link>
    </Flex>
  );
};

export default SocialShare;
