import React from 'react';
import { Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import config from '@/theme/grid';
import { assertNever } from '@/utils/types';

// Utility components for creating a grid-based layout using flexbox (for IE11 compatibility)
// Heavily based on http://flexboxgrid.com/

// Utility function to turn `ignoreMargins`, `colOffset`, `colWidth`, etc. in to a full responsive prop
type ResponsiveNumber = { base: number; md?: number; lg?: number };
const getResponsiveValues = (value: number | ResponsiveNumber) => {
  const getResponsiveValue = (
    val: number | ResponsiveNumber,
    breakpoint: 'base' | 'md' | 'lg'
  ) => {
    if (typeof val === 'number') {
      return val;
    }

    switch (breakpoint) {
      case 'base':
        return val.base;
      case 'md':
        return val.md ?? val.base;
      case 'lg':
        return val.lg ?? val.md ?? val.base;
      default:
        return assertNever(breakpoint);
    }
  };

  return {
    base: getResponsiveValue(value, 'base'),
    md: getResponsiveValue(value, 'md'),
    lg: getResponsiveValue(value, 'lg'),
  };
};

export const FlexGrid: React.FC<FlexProps> = ({ ...rest }) => {
  return (
    <Flex direction="column" paddingX={config.margin} width="100%" {...rest} />
  );
};

type RowProps = {
  // Value(s) between 0-1 to make the row bleed in to the grid margins
  // (e.g. 0.5 for half-bleed, for 1 for full-bleed)
  ignoreMargins?: number | ResponsiveNumber;
} & FlexProps;

export const FlexGridRow: React.FC<RowProps> = ({
  ignoreMargins = 0,
  ...rest
}) => {
  // Calculate negative horizontal margins using the gutter sizes
  let marginX = {
    base: -parseFloat(config.gutter.base) / 2,
    md: -parseFloat(config.gutter.md) / 2,
    lg: -parseFloat(config.gutter.lg) / 2,
  };

  // If ignoreMargins is set, use values to subtract from horizontal margins
  const ignore = getResponsiveValues(ignoreMargins);
  marginX = {
    base: marginX.base - parseFloat(config.margin.base) * ignore.base,
    md: marginX.md - parseFloat(config.margin.md) * ignore.md,
    lg: marginX.lg - parseFloat(config.margin.lg) * ignore.lg,
  };

  return (
    <Flex
      direction="row"
      flexWrap="wrap"
      marginX={{
        base: `${marginX.base}rem`,
        md: `${marginX.md}rem`,
        lg: `${marginX.lg}rem`,
      }}
      {...rest}
    />
  );
};

type ItemProps = {
  colOffset?: number | ResponsiveNumber;
  colWidth?: number | ResponsiveNumber;
} & FlexProps;

export const FlexGridItem: React.FC<ItemProps> = ({
  colOffset = 0,
  colWidth = { base: 4, lg: 12 },
  children,
  ...rest
}) => {
  // Get the offset and width values for each grid breakpoint
  const offset = getResponsiveValues(colOffset);
  const width = getResponsiveValues(colWidth);
  // Use them to calculate the margin-left and flex-basis values
  const getPctOfWidth = (val: number, cols: number) => `${(val / cols) * 100}%`;
  const marginLeft = {
    base: getPctOfWidth(offset.base, config.columns.base),
    md: getPctOfWidth(offset.md, config.columns.md),
    lg: getPctOfWidth(offset.lg, config.columns.lg),
  };
  const flexBasis = {
    base: getPctOfWidth(width.base, config.columns.base),
    md: getPctOfWidth(width.md, config.columns.md),
    lg: getPctOfWidth(width.lg, config.columns.lg),
  };

  return (
    <Flex
      flexBasis={flexBasis}
      marginLeft={marginLeft}
      maxWidth={flexBasis}
      paddingX={{
        base: `${parseFloat(config.gutter.base) / 2}rem`,
        md: `${parseFloat(config.gutter.md) / 2}rem`,
        lg: `${parseFloat(config.gutter.lg) / 2}rem`,
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
};
