import React from 'react';
import NextLink from 'next/link';
import {
  Divider,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  ThemeProvider,
  useTheme,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import getPlainText from '@/utils/getPlainText';
import { Button } from '@/components/ui';
import { useSavedContent } from '@/components/providers';
import { SavedContentState, SavedContentType } from '@/utils/saved-content';
import * as textStyles from '@/theme/textStyles';
import { rem, em } from 'polished';

// This component requires custom breakpoints
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customBreakpoints: any = [
  em('320px'),
  em('570px'),
  em('870px'),
  em('1140px'),
];

customBreakpoints.sm = customBreakpoints[0];
customBreakpoints.md = customBreakpoints[1];
customBreakpoints.lg = customBreakpoints[2];
customBreakpoints.xl = customBreakpoints[3];

export type RelatedServicesProps = {
  serviceId: string;
  title: string;
  provider: string;
  description: string;
  url: string;
};

export type RelatedServicesSectionProps = {
  heading: string;
  services: RelatedServicesProps[];
  totalRelatedServices: number;
  allRelatedServicesSearchUrl: string;
} & FlexProps;

const RelatedServicesSection: React.FC<RelatedServicesSectionProps> = ({
  heading,
  services,
  totalRelatedServices,
  allRelatedServicesSearchUrl,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const savedContent = useSavedContent();
  return (
    <ThemeProvider theme={{ ...theme, breakpoints: customBreakpoints }}>
      <Flex direction="column" width="100%" {...rest}>
        <Stack spacing={6} width="100%">
          <Heading as="h3" {...textStyles.h3}>
            {heading}
          </Heading>
          <Flex
            direction="row"
            flexWrap="wrap"
            marginLeft={{ base: -4, xl: -6 }}
            flexShrink={1}
          >
            {services.map((service) => {
              // Get the saved state of the service
              const [savedState, onToggleSave] = savedContent.getItemState(
                service.serviceId,
                SavedContentType.Service
              );
              return (
                <Flex
                  key={service.title}
                  direction="column"
                  paddingRight={4}
                  paddingLeft={{ base: 4, xl: 6 }}
                  marginBottom={10}
                  width={{
                    base: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  }}
                >
                  <Flex
                    direction="column"
                    flexGrow={1}
                    maxWidth={{ base: rem('360px'), md: rem('720px') }}
                  >
                    <Flex flexGrow={1} direction="column">
                      <Heading
                        as="h3"
                        color="lately.highlights03"
                        marginBottom={1}
                        {...textStyles.h4}
                      >
                        <NextLink href={service.url} passHref>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            _hover={{
                              color: 'lately.highlights03',
                              textDecoration: 'none',
                            }}
                          >
                            {service.title}
                          </Link>
                        </NextLink>
                      </Heading>
                      <Text
                        color="lately.highlights03"
                        marginBottom={{ base: 1, lg: 2 }}
                        {...textStyles.body}
                      >
                        Provider: {service.provider}
                      </Text>
                      <Text {...textStyles.body}>
                        {getPlainText(service.description, 125)}
                      </Text>
                    </Flex>
                    <Flex flexWrap="wrap">
                      <NextLink href={service.url} passHref>
                        <Button
                          as={Link}
                          alignSelf="flex-start"
                          marginTop={{ base: 4, lg: 6 }}
                          width="auto"
                          marginRight={2}
                        >
                          View service
                        </Button>
                      </NextLink>
                      <Button
                        variant="secondary"
                        isLoading={savedState === SavedContentState.Pending}
                        alignSelf="flex-start"
                        marginTop={{ base: 4, lg: 6 }}
                        width="auto"
                        onClick={onToggleSave}
                      >
                        {savedState === SavedContentState.None
                          ? 'Save'
                          : 'Remove'}
                      </Button>
                    </Flex>
                  </Flex>
                  <Divider
                    marginTop={4}
                    marginBottom={0}
                    marginRight={-4}
                    borderWidth={rem('2px')}
                    display={{ base: 'block', lg: 'none' }}
                  />
                </Flex>
              );
            })}
          </Flex>
        </Stack>
        <NextLink href={allRelatedServicesSearchUrl} passHref>
          <Button
            as={Link}
            variant="secondary"
            alignSelf="flex-start"
            width="auto"
          >
            {`See all related services (${totalRelatedServices})`}
          </Button>
        </NextLink>
      </Flex>
    </ThemeProvider>
  );
};

export default RelatedServicesSection;
