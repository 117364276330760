import React from 'react';
import { Box, BoxProps, Flex, Heading, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { rem } from 'polished';
import gridConfig from '@/theme/grid';
import * as textStyles from '@/theme/textStyles';

type Props = {
  title: string;
  description?: string;
  mobileImageUrl?: string;
  desktopImageUrl?: string;
  desktopBackgroundProps?: BoxProps;
} & FlexProps;

const PageHeader: React.FC<Props> = ({
  title,
  description,
  mobileImageUrl,
  desktopImageUrl,
  desktopBackgroundProps,
  ...rest
}) => {
  return (
    <Flex
      alignItems="stretch"
      backgroundColor="lately.core02"
      borderRadius={{ base: 0, lg: '2px' }}
      color="lately.background"
      direction={{ base: 'column', lg: 'row' }}
      width="100%"
      {...rest}
    >
      <Stack
        marginRight="auto"
        paddingX={{
          base: gridConfig.margin.base,
          md: gridConfig.margin.md,
          lg: `${parseFloat(gridConfig.margin.lg) / 2}rem`,
        }}
        paddingY={{ base: 6, lg: 10 }}
        spacing={4}
        width="100%"
      >
        <Heading as="h1" {...textStyles.h1} wordBreak="break-word">
          {title}
        </Heading>
        {description && (
          <Text {...textStyles.body} wordBreak="break-word">
            {description}
          </Text>
        )}
      </Stack>
      <Box
        backgroundImage={`url(${
          mobileImageUrl ??
          require('@/images/illustration-page-header-small.svg')
        })`}
        backgroundPosition="bottom right"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        display={{ base: 'flex', lg: 'none' }}
        height={rem('80px')}
        width="100%"
      />
      <Box
        backgroundImage={`url(${
          desktopImageUrl ??
          require('@/images/illustration-page-header-large.svg')
        })`}
        backgroundPosition="center left"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        display={{ base: 'none', lg: 'initial' }}
        width={rem('357px')}
        {...desktopBackgroundProps}
      />
    </Flex>
  );
};

export default PageHeader;
