/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { rem } from 'polished';
import NextLink from 'next/link';
import { Flex, Heading, Link, Stack } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { FlexGridRow, FlexGridItem } from '@/components/layout';
import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type FaqsSectionProps = {
  heading: string;
  faqs: {
    question: string;
    href: string;
  }[];
  button: {
    label: string;
    href: string;
  };
} & FlexProps;

const FaqsSection: React.FC<FaqsSectionProps> = ({
  heading,
  faqs,
  button,
  children,
  ...rest
}) => {
  return (
    <FlexGridRow {...rest}>
      <FlexGridItem>
        <Stack spacing={{ base: 3, md: 4, lg: rem('28px') }} width="100%">
          <Heading as="h2" {...textStyles.h2}>
            {heading}
          </Heading>
          <Stack spacing={{ base: 5, md: 4, lg: rem('28px') }} width="100%">
            <Flex
              direction="row"
              flexWrap="wrap"
              marginX={{ base: -2, lg: -3 }}
            >
              {faqs.map((faq) => {
                return (
                  <Flex
                    key={faq.href}
                    paddingX={{ base: 2, lg: 3 }}
                    paddingY={{ base: 1, md: 2, lg: 3 }}
                    width={{ base: '100%', md: '33.33%' }}
                  >
                    <NextLink href={faq.href} passHref>
                      <Link
                        backgroundColor="lately.answers"
                        color="lately.highlights03"
                        width="100%"
                        {...textStyles.h4}
                        _hover={{
                          textDecoration: 'none',
                          backgroundColor: 'lately.highlights02',
                          cursor: 'pointer',
                        }}
                        css={css`
                          &:hover {
                            span {
                              text-decoration: none;
                            }
                          }
                        `}
                        borderRadius="2px"
                      >
                        <Flex
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          height="100%"
                          paddingX={{ base: 6, lg: 8 }}
                          paddingY={{ base: 6, lg: rem('28px') }}
                        >
                          <Heading
                            as="h3"
                            {...textStyles.h4}
                            marginBottom={{ base: 4, lg: 8 }}
                            flexGrow={1}
                          >
                            {faq.question}
                          </Heading>
                          <Flex width="auto" justifyContent="start">
                            <Button
                              variant="textLink"
                              color="lately.highlights03"
                              as="span"
                            >
                              Read more
                            </Button>
                          </Flex>
                        </Flex>
                      </Link>
                    </NextLink>
                  </Flex>
                );
              })}
            </Flex>
            <Flex width="100%" justifyContent={{ base: 'start', md: 'center' }}>
              <NextLink href={button.href} passHref>
                <Button as={Link} variant="secondary">
                  {button.label}
                </Button>
              </NextLink>
            </Flex>
          </Stack>
        </Stack>
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default FaqsSection;
