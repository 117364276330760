import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

type Options =
  | {
      limit: 'none';
    }
  | {
      limit: 'debounce';
      wait: number;
      settings?: {
        leading?: boolean;
        maxWait?: number;
        trailing?: boolean;
      };
    }
  | {
      limit: 'throttle';
      wait: number;
      settings?: {
        leading?: boolean;
        trailing?: boolean;
      };
    };

export const useHasScrolled = (
  options: Options = { limit: 'none' }
): boolean => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const onScroll = (): void => {
      setHasScrolled(window.pageYOffset > 0);
    };
    onScroll();

    let cb: () => void;
    switch (options.limit) {
      case 'debounce':
        cb = debounce(onScroll, options.wait, options.settings);
        break;
      case 'throttle':
        cb = throttle(onScroll, options.wait, options.settings);
        break;
      default:
        cb = onScroll;
        break;
    }

    window.addEventListener('scroll', cb);
    return () => {
      window.removeEventListener('scroll', cb);
    };
  }, []);

  return hasScrolled;
};

export default useHasScrolled;
