import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Text,
  Icon,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Link,
  List,
  ListItem,
} from '@chakra-ui/core';
import NextLink from 'next/link';
import type { FlexProps } from '@chakra-ui/core';
import { rem } from 'polished';

import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import type { NavItemType } from '@/utils/types';

type MenuDrawerProps = {
  navItems: Array<NavItemType>;
  logoImageUrl: string;
  isOpen: boolean;
  onClose: () => void;
  hasSavedBadge: boolean;
} & FlexProps;

const MenuDrawer: React.FC<MenuDrawerProps> = React.memo(function MenuDrawer({
  navItems,
  logoImageUrl,
  isOpen,
  onClose,
  hasSavedBadge,
}) {
  // Generate and memoise the list of items
  const navItemList = useMemo(() => {
    return navItems.map((navItem) => {
      const { name, url, links, ...boxProps } = navItem;
      if (name === 'Search') {
        return null;
      }
      if (links !== undefined) {
        return (
          <Accordion allowToggle allowMultiple py={3} key={name}>
            <AccordionItem border="none" defaultIsOpen={false}>
              {({ isExpanded }) => (
                <>
                  <AccordionHeader width="auto" p={0} _hover={{ bg: 'none' }}>
                    <Text textAlign="left" {...textStyles.h4} mr={2}>
                      {name}
                    </Text>
                    <Icon
                      name="accordionChevron"
                      width={rem('12px')}
                      transform={isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'}
                      transition="all 0.2s"
                    />
                  </AccordionHeader>
                  <AccordionPanel pb={4} pl={2} pt={6}>
                    <List styleType="none" spacing={6}>
                      {links.map((ai) => (
                        <ListItem key={ai.name}>
                          <NextLink href={ai.url} passHref>
                            <Link
                              {...textStyles.smallData}
                              color="#666870"
                              _hover={{
                                textDecoration: 'none',
                                color: 'lately.text',
                              }}
                            >
                              {ai.name}
                            </Link>
                          </NextLink>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        );
      }
      if (name === 'Saved') {
        return (
          <Box py={3} key={name} {...boxProps}>
            <NextLink href={url ?? '/404'} passHref>
              <Link
                {...textStyles.h4}
                _hover={{
                  textDecoration: 'none',
                }}
              >
                {name}
              </Link>
            </NextLink>
            <Badge
              backgroundColor="lately.secondary01"
              paddingX={rem('3px')}
              height={rem('6px')}
              borderRadius="99%"
              opacity={hasSavedBadge ? 1 : 0}
              position="relative"
              right={rem('-3px')}
              top={rem('-12px')}
              transition="opacity 0.3s"
            >
              {' '}
            </Badge>
          </Box>
        );
      }
      return (
        <Box py={3} key={name} {...boxProps}>
          <NextLink href={url ?? '/404'} passHref>
            <Link
              {...textStyles.h4}
              _hover={{
                textDecoration: 'none',
              }}
            >
              {name}
            </Link>
          </NextLink>
        </Box>
      );
    });
  }, [navItems, hasSavedBadge]);

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="full">
      <DrawerOverlay />
      <DrawerContent bg="lately.background">
        <DrawerHeader borderBottomWidth="1px" borderBottom="none" p={1}>
          <Button
            onClick={onClose}
            display="flex"
            alignItems="center"
            px={3}
            py={3}
            variant="unstyled"
          >
            <Icon name="close" width={rem('15px')} />
            <Text {...textStyles.h5} pl={2}>
              Close
            </Text>
          </Button>
        </DrawerHeader>
        <DrawerBody p={4} overflowY="auto">
          <Flex
            justifyContent="space-between"
            justifyItems="space-between"
            flexDirection="column"
            minHeight="100%"
          >
            <Box>{navItemList}</Box>
            <Box height={rem('126px')}>
              <Image
                alt="Precious Time Logo"
                src={logoImageUrl}
                ignoreFallback
                height="100%"
                pt={20}
                pb={4}
              />
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});

export default MenuDrawer;
