import React from 'react';
import { rem } from 'polished';
import { Flex, Heading, Icon, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Button } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';

type Props = {
  data: {
    title: string;
    description: string;
    link: {
      text: string;
      url: string;
    };
  };
} & FlexProps;

const PromoLinkSection: React.FC<Props> = ({ data, ...rest }) => {
  const { title, description, link } = data;
  return (
    <FlexGridRow {...rest}>
      <FlexGridItem
        colOffset={{ base: 0, md: 1, lg: 3 }}
        colWidth={{ base: 4, md: 2, lg: 6 }}
      >
        <Stack
          backgroundColor="lately.core02"
          borderRadius="4px"
          color="lately.background"
          direction="column"
          paddingX={{ base: 6, lg: 12 }}
          paddingTop={{ base: 6, lg: 10 }}
          paddingBottom={{ base: 6, lg: 8 }}
          spacing={{ base: 4, md: 6 }}
          width="100%"
        >
          <Flex direction={{ base: 'column', lg: 'row-reverse' }}>
            <Icon
              name="conversation"
              size={rem('82px')}
              alignSelf="flex-start"
              marginLeft={{ base: 0, lg: 2 }}
              marginBottom={6}
            />

            <Stack spacing={{ base: 4, lg: 6 }}>
              <Heading as="h2" {...textStyles.h2} flexGrow={1}>
                {title}
              </Heading>
              <Text {...textStyles.body}>{description}</Text>
              <Button
                as={Link}
                variant="textLink"
                color="lately.background"
                justifyContent="flex-start"
                whiteSpace="normal"
                {...{ href: link.url }}
              >
                {link.text}
              </Button>
            </Stack>
          </Flex>
        </Stack>
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default PromoLinkSection;
