import React from 'react';
import NextLink from 'next/link';
import { Box, Flex, Heading, Icon, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import { rem } from 'polished';

import { generateRefinementAsUrl } from '@/utils/search';
import { Button } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';

export type CategoriesProps = { name: string; total: number }[];

type RelatedCategoriesSectionProps = {
  heading?: string;
  categories: CategoriesProps;
} & FlexProps;

const RelatedCategoriesSection: React.FC<RelatedCategoriesSectionProps> = ({
  heading,
  categories,
  ...rest
}) => {
  return (
    <FlexGridRow {...rest}>
      <FlexGridItem width="100%">
        <Stack spacing={{ base: 4, md: 6, lg: 10 }} width="100%">
          {heading && (
            <Heading as="h2" display="inline" {...textStyles.h2}>
              {heading}
              <Icon
                name="search"
                display="inline"
                marginBottom={1}
                marginLeft={rem('10px')}
              />
            </Heading>
          )}
          <Stack
            maxWidth={{
              base: rem('515px'),
              md: rem('468px'),
              lg: rem('570px'),
            }}
            spacing={2}
          >
            {categories.map((category) => {
              return (
                <Box key={category.name}>
                  <NextLink
                    href={generateRefinementAsUrl(
                      'service_types.name',
                      category.name
                    )}
                    passHref
                  >
                    <Button
                      variant="feature"
                      as={Link}
                      width="100%"
                      minHeight={rem('68px')}
                      height="100%"
                      alignItems="center"
                    >
                      <Flex width="100%" justifyContent="space-between">
                        <Text textAlign="left">{category.name}</Text>
                        <Text
                          textAlign="left"
                          wordBreak="keep-all"
                          fontWeight="normal"
                        >
                          ({category.total})
                        </Text>
                      </Flex>
                    </Button>
                  </NextLink>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default RelatedCategoriesSection;
