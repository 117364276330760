import React from 'react';
import { rem } from 'polished';
import {
  connectStateResults,
  connectHits,
  connectHighlight,
} from 'react-instantsearch-dom';
import type {
  StateResultsProvided,
  HitsProvided,
  Hit as THit,
  HighlightProps as THighlightProps,
} from 'react-instantsearch-core';
import { Box, Link, Text } from '@chakra-ui/core';
import reactFastCompare from 'react-fast-compare';

import * as textStyles from '@/theme/textStyles';
import { assertNever } from '@/utils/types';

export type CustomHit = THit & {
  name?: string;
  title?: string;
  question?: string;
};

type HitProps = {
  hit: CustomHit;
  onSelect: (query: string) => void;
  onBlur: (forceClose: boolean) => void;
} & THighlightProps;

const Hit: React.FC<HitProps> = React.memo(function Hit({
  highlight,
  attribute,
  hit,
  onSelect,
  onBlur,
}) {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  const label = hit.title || hit.name || hit.question || '';

  return (
    <Box width="100%">
      <Link
        as="button"
        type="button"
        onClick={() => onSelect(label)}
        onBlur={() => onBlur(false)}
        _hover={{
          backgroundColor: 'lately.core02',
          color: 'lately.background',
        }}
        _focus={{
          backgroundColor: 'lately.core02',
          color: 'lately.background',
        }}
        width="100%"
        height="100%"
        display="block"
        px={6}
        py={rem('14px')}
        {...textStyles.smallData}
        fontSize={rem('15px')}
        borderBottomRightRadius="0px"
        borderBottomLeftRadius="0px"
        textAlign="left"
      >
        {parsedHit.map(({ value, isHighlighted }, i) => {
          const Component = isHighlighted ? 'strong' : 'span';
          // eslint-disable-next-line react/no-array-index-key
          return <Component key={`${value}${i}`}>{value}</Component>;
        })}
      </Link>
    </Box>
  );
},
reactFastCompare);

const ConnectedHit = connectHighlight<HitProps, CustomHit>(
  function ConnectedHit(props) {
    return <Hit {...props} />;
  }
);

const ResultWrapper: React.FC = ({ children }): JSX.Element => {
  return (
    <Box
      width="100%"
      height="100%"
      display="block"
      px={6}
      py={rem('14px')}
      {...textStyles.smallData}
      fontSize={rem('15px')}
    >
      {children}
    </Box>
  );
};

type HitsProps = Pick<HitProps, 'onSelect' | 'onBlur'> &
  HitsProvided<CustomHit>;

const Hits: React.FC<HitsProps> = React.memo(function Hits({
  hits,
  onSelect,
  onBlur,
}) {
  if (!hits.length) return null;
  return (
    <>
      {hits.map((h) => {
        const attribute = ['title', 'name', 'question'].find((a) => h[a]);
        if (attribute === undefined) return null;
        // Trim white space from missing attribute string and return null so there are no blank spaces in the dropdown list.
        if (h[attribute].trim() === '') return null;
        return (
          <ConnectedHit
            key={h.objectID}
            hit={h}
            attribute={attribute}
            onSelect={onSelect}
            onBlur={onBlur}
          />
        );
      })}
    </>
  );
},
reactFastCompare);

const ConnectedHits = connectHits<HitsProps, CustomHit>(function ConnectedHits(
  props
) {
  return <Hits {...props} />;
});

type State = 'error' | 'searching' | 'noQuery' | 'hasResults' | 'noResults';

const getState = (resultsData: StateResultsProvided): State => {
  if (resultsData.error) return 'error';
  const hasResults =
    resultsData.searchResults && resultsData.searchResults.nbHits !== 0;

  if (hasResults) return 'hasResults';

  if (!!resultsData.searchState.query && !hasResults) return 'noResults';

  if (resultsData.searching) return 'searching';

  return 'error';
};

// Search results dropdown contents
const StateResults: React.FC<StateResultsProvided> = React.memo(
  function StateResults(stateResults) {
    const state = getState(stateResults);
    switch (state) {
      case 'error':
        return (
          <ResultWrapper>
            <Text fontStyle="italic">
              There was an error while completing your search.
            </Text>
          </ResultWrapper>
        );
      case 'hasResults':
      case 'noQuery':
        return null;
      case 'noResults':
        return (
          <ResultWrapper>
            <Text fontStyle="italic">No suggestions match your search.</Text>
          </ResultWrapper>
        );
      // Searching must be last as it creates a loop by continually
      // re-rendering the component then restarting the search
      case 'searching':
        return (
          <ResultWrapper>
            <Text fontStyle="italic">Loading...</Text>
          </ResultWrapper>
        );
      default:
        return assertNever(state);
    }
  }
);

const ConnectedStateResults = connectStateResults(
  function ConnectedStateResults(props) {
    return <StateResults {...props} />;
  }
);

const SearchResultsHits = React.memo(function SearchResultsHits({
  onSelect,
  onBlur,
}: Pick<HitProps, 'onSelect' | 'onBlur'>) {
  return (
    <>
      <ConnectedHits onSelect={onSelect} onBlur={onBlur} />
      <ConnectedStateResults />
    </>
  );
});

export default SearchResultsHits;
