import React from 'react';
import { rem } from 'polished';
import { Input } from '@chakra-ui/core';
import { connectSearchBox } from 'react-instantsearch-dom';
import type { SearchBoxProvided } from 'react-instantsearch-core';

import * as textStyles from '@/theme/textStyles';

type SearchBoxProps = {
  inputPlaceholder: string;
  handleOpenDropdown: () => void;
  handleCloseDropdown: (force: boolean) => void;
} & SearchBoxProvided;

const SearchBox = ({
  inputPlaceholder,
  currentRefinement,
  refine,
  handleOpenDropdown,
  handleCloseDropdown,
}: SearchBoxProps) => {
  const onChange = (value: string) => {
    // The change was triggered by a higher level state change when a search suggestion was selected
    // which means the refinement already happened at the searchState level
    if (currentRefinement === value) return;

    refine(value);

    if (value.length === 0) {
      handleCloseDropdown(false);
      return;
    }

    handleOpenDropdown();
  };

  return (
    <Input
      type="input"
      autoComplete="off"
      value={currentRefinement}
      placeholder={inputPlaceholder}
      aria-label="Search"
      name="query"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
      }}
      onFocus={handleOpenDropdown}
      onBlur={() => handleCloseDropdown(false)}
      {...textStyles.body}
      lineHeight="20px"
      height="auto"
      backgroundColor="lately.background"
      border={0}
      borderRadius="2px"
      paddingX={{ base: 4, lg: 6 }}
      paddingY={{
        base: rem('14px'),
        lg: rem('20px'),
      }}
      position="relative"
    />
  );
};

const ConnectedSearchBox = connectSearchBox(SearchBox);

export default ConnectedSearchBox;
