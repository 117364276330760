import { rem } from 'polished';

import customTheme from '@/theme/chakra';

// H1 - Heading
export const h1 = {
  fontSize: { base: rem('29px'), lg: rem('40px') },
  fontWeight: 'bold',
  letterSpacing: '-0.005em',
  lineHeight: { base: rem('40px'), lg: rem('56px') },
};

// H1 - Campaign Heading
export const h1campaign = {
  fontSize: { base: rem('34px'), lg: rem('48px'), xl: rem('51px') },
  fontWeight: 800,
  fontStyle: 'normal',
  letterSpacing: { base: '-0.05em', lg: '-0.05em' },
  lineHeight: 1.5,
  fontFamily: customTheme.fonts.headingCampaign,
};

// H2 - Sub heading
export const h2 = {
  fontSize: { base: rem('26px'), lg: rem('32px') },
  fontWeight: 'bold',
  letterSpacing: '-0.005em',
  lineHeight: { base: rem('36px'), lg: rem('40px') },
};

// H3 - Quotes
export const h3 = {
  fontSize: { base: rem('23px'), lg: rem('28px') },
  fontWeight: 'bold',
  letterSpacing: '-0.005em',
  lineHeight: { base: rem('32px'), lg: rem('40px') },
};

// H4 - Card title
export const h4 = {
  fontSize: { base: rem('20px'), lg: rem('23px') },
  fontWeight: 'bold',
  letterSpacing: '-0.005em',
  lineHeight: { base: rem('30px'), lg: rem('34px') },
};

// H5 - Button
export const h5 = {
  fontSize: rem('18px'),
  fontWeight: '600',
  letterSpacing: '0',
  lineHeight: rem('28px'),
  fontFamily: customTheme.fonts.body,
};

// Body
export const body = {
  fontSize: rem('17px'),
  fontWeight: 'normal',
  letterSpacing: '0',
  lineHeight: rem('28px'),
};

// Small data
export const smallData = {
  fontSize: { base: rem('14px'), lg: rem('15px') },
  fontWeight: 'normal',
  letterSpacing: '0.01em',
  lineHeight: { base: rem('20px'), lg: rem('24px') },
};

// Link
export const link = {
  textDecoration: 'underline',
  _hover: {
    textDecoration: 'none',
  },
};
