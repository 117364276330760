/* eslint-disable no-underscore-dangle */
import type { PageProps } from '@/components/layout';
import type {
  BlogPostProps,
  BlogListProps,
  ConversationStartersProps,
  Error404Props,
  FaqDetailsProps,
  FaqListProps,
  GlossaryProps,
  HomeProps,
  HubProps,
  SavedProps,
  SearchResultsProps,
  ServiceProps,
  StandardContentProps,
} from '@/components/pages';
import {
  assertNever,
  SectionOption,
  SectionsProps,
  NavItemType,
  ImageTextSectionProps,
  ImageSectionProps,
  FaqsSectionProps,
  HubsListProps,
  TwoColTextSectionProps,
  TextSectionProps,
  PopularCategoriesProps,
  RelatedServicesProps,
  VideoSectionProps,
} from '@/utils/types';

import { generateRefinementAsUrl } from '@/utils/search';
import type { BlogTileProps } from '@/components/ui';
import { ResourceListProps } from '@/components/pages/ResourceList';
import { ResourceTileProps } from '@/components/ui/ResourceTile';
import type {
  BlogListData,
  BlogPostData,
  BlogPostTileData,
  ConversationStarterData,
  Error404Data,
  FaqDetailsData,
  FaqListData,
  FaqsSectionData,
  GlobalData,
  GlossaryData,
  HomeData,
  HubData,
  HubsListData,
  ImageTextSectionData,
  ImageSectionData,
  NavItemData,
  PopularCategoryData,
  SavedPageData,
  SearchData,
  SectionOptionData,
  SectionsData,
  ServiceData,
  ServiceTypesData,
  StandardContentData,
  TwoColumnTextSectionData,
  TextBlockSectionData,
  VideoSectionData,
  ResourceTileData,
  ResourceListData,
} from './queries';

import { YesNoSometimes } from './queries';

// Prepend the CMS URL to the image URL returned by the API
const getImageUrl = (url: string) => {
  // Storybook serves static assets from static/media
  // In Production we're using absolute urls from S3
  if (/^(static\/media|http)/.test(url)) return url;
  // All others will be relative files hosted in the CMS
  return `${process.env.NEXT_PUBLIC_API_URL}${url}`;
};

// Prepend the CMS URL to the image URL returned by the API
const getFileUrl = (url: string) => {
  // Storybook serves static assets from static/media
  // In Production we're using absolute urls from S3
  if (/^(static\/media|http)/.test(url)) return url;
  // All others will be relative files hosted in the CMS
  return `${process.env.NEXT_PUBLIC_API_URL}${url}`;
};

const getConversationStarterUrl = (slug: string) => {
  return `/conversation-starters/${slug}`;
};

const getFaqUrl = (slug: string) => {
  return `/faq/${slug}`;
};

const getHubUrl = (slug: string) => {
  return `/hubs/${slug}`;
};

const getServiceUrl = (slug: string) => {
  return `/services/${slug}`;
};

const getBlogUrl = (slug: string) => {
  return `/blog/${slug}`;
};

// Utility functions to turn GraphQL data in to component props
const getImageTextSectionProps = (
  data: ImageTextSectionData
): ImageTextSectionProps => {
  const { Image, Text } = data;

  let textBlock;
  if (Text !== undefined) {
    textBlock = {
      heading: Text.heading,
      headingStyle: Text.heading_style,
      body: Text.body,
    };
  }

  let imageBlock;
  if (Image !== undefined) {
    imageBlock = {
      url: getImageUrl(Image.url),
      alt: Image.alternativeText ?? undefined,
      width: Image.width ?? undefined,
      height: Image.height ?? undefined,
    };
  }

  return {
    data: {
      image: imageBlock,
      textBlock: textBlock,
    },
  };
};

const getImageSectionProps = (data: ImageSectionData): ImageSectionProps => {
  if (!data.image) return { data: { image: undefined } };
  const {
    image: { url, height, width, alternativeText },
  } = data;

  return {
    data: {
      image: {
        url: getImageUrl(url),
        alt: alternativeText ?? undefined,
        width: width ?? undefined,
        height: height ?? undefined,
      },
    },
  };
};

const getVideoSectionProps = (data: VideoSectionData): VideoSectionProps => {
  return {
    data: {
      videoTitle: data.video_title,
      videoUrl: data.video_link,
    },
  };
};

const getFaqsProps = (data: FaqsSectionData): FaqsSectionProps['faqs'] => {
  if (data === null) return [];
  const { FaqCards } = data;
  return FaqCards.map((question) => ({
    question: question.faq.question,
    href: getFaqUrl(question.faq.slug),
  }));
};

const getHubListProps = (hubs: HubsListData): HubsListProps => {
  const hubsList = hubs.map((hub) => ({
    url: getHubUrl(hub.slug),
    title: hub.title,
    description: hub.description,
    imageUrl: getImageUrl(hub.illustration.url),
  }));
  return hubsList;
};

const getTextSectionProps = (
  textSectionData: TwoColumnTextSectionData
): TwoColTextSectionProps => {
  const textBlocks = textSectionData.TextBlock.map((text) => {
    return {
      heading: text.heading,
      headingStyle: text.heading_style,
      body: text.body,
    };
  });
  return {
    data: [textBlocks[0], textBlocks[1]],
  };
};

const getTextBlockSectionProps = (
  textSectionData: TextBlockSectionData
): TextSectionProps => {
  const textBlock = {
    heading: textSectionData.heading,
    headingStyle: textSectionData.heading_style,
    body: textSectionData.body,
  };

  return {
    data: textBlock,
  };
};

const getPopularCategoriesProps = (
  popularCategoriesData: PopularCategoryData
): Omit<PopularCategoriesProps, 'numberOfCategoriesToShow'> => {
  return {
    heading: popularCategoriesData.heading,
    description: popularCategoriesData.description,
    popularCategories: popularCategoriesData.PopularCategoryCards.map(
      ({ service_type }) => {
        return {
          id: service_type.id,
          name: service_type.name,
          isCommunityService: service_type.is_community_service,
        };
      }
    ),
  };
};

const getNavItemLinks = (
  navItem: NavItemData,
  serviceTypes: GlobalData['serviceTypes'],
  hubNavItems: GlobalData['hubNavItems']
): NavItemType['links'] => {
  switch (navItem.__typename) {
    case 'ComponentUiServicesDropdown':
      return (
        serviceTypes
          // Exclude any service types that have no services
          .filter((s) => s.serviceCount > 0)
          // Sort the service types alphabetically
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
          .map((link) => ({
            name: link.name,
            url: generateRefinementAsUrl('service_types.name', link.name),
          }))
      );
    case 'ComponentUiHubsDropdown':
      return hubNavItems.map((link) => ({
        name: link.title,
        url: getHubUrl(link.slug),
      }));

    default:
      return undefined;
  }
};

const getNavigationItems = (
  navLinks: NavItemData[],
  savedLabel: string,
  serviceTypes: GlobalData['serviceTypes'],
  hubNavItems: GlobalData['hubNavItems']
): NavItemType[] => {
  const baseNavigationMenuItems = navLinks.map(
    (navItem: NavItemData): NavItemType => {
      return {
        name: navItem.label,
        url: navItem.url,
        links: getNavItemLinks(navItem, serviceTypes, hubNavItems),
      };
    }
  );

  const allNavigationMenuItems = baseNavigationMenuItems.concat([
    {
      name: savedLabel,
      url: '/saved',
    },
  ]);

  return allNavigationMenuItems;
};

export const getPageProps = (data: GlobalData): PageProps => {
  const {
    global: {
      SiteNotice,
      Footer,
      PrimaryNavigationLinks,
      PrimaryNavigationOptions,
      PopularCategories,
      BlogPostsSectionBlogPostPage,
    },
    feedbackForm,
    serviceTypes,
    hubNavItems,
  } = data;

  return {
    feedbackForm,
    notice: SiteNotice?.visible
      ? {
          text: SiteNotice.text,
          link:
            SiteNotice.Link !== null
              ? {
                  text: SiteNotice.Link.label,
                  url: SiteNotice.Link.url,
                }
              : undefined,
        }
      : undefined,
    footerProps: {
      logoImageUrl: getImageUrl(Footer.logo.url),
      nominateButton: {
        label: Footer.NominateButton.label,
        href: Footer.NominateButton.link,
      },
      tagline: Footer.tagline,
      links: {
        leftColumn: Footer.LinksLeftColumn,
        rightColumn: Footer.LinksRightColumn,
      },
      acknowledgement: Footer.acknowledgement,
      awardsText: Footer.awardsText,
      awardsBadgeURL:
        Footer.awardsBadge && Footer.awardsBadge.url
          ? getImageUrl(Footer.awardsBadge.url)
          : '',
      flagImages: Footer.flags.map((flag) => ({
        alt: flag.alternativeText ?? '',
        url: getImageUrl(flag.url),
      })),
      copyright: Footer.copyright,
      funding: Footer.funding,
      phnLogoImageUrl: getImageUrl(Footer.phn_logo.url),
      surveyUrl: Footer.survey_embed_url,
      phnWebsiteUrl: Footer.phn_website_url,
    },
    primaryNavigationProps: {
      navItems: getNavigationItems(
        PrimaryNavigationLinks,
        PrimaryNavigationOptions.saved_label,
        serviceTypes,
        hubNavItems
      ),
      logoImageUrl: getImageUrl(PrimaryNavigationOptions.logo.url),
      description: PrimaryNavigationOptions.website_description || '',
    },
    popularCategoriesProps: getPopularCategoriesProps(PopularCategories),
    blogPostRecentProps: BlogPostsSectionBlogPostPage
      ? {
          heading: BlogPostsSectionBlogPostPage.heading,
          button: {
            label: BlogPostsSectionBlogPostPage.button_label,
            href: BlogPostsSectionBlogPostPage.button_link,
          },
        }
      : undefined,
  };
};

export const getBlogPostTileProps = (
  blogPosts: BlogPostTileData[]
): BlogTileProps[] => {
  return blogPosts.map((blog) => ({
    url: getBlogUrl(blog.slug),
    title: blog.title,
    description: blog.description,
    heroImage: blog.hero_image
      ? {
          url: getImageUrl(blog.hero_image.url),
          alt: blog.hero_image.alternativeText ?? undefined,
          width: blog.hero_image.width ?? undefined,
          height: blog.hero_image.height ?? undefined,
        }
      : undefined,
    categories: blog.blog_categories
      ? blog.blog_categories.map((category) => category.category)
      : [],
  }));
};

export const getHomeProps = (data: HomeData): HomeProps => {
  const { global, homePage, hubs, blogPosts } = data;
  const { PromoLink, HomeHeader, FaqsSection, BlogPostsSection } = homePage;

  return {
    metaTitle: homePage.meta_title,
    metaDescription: homePage.meta_description,
    shareImage: homePage.share_image
      ? getImageUrl(homePage.share_image.url)
      : '',
    pageProps: getPageProps(data),
    headerProps: HomeHeader && {
      isCampaign: HomeHeader.is_campaign_banner,
      heading: HomeHeader.heading || '',
      description: HomeHeader.description,
      button1: HomeHeader.button_1 && {
        label: HomeHeader.button_1.label,
        href: HomeHeader.button_1.url,
      },
      button2: HomeHeader.button_2 && {
        label: HomeHeader.button_2.label,
        href: HomeHeader.button_2.url,
      },
      imageLargeUrl: HomeHeader.illustration_large_header
        ? getImageUrl(HomeHeader.illustration_large_header.url)
        : '',
      imageSmallUrl: HomeHeader.illustration_small_header
        ? getImageUrl(HomeHeader.illustration_small_header.url || '')
        : '',
    },
    hubsListHeading: global.hubs_list_heading,
    hubs: getHubListProps(hubs),
    blogPosts: BlogPostsSection
      ? {
          heading: BlogPostsSection.heading,
          posts: getBlogPostTileProps(blogPosts),
          button: {
            label: BlogPostsSection.button_label,
            href: BlogPostsSection.button_link,
          },
        }
      : undefined,
    faqs: {
      heading: global.faq_list_heading,
      faqs: getFaqsProps(FaqsSection),
      button: {
        href: '/faq',
        label: global.faq_list_button_label,
      },
    },
    promo:
      PromoLink !== null
        ? {
            title: PromoLink.title,
            description: PromoLink.description,
            link: {
              text: PromoLink.Link.label,
              url: PromoLink.Link.url,
            },
          }
        : undefined,
  };
};

export const getSearchPageProps = (data: SearchData): SearchResultsProps => {
  return {
    pageProps: getPageProps(data),
  };
};

const getSectionProps = (
  data: SectionsData,
  global: GlobalData['global']
): SectionsProps => {
  const sections: SectionOption[] = data.map((section: SectionOptionData) => {
    switch (section.__typename) {
      case 'ComponentUiImageTextSection':
        return {
          kind: 'imageText',
          id: section.id,
          ...getImageTextSectionProps(section),
        };
      case 'ComponentUiFaqSection':
        return {
          kind: 'faqs',
          id: section.id,
          heading: global.faq_list_heading,
          faqs: getFaqsProps(section),
          button: {
            href: '/faq',
            label: global.faq_list_button_label,
          },
        };
      case 'ComponentUiTextSection': // two col text section
        return {
          kind: 'twoColText',
          id: section.id,
          ...getTextSectionProps(section),
        };
      case 'ComponentUiTextBlock': // one col text section
        return {
          kind: 'textBlock',
          id: section.id,
          ...getTextBlockSectionProps(section),
        };
      case 'ComponentUiImage':
        return {
          kind: 'image',
          id: section.id,
          ...getImageSectionProps(section),
        };
      case 'ComponentUiVideoSection':
        return {
          kind: 'video',
          id: section.id,
          ...getVideoSectionProps(section),
        };
      default:
        return assertNever(section);
    }
  });
  return sections;
};

export const getStandardContentProps = (
  data: {
    page: NonNullable<StandardContentData['pages'][0]>;
  } & GlobalData
): StandardContentProps => {
  const { page, global } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: page.meta_title,
    metaDescription: page.meta_description,
    shareImage: page.share_image ? page.share_image.url : undefined,
    title: page.title,
    subtitle: page.subtitle,
    sections: getSectionProps(page.sections, global),
  };
};

export const getServiceProps = (
  data: {
    service: NonNullable<ServiceData>;
  } & GlobalData
): ServiceProps => {
  const { global, service } = data;

  // Mapping CMS enum values to their front-end display values
  // TODO: These labels need to be the same as in the CMS code (api/service/models/service.ts)
  // TODO: Should they be moved to a common code folder?
  const Booking = {
    [YesNoSometimes.Yes]: 'Booking needed',
    [YesNoSometimes.No]: 'No booking needed',
    [YesNoSometimes.Sometimes]: 'Booking sometimes needed',
  };
  const Referral = {
    [YesNoSometimes.Yes]: 'Referral needed',
    [YesNoSometimes.No]: 'No referral needed',
    [YesNoSometimes.Sometimes]: 'Referral sometimes needed',
  };
  const Cost = {
    [YesNoSometimes.Yes]: 'There is a cost',
    [YesNoSometimes.No]: 'No cost',
    [YesNoSometimes.Sometimes]: 'Cost sometimes applies',
  };

  // Return empty arrray if no service_delivery_formats
  // or map service_delivery_formats and return array of strings
  const serviceDeliveryFormats = (service.service_delivery_formats ?? []).map(
    ({ name }) => name
  );

  // Return empty arrray if no languages
  // or map languages and return array of strings
  const languages = (service.languages ?? []).map((languageEntry) => {
    return languageEntry.name;
  });

  let venueInfo;
  if (service.Venue !== null) {
    // Return empty arrray if no accessibilityFeatures
    // or map accessibilityFeatures and return array of strings
    const accessibilityFeatures = (service.Venue.accessibilities ?? []).map(
      (accessEntry) => {
        return accessEntry.name;
      }
    );

    venueInfo = {
      name: service.Venue.name,
      address: service.Venue.address,
      accessibility: accessibilityFeatures,
      transport: service.Venue.transport,
      hasLocation: !!service.Venue.latitude,
    };
  }

  return {
    pageProps: getPageProps(data),
    metaTitle: service.meta_title,
    metaDescription: service.meta_description,
    shareImage: service.share_image ? service.share_image.url : undefined,
    serviceId: service.id,
    title: service.title,
    provider: service.provider,
    description: service.description,
    contact: {
      website: service.website,
      emailAddresses: service.EmailAddresses.map(
        (emailEntry) => emailEntry.email_address
      ),
      phoneNumbers: service.PhoneNumbers.map((phoneEntry) => {
        return {
          number: phoneEntry.phone_number,
          label: phoneEntry.contact_name,
        };
      }),
    },
    whereAndWhen: {
      areasServiced: service.areas_serviced,
      when: service.when,
      formats: serviceDeliveryFormats,
    },
    venue: venueInfo,
    misc: {
      booking: service.booking_needed
        ? Booking[service.booking_needed]
        : service.booking_needed,
      referral: service.referral_needed
        ? Referral[service.referral_needed]
        : service.booking_needed,
      languages: languages,
    },
    cost: {
      cost: service.cost ? Cost[service.cost] : service.cost,
      howMuch: service.price,
      comments: service.cost_comment,
    },
    hubsListHeading: global.hubs_list_heading,
    hubs: getHubListProps(service.hubs),
    categories: service.service_types.map((service_type: ServiceTypesData) => ({
      name: service_type.name,
      total: service_type.services.length,
    })),
  };
};

export const getNumberOfRelatedServices = (
  categories: {
    service_type: {
      name: string;
      services: Pick<ServiceData, 'id'>[];
    };
  }[]
) => {
  const serviceIds: string[] = [];
  categories.forEach((category) => {
    category.service_type.services.forEach((service) =>
      serviceIds.push(service.id)
    );
  });
  const onlyUniqueServiceIds = serviceIds.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  return onlyUniqueServiceIds.length;
};

export const getRelatedServices = (
  services: Pick<
    ServiceData,
    'id' | 'title' | 'provider' | 'description' | 'slug'
  >[],
  featuredServices: {
    service: Pick<
      ServiceData,
      'id' | 'title' | 'provider' | 'description' | 'slug'
    >;
  }[]
): RelatedServicesProps[] => {
  let allRelatedServices: Pick<
    ServiceData,
    'id' | 'title' | 'provider' | 'description' | 'slug'
  >[] = [];
  // Fiter out any services added to the hub but no service has been selected
  const featuredServicesFiltered = featuredServices.filter((service) => {
    return service.service !== null;
  });
  if (featuredServices) {
    const featuredServicesIds = featuredServicesFiltered.map(
      ({ service }) => service.id
    );
    const servicesFeaturedRemoved = services.filter(
      (s) => !featuredServicesIds.includes(s.id)
    );
    const allFeaturedServices = featuredServicesFiltered.map(
      ({ service }) => service
    );
    allRelatedServices = [
      ...allFeaturedServices,
      ...servicesFeaturedRemoved,
    ].slice(0, 4);
  }
  if (!featuredServices) {
    allRelatedServices = [...services].slice(0, 4);
  }
  return allRelatedServices.map((service) => ({
    serviceId: service.id,
    title: service.title,
    provider: service.provider,
    description: service.description,
    url: service.slug ? getServiceUrl(service.slug) : '/404',
  }));
};

export const getHubProps = (
  data: {
    hub: NonNullable<HubData['hubs'][0]>;
  } & GlobalData
): HubProps => {
  const { hub } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: hub.meta_title,
    metaDescription: hub.meta_description,
    shareImage: hub.share_image ? hub.share_image.url : undefined,
    title: hub.title,
    illustrationDesktopHeader:
      hub.illustration_desktop_header &&
      getImageUrl(hub.illustration_desktop_header.url),
    illustrationMobileHeader:
      hub.illustration_mobile_header &&
      getImageUrl(hub.illustration_mobile_header.url),
    textBlock: hub.text_block,
    conversationStarters: hub.ConversationCards.map(
      ({ conversation_starter }) => ({
        heading: conversation_starter.title,
        description: conversation_starter.description,
        questions: conversation_starter.Questions?.map((q) => q.question) || [],
      })
    ),
    categories: hub.categories
      .filter((service) => service.service_type.services.length !== 0)
      .map((service) => ({
        name: service.service_type.name,
        total: service.service_type.services.length,
      })),
    relatedServices: {
      heading: data.global.related_services_heading,
      services: getRelatedServices(hub.services, hub.FeaturedServices),
      totalRelatedServices: getNumberOfRelatedServices(hub.categories),
      relatedServicesCategoryTags: hub.categories.map(
        (service) => service.service_type.name
      ),
    },
    faqs: {
      heading: data.global.faq_list_heading,
      faqs: getFaqsProps(hub.FaqsSection),
      button: {
        label: data.global.faq_list_button_label,
        href: '/faq',
      },
    },
  };
};

export const getFaqDetailsProps = (
  data: {
    faq: NonNullable<FaqDetailsData['faqs'][0]>;
    faqPage: FaqDetailsData['faqPage'];
    faqListPage: FaqDetailsData['faqListPage'];
  } & GlobalData
): FaqDetailsProps => {
  const { faq, faqPage, faqListPage } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: faq.meta_title,
    metaDescription: faq.meta_description || faqListPage.description,
    shareImage: faq.share_image ? faq.share_image.url : undefined,
    id: faq.id,
    title: faq.question,
    description: faq.description,
    illustrationMobileHeader: getImageUrl(
      faqPage.illustration_mobile_header.url
    ),
    illustrationDesktopHeader: getImageUrl(
      faqPage.illustration_desktop_header.url
    ),
    disclaimerTop: {
      title: faqPage.TopDisclaimer.title,
      description: faqPage.TopDisclaimer.description,
    },
    disclaimerBottom: {
      title: faqPage.BottomDisclaimer.title,
      description: faqPage.BottomDisclaimer.description,
    },
    conversationStarters: faq.conversation_starters.map((c) => ({
      title: c.title,
      url: getConversationStarterUrl(c.slug),
    })),
    resources: faq.Resources.map((r) => ({
      title: r.title,
      description: r.description,
      url: r.url,
    })),
  };
};

export const getFaqListProps = (data: FaqListData): FaqListProps => {
  const { faqListPage, faqs } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: faqListPage.meta_title,
    metaDescription: faqListPage.meta_description || faqListPage.description,
    shareImage: faqListPage.share_image
      ? faqListPage.share_image.url
      : undefined,
    title: faqListPage.title,
    description: faqListPage.description,
    illustrationDesktopHeader:
      faqListPage.illustration_desktop_header &&
      getImageUrl(faqListPage.illustration_desktop_header.url),
    illustrationMobileHeader:
      faqListPage.illustration_mobile_header &&
      getImageUrl(faqListPage.illustration_mobile_header.url),
    faqs: faqs.map((faq) => ({
      title: faq.question,
      url: getFaqUrl(faq.slug),
    })),
  };
};

export const getConversationStarterProps = (
  data: ConversationStarterData
): Omit<ConversationStartersProps, 'selectedIndex' | 'onChangeIndex'> => {
  const { conversationStarters } = data;

  return {
    pageProps: getPageProps(data),
    conversationStarters: conversationStarters.map((c) => ({
      metaTitle: c.meta_title,
      metaDescription: c.meta_description || c.description,
      shareImage: c.share_image ? c.share_image.url : undefined,
      heading: c.title,
      description: c.description,
      questions: c.Questions.map((q) => q.question),
      slug: c.slug,
    })),
  };
};

export const getGlossaryProps = (data: GlossaryData): GlossaryProps => {
  const { glossaryPage, glossaryTerms } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: glossaryPage.meta_title,
    metaDescription: glossaryPage.meta_description,
    shareImage: glossaryPage.share_image
      ? glossaryPage.share_image.url
      : undefined,
    title: glossaryPage.title,
    description: glossaryPage.description,
    words: glossaryTerms.map((term) => ({
      word: term.word,
      definition: term.definition,
    })),
    illustrationMobileHeader: getImageUrl(
      glossaryPage.illustration_mobile_header.url
    ),
    illustrationDesktopHeader: getImageUrl(
      glossaryPage.illustration_desktop_header.url
    ),
  };
};

export const getSavedPageProps = (
  data: SavedPageData
): Omit<SavedProps, 'loading' | 'savedItems'> => {
  const { savedPage } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: savedPage.meta_title,
    metaDescription: savedPage.meta_description,
    shareImage: savedPage.share_image ? savedPage.share_image.url : undefined,
    title: savedPage.title,
    subtitle: savedPage.subtitle,
    description: savedPage.description,
    explanation: savedPage.explanation,
    disclaimer: {
      title: savedPage.Disclaimer.title,
      description: savedPage.Disclaimer.description,
    },
    illustrationMobileHeader: getImageUrl(
      savedPage.illustration_mobile_header.url
    ),
    illustrationDesktopHeader: getImageUrl(
      savedPage.illustration_desktop_header.url
    ),
  };
};

export const getError404Props = (data: Error404Data): Error404Props => {
  const { hubsFor404 } = data;

  return {
    pageProps: getPageProps(data),
    hubs:
      hubsFor404 &&
      hubsFor404.map((hub) => ({
        title: hub.title,
        url: getHubUrl(hub.slug),
      })),
  };
};

const getBlogCategories = (
  blogCategories: {
    category: string;
    blog_posts: {
      id: string;
    }[];
  }[]
): { name: string }[] => {
  const categories = blogCategories
    .filter((category) => {
      return category.blog_posts.length > 0;
    })
    .map((category) => {
      return {
        name: category.category,
      };
    });
  return categories;
};

export const getBlogPostProps = (
  data: {
    blogPost: NonNullable<BlogPostData['blogPosts'][0]>;
  } & { recentBlogPosts: BlogPostTileData[] } & GlobalData
): BlogPostProps => {
  const { blogPost, global, recentBlogPosts } = data;

  return {
    pageProps: getPageProps(data),
    metaTitle: blogPost.meta_title,
    metaDescription: blogPost.meta_description,
    shareImage: blogPost.share_image ? blogPost.share_image.url : undefined,
    title: blogPost.title,
    description: blogPost.description,
    heroImage: blogPost.hero_image
      ? {
          url: getImageUrl(blogPost.hero_image.url),
          alt: blogPost.hero_image.alternativeText ?? undefined,
          width: blogPost.hero_image.width ?? undefined,
          height: blogPost.hero_image.height ?? undefined,
        }
      : undefined,
    sections: getSectionProps(blogPost.sections, global),
    categories: blogPost.blog_categories
      ? blogPost.blog_categories.map((cat) => {
          return cat.category;
        })
      : [],
    recentBlogPosts: getBlogPostTileProps(recentBlogPosts),
  };
};

export const getBlogListProps = (data: BlogListData): BlogListProps => {
  const { blogListPage, blogPosts, blogCategories } = data;
  return {
    pageProps: getPageProps(data),
    metaTitle: blogListPage.meta_title,
    metaDescription: blogListPage.meta_description,
    shareImage: blogListPage.share_image
      ? blogListPage.share_image.url
      : undefined,
    title: blogListPage.title || 'Precious Time blog',
    description:
      blogListPage.description ||
      'Browse our collection of resources and stories',
    illustrationMobileHeader:
      (blogListPage.illustration_mobile_header &&
        getImageUrl(blogListPage.illustration_mobile_header.url)) ||
      undefined,
    illustrationDesktopHeader:
      (blogListPage.illustration_desktop_header &&
        getImageUrl(blogListPage.illustration_desktop_header.url)) ||
      undefined,
    blogPosts: getBlogPostTileProps(blogPosts),
    blogCategories: blogCategories ? getBlogCategories(blogCategories) : [],
  };
};

export const getResourceTileProps = (
  resources: ResourceTileData[]
): ResourceTileProps[] => {
  return resources.map((resource) => ({
    url: resource.document?.url
      ? getFileUrl(resource.document.url)
      : resource.external_resource_url || '',
    title: resource.title,
    description: resource.description,
    heroImage: resource.hero_image
      ? {
          url: getImageUrl(resource.hero_image.url),
          alt: resource.hero_image.alternativeText ?? undefined,
          width: resource.hero_image.width ?? undefined,
          height: resource.hero_image.height ?? undefined,
        }
      : undefined,
  }));
};

export const getResourceListProps = (
  data: ResourceListData
): ResourceListProps => {
  const { resourceListPage, resources } = data;
  return {
    pageProps: getPageProps(data),
    metaTitle: resourceListPage.meta_title,
    metaDescription: resourceListPage.meta_description,
    shareImage: resourceListPage.share_image
      ? resourceListPage.share_image.url
      : undefined,
    title: resourceListPage.title || 'Precious Time blog',
    description:
      resourceListPage.description || 'Browse our collection of resources',
    illustrationMobileHeader:
      (resourceListPage.illustration_mobile_header &&
        getImageUrl(resourceListPage.illustration_mobile_header.url)) ||
      undefined,
    illustrationDesktopHeader:
      (resourceListPage.illustration_desktop_header &&
        getImageUrl(resourceListPage.illustration_desktop_header.url)) ||
      undefined,
    resources: getResourceTileProps(resources),
  };
};
