import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { rem } from 'polished';
import Router from 'next/router';
import { Icon, Text, Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { generateQueryState, searchStateToUrl } from '@/utils/search';
import gridConfig from '@/theme/grid';
import { Button, SearchInput, ServiceAndCategorySearch } from '@/components/ui';
import { useSearchModal } from '@/components/providers';
import { FlexGrid, FlexGridRow, FlexGridItem } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';

export type SearchResultsHeaderProps = {
  inputPlaceholder: string;
  imageDesktopUrl: string;
  onOpenFilters: () => void;
  onViewMap: () => void;
  numberOfFilters: number;
  parentQuery?: string;
} & FlexProps;

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = React.memo(
  function SearchResultsHeader({
    inputPlaceholder,
    imageDesktopUrl,
    onOpenFilters,
    onViewMap,
    numberOfFilters,
    parentQuery = '',
    ...rest
  }) {
    const searchModal = useSearchModal();
    const [query, setQuery] = useState({ query: parentQuery });

    const handleSubmit = useCallback((q: string) => {
      const queryState = generateQueryState(q, Router.asPath);
      Router.push(searchStateToUrl('/search', queryState));
    }, []);

    const onSubmit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        handleSubmit(formData.get('query') as string);
      },
      [handleSubmit]
    );

    const onSelectResult = useCallback(
      (q: string) => {
        handleSubmit(q);
      },
      [handleSubmit]
    );

    useEffect(() => {
      setQuery({ query: parentQuery });
    }, [parentQuery]);

    return (
      <FlexGrid
        backgroundColor="lately.core"
        backgroundImage={{ base: '', lg: `url(${imageDesktopUrl})` }}
        backgroundRepeat="no-repeat"
        backgroundSize={{ base: '0px', lg: '354px' }}
        backgroundPosition="101% 100%"
        borderRadius={{ base: 0, lg: '2px' }}
        color="lately.highlights03"
        paddingX={{
          base: gridConfig.margin.base,
          md: gridConfig.margin.md,
          lg: `${parseFloat(gridConfig.margin.lg) / 2}rem`,
        }}
        paddingY={{ base: 2, md: 4, lg: 10 }}
        {...rest}
      >
        <FlexGridRow justifyContent="space-between">
          <FlexGridItem colWidth={{ base: 4, md: 2, lg: 7 }} direction="column">
            <Button
              variant="unstyled"
              aria-label="Open search"
              onClick={() => searchModal.onOpenPageTwo(true)}
              alignItems="center"
              backgroundColor="white"
              borderRadius="2px"
              display={{ base: 'flex', lg: 'none' }}
              height={rem('48px')}
              justifyContent="flex-start"
              paddingX={4}
              paddingY={0}
            >
              <Icon
                name="search"
                color="lately.core02"
                marginRight={rem('10px')}
                size={rem('22px')}
              />
              <Text
                as="span"
                {...textStyles.body}
                overflow="hidden"
                style={{ textOverflow: 'ellipsis' }}
              >
                {parentQuery !== '' ? parentQuery : inputPlaceholder}
              </Text>
            </Button>
            <ServiceAndCategorySearch
              onSearchStateChange={(state) => setQuery(state)}
              searchState={query}
            >
              <SearchInput
                inputPlaceholder={inputPlaceholder}
                display={useMemo(() => ({ base: 'none', lg: 'flex' }), [])}
                paddingRight={useMemo(() => ({ base: 0, md: 6, lg: 0 }), [])}
                width="100%"
                onSubmit={onSubmit}
                onSelectResult={onSelectResult}
              />
            </ServiceAndCategorySearch>
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 2 }}
            display={{ base: 'flex', lg: 'none' }}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            marginTop={{ base: 2, md: 0 }}
          >
            <Button flexShrink={0} onClick={onOpenFilters}>
              <Flex justifyContent="space-between">
                <Icon name="filter" size={rem('24px')} />
                <Text paddingLeft={2}>
                  {numberOfFilters > 0
                    ? `Edit filters (${numberOfFilters})`
                    : 'Filters'}
                </Text>
              </Flex>
            </Button>
            <Button
              onClick={onViewMap}
              flexShrink={0}
              marginLeft={{ base: 0, md: 2 }}
            >
              Map view
            </Button>
          </FlexGridItem>
        </FlexGridRow>
      </FlexGrid>
    );
  }
);

export default SearchResultsHeader;
