import React from 'react';
import NextLink from 'next/link';

import { Link } from '@chakra-ui/core';
import * as textStyles from '@/theme/textStyles';
import useUrlPolyfill from '@/utils/useUrlPolyfill';

type Props = {
  children: string[];
  href: string;
};

const MarkdownLink: React.FC<Props> = ({ href, children }) => {
  // Load urlPolyfill for accessing the URL hostname on browsers that need it
  useUrlPolyfill();

  let url: URL | null = null;
  // if the url is a loval one like '/faq/what-is-end-of-life-care'
  // then creating a new instance of an URL will throw the error 'invalid url'
  try {
    url = new URL(href);
  } catch (_) {
    // do nothing
  }

  if (
    // check whether the url hostname matches the site base url
    (url &&
      process.env.SITE_URL?.includes(url.hostname) &&
      url.hostname !== '') ||
    // or if it missing the domain and therefore is an internal url
    href[0] === '/' ||
    href.slice(0, 4) === 'tel:' ||
    href.slice(0, 7) === 'mailto:'
  ) {
    return (
      <NextLink href={href} passHref>
        <Link
          {...textStyles.link}
          textDecoration="underline"
          _hover={{ textDecoration: 'none' }}
        >
          {children}
        </Link>
      </NextLink>
    );
  }

  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...textStyles.link}
      textDecoration="underline"
      _hover={{ textDecoration: 'none' }}
    >
      {children[0]}
    </Link>
  );
};

export default MarkdownLink;
