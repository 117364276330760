import qs from 'qs';
import { LocationType } from '@/lib/graphql/queries';

type Refinement = '' | string | string[];

export type SearchState = {
  page?: number;
  query?: string;
  refinementList?: {
    'Venue.accessibilities.name'?: Refinement;
    'Suburb.name'?: Refinement;
    'LGA.name'?: Refinement;
    'service_types.name'?: Refinement;
    'languages.name'?: Refinement;
    'service_delivery_formats.name'?: Refinement;
    referral_label?: Refinement;
    booking_label?: Refinement;
    cost_label?: Refinement;
    type?: Refinement;
  };
  filters?: string;
};

export const urlToSearchState = (path: string) =>
  (qs.parse(path.split(/\?/)[1]) as unknown) as SearchState;

// Used to clear out empty search state from the url
const isSearchStateEmpty = (state?: SearchState) => {
  if (!state) return true;

  const refinements = state.refinementList || {};

  const refinementKeys = Object.keys(refinements) as (keyof NonNullable<
    SearchState['refinementList']
  >)[];

  const hasRefinements = refinementKeys.some((k) => refinements[k]);

  return (!state.page || state.page === 1) && !state.query && !hasRefinements;
};

export const searchStateToUrl = (
  pathname: string,
  searchState?: SearchState
) => {
  if (isSearchStateEmpty(searchState)) return pathname;
  return `${pathname}?${qs.stringify(searchState)}`;
};
// Merge existing url state with new query param
export const generateQueryState = (query: string, currentPath: string) => {
  const currentState = urlToSearchState(currentPath);
  if (isSearchStateEmpty(currentState)) return { query };
  return {
    ...currentState,
    query,
  };
};
// Only needs to generate one at a time as this will be used to deeplink from service detail to search results
export const generateRefinementState = (
  name: keyof NonNullable<SearchState['refinementList']>,
  value: string
) => {
  return {
    refinementList: {
      [name]: [value],
    },
  };
};

export const locationTypeRefinements: {
  [key: string]: keyof NonNullable<SearchState['refinementList']>;
} = {
  [LocationType.Country]: 'LGA.name', // We don't filter by country or state, we filter on a special value inside the LGA refinement
  [LocationType.State]: 'LGA.name',
  [LocationType.LGA]: 'LGA.name',
  [LocationType.Suburb]: 'Suburb.name',
};

export const getLocationName = (location: {
  name: string;
  type: LocationType;
}) =>
  [LocationType.Country, LocationType.State].includes(location.type)
    ? `Available throughout ${location.name}` // TODO: this should be moved to a common folder as it is duplicated in the CMS
    : location.name;

export const generateRefinementAsUrl = (
  name: keyof NonNullable<SearchState['refinementList']>,
  value: string,
  pathName = '/search'
) => {
  const state = generateRefinementState(name, value);
  return searchStateToUrl(pathName, state);
};

export const generateServiceCategoryRefinementsAsUrl = (
  refinements: string[]
) => {
  const refinementState = {
    refinementList: { type: 'Services', 'service_types.name': <string[]>[] },
  };
  refinements.forEach((r) => {
    refinementState.refinementList['service_types.name'].push(r);
  });

  return searchStateToUrl('/search', refinementState);
};

// Pull the special synthetic filters to the top of the order e.g Available throughout VIC come before all the other suburb filters
export const getRefinementSortOrder = (item: {
  label: string;
  value: string[];
}) => (item.label.startsWith('Available ') ? -1 : 0);

// Generate a flat array of applied refinements
export const getAppliedRefinements = (
  refinementList: NonNullable<SearchState['refinementList']>
): string[] => {
  const appliedRefinements: string[] = [];

  Object.keys(refinementList).forEach((k) => {
    const val = refinementList[k as keyof typeof refinementList];
    if (val === undefined || val === '') return;

    if (Array.isArray(val)) {
      appliedRefinements.push(...val);
    } else {
      appliedRefinements.push(val);
    }
  });

  return appliedRefinements;
};
