import { rem } from 'polished';

const gridConfig = {
  columns: {
    base: 4,
    md: 4,
    lg: 12,
  },
  gutter: {
    base: rem('8px'),
    md: rem('16px'),
    lg: rem('24px'),
  },
  margin: {
    base: rem('16px'),
    md: rem('32px'),
    lg: rem('96px'),
  },
} as const;

export default gridConfig;
