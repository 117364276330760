/** @jsx jsx */
import React from 'react';
import { rem } from 'polished';
import { css, jsx } from '@emotion/core';
import { Flex, useTheme } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Button } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import type { CustomTheme } from '@/theme/chakra';

type Action = React.ComponentProps<typeof Button>;

export const PrintAction: Action = {
  key: 'print',
  leftIcon: 'print',
  children: 'Print',
  onClick: () => window.print(),
};

export const ShareAction: Action = {
  key: 'share',
  variant: 'secondary',
  leftIcon: 'share',
  children: 'Share with someone',
  onClick: () => {},
};

export const SaveAction: Action = {
  key: 'save',
  variant: 'secondary',
  children: 'Save for later',
  onClick: () => {},
};

export const defaultActions = [PrintAction, ShareAction, SaveAction];

type Props = {
  actions?: Action[];
  stackDirection?: FlexProps['flexDirection'];
} & FlexProps;

const ActionsSection: React.FC<Props> & {
  Actions: {
    Print: typeof PrintAction;
    Share: typeof ShareAction;
    Save: typeof SaveAction;
  };
} = ({ actions = defaultActions, stackDirection, children, ...rest }) => {
  // The Chakra UI Stack component doesn't (yet) support responsive direction
  // Instead we have to add margins to the buttons with "manual" CSS
  const theme = useTheme() as CustomTheme;

  return (
    <FlexGridRow {...rest}>
      <FlexGridItem>
        <Flex
          alignItems="flex-start"
          direction={stackDirection ?? { base: 'column', md: 'row' }}
          flexWrap="wrap"
          width="100%"
          css={css`
            margin: -${rem('6px')};

            & > * {
              margin: ${rem('6px')};
            }

            @media (min-width: ${theme.breakpoints.md}) {
              margin: -${rem('8px')};

              & > * {
                margin: ${rem('8px')};
              }
            }
          `}
        >
          {actions.map((props) => (
            <Button {...props} />
          ))}
          {children}
        </Flex>
      </FlexGridItem>
    </FlexGridRow>
  );
};

ActionsSection.Actions = {
  Print: PrintAction,
  Save: SaveAction,
  Share: ShareAction,
};

export default ActionsSection;
