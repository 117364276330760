import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  connectSearchBox,
  connectHits,
  connectHighlight,
  Configure,
} from 'react-instantsearch-dom';
import {
  Divider,
  Flex,
  Icon,
  Input,
  ModalContent,
  Stack,
  Text,
} from '@chakra-ui/core';
import { rem } from 'polished';
import reactFastCompare from 'react-fast-compare';
import type {
  SearchBoxProvided,
  HitsProvided,
  HighlightProps as THighlightProps,
} from 'react-instantsearch-core';

import * as textStyles from '@/theme/textStyles';
import useViewportHeight from '@/utils/useViewportHeight';
import { Button, PopularCategories } from '@/components/ui';
import type { PopularCategoriesProps } from '@/components/ui';
import type { CustomHit } from '@/components/ui/SearchInput/SearchResultHits';

/**
 * Hit
 */

type HitProps = {
  hit: CustomHit;
  onSelect: (query: string) => void;
} & THighlightProps;

const Hit: React.FC<HitProps> = React.memo(function Hit({
  hit,
  onSelect,
  attribute,
  highlight,
}) {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  const label = hit.title || hit.name || hit.question || '';

  return (
    <Button
      variant="unstyled"
      onClick={() => onSelect(label)}
      {...textStyles.body}
      flexShrink={0}
      fontWeight="normal"
      justifyContent="flex-start"
      paddingX={4}
      paddingY={2}
      textDecoration="none"
      width="100%"
    >
      {parsedHit.map(({ value, isHighlighted }, i) => {
        const Component = isHighlighted ? 'strong' : 'span';
        // eslint-disable-next-line react/no-array-index-key
        return <Component key={`${value}${i}`}>{value}</Component>;
      })}
    </Button>
  );
},
reactFastCompare);

const ConnectedHit = connectHighlight<HitProps, CustomHit>(
  function ConnectedHit(props) {
    return <Hit {...props} />;
  }
);

/**
 * Hits
 */

type HitsProps = Pick<HitProps, 'onSelect'> & HitsProvided<CustomHit>;

const Hits: React.FC<HitsProps> = React.memo(function Hits({ hits, onSelect }) {
  return (
    <>
      {hits.map((h) => {
        const attribute = ['title', 'name', 'question'].find((a) => h[a]);
        if (attribute === undefined) return null;
        // Trim white space from missing attribute string and return null so there are no blank spaces in the dropdown list.
        if (h[attribute].trim() === '') return null;
        return (
          <ConnectedHit
            key={h.objectID}
            hit={h}
            attribute={attribute}
            onSelect={onSelect}
          />
        );
      })}
    </>
  );
}, reactFastCompare);

const ConnectedHits = connectHits<HitsProps, CustomHit>(function ConnectedHits(
  props
) {
  return <Hits {...props} />;
});

/**
 * QueryListener
 */

type QueryListenerProps = {
  children: (query: string) => JSX.Element | null;
} & SearchBoxProvided;

const QueryListener = connectSearchBox<QueryListenerProps>(
  ({ currentRefinement, children }) => {
    return children(currentRefinement);
  }
);

/**
 * SearchModalContentMobile
 */

type Props = {
  onClose: () => void;
  popularCategories: Omit<PopularCategoriesProps, 'numberOfCategoriesToShow'>;
  defaultPage?: 1 | 2;
  onSubmit: (query: string) => void;
};

const SearchModalContentMobile: React.FC<Props> = React.memo(
  function SearchModalContentMobile({
    onClose,
    popularCategories,
    defaultPage = 1,
    onSubmit,
    ...rest
  }) {
    // Mobile view has 2 pages: the "popular categories" page and the actual search
    const [page, setPage] = useState<1 | 2>(defaultPage);

    // Automatically focus the "search" input when the search page is shown
    const inputEl = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
      if (page === 2 && inputEl.current !== null) {
        setTimeout(() => inputEl?.current?.focus(), 0);
      }
    }, [page]);

    // Component is defined here so it can use the inputEl ref (connectSearchBox does not forward refs)
    const SearchBox = useMemo(
      () =>
        connectSearchBox(({ currentRefinement, refine }) => (
          <Input
            ref={inputEl}
            name="query"
            variant="unstyled"
            value={currentRefinement}
            placeholder="I'm looking for..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              refine(e.target.value);
            }}
          />
        )),
      []
    );

    // Set the modal height to 100vh to make it fill the screen
    const viewportHeight = useViewportHeight();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.target as HTMLFormElement);
      onSubmit(formData.get('query') as string);
    };

    return (
      <ModalContent
        backgroundColor="white"
        height={viewportHeight}
        margin={0}
        maxWidth="none"
        {...rest}
      >
        {page === 1 && (
          <Flex direction="column" height="100%">
            <Stack
              backgroundColor="lately.core"
              flexShrink={0}
              padding={4}
              spacing={4}
            >
              <Button
                aria-label="Close"
                onClick={onClose}
                borderRadius="2px"
                height={rem('48px')}
                marginLeft="auto"
                width={rem('48px')}
              >
                <Icon name="close" color="white" size={rem('14px')} />
              </Button>
              <Button
                variant="unstyled"
                onClick={() => setPage(2)}
                alignItems="center"
                backgroundColor="white"
                borderRadius="2px"
                display="flex"
                height={rem('48px')}
                justifyContent="flex-start"
                paddingX={4}
                paddingY={0}
              >
                <Icon
                  name="search"
                  color="lately.core02"
                  marginRight={rem('10px')}
                  size={rem('22px')}
                />
                <Text as="span" {...textStyles.body}>
                  Search here
                </Text>
              </Button>
            </Stack>
            <Flex
              direction="column"
              flexGrow={1}
              flexShrink={1}
              marginTop={rem('22px')}
              paddingBottom={rem('22px')}
              paddingX={4}
              overflowY="auto"
            >
              <PopularCategories
                numberOfCategoriesToShow={6}
                isModal
                {...popularCategories}
              />
            </Flex>
          </Flex>
        )}
        {page === 2 && (
          <Flex
            as="form"
            direction="column"
            height="100%"
            onSubmit={handleSubmit}
          >
            <Flex
              direction="column"
              flexShrink={0}
              padding={4}
              paddingBottom={0}
            >
              <Flex justifyContent="space-between">
                <Button
                  aria-label="Back"
                  variant="secondary"
                  onClick={() => setPage(1)}
                  borderRadius="2px"
                  height={rem('48px')}
                  width={rem('48px')}
                >
                  <Icon
                    name="accordionChevron"
                    size={rem('14px')}
                    transform="rotate(-90deg)"
                  />
                </Button>
                <Button
                  aria-label="Close"
                  onClick={onClose}
                  borderRadius="2px"
                  height={rem('48px')}
                  width={rem('48px')}
                >
                  <Icon name="close" color="white" size={rem('14px')} />
                </Button>
              </Flex>
              <Flex alignItems="center" height={rem('48px')}>
                <Icon
                  name="search"
                  color="lately.core02"
                  marginRight={rem('10px')}
                  size={rem('22px')}
                />
                <SearchBox />
              </Flex>
              <Divider
                borderBottomWidth="2px"
                borderColor="lately.text"
                margin={0}
                opacity={0.4}
              />
            </Flex>
            <Flex
              direction="column"
              flexGrow={1}
              flexShrink={1}
              overflowY="auto"
              paddingTop={4}
            >
              <QueryListener>
                {(query) => {
                  // Only show the suggestions if the query is at least 2 chars long
                  const isVisible = query.length > 1;
                  return (
                    <>
                      <Configure hitsPerPage={isVisible ? 10 : 0} />
                      {isVisible && <ConnectedHits onSelect={onSubmit} />}
                    </>
                  );
                }}
              </QueryListener>
            </Flex>
            <Flex flexShrink={0} paddingX={4} paddingY={1}>
              <Button type="submit" width="100%">
                Search
              </Button>
            </Flex>
          </Flex>
        )}
      </ModalContent>
    );
  }
);

export default SearchModalContentMobile;
