import React from 'react';
import NextLink from 'next/link';
import { Flex, Heading, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps, HeadingProps } from '@chakra-ui/core';

import { Button, ButtonGrid } from '@/components/ui';
import { generateRefinementAsUrl } from '@/utils/search';
import * as textStyles from '@/theme/textStyles';

export type ServiceCostProps = {
  cost?: string;
  howMuch?: string;
  comments?: string;
} & FlexProps;

const ServiceCost: React.FC<ServiceCostProps> = ({
  cost,
  howMuch,
  comments,
  ...rest
}) => {
  if (!cost) return null;

  const Subheading: React.FC<HeadingProps> = (props) => (
    <Heading as="h3" {...textStyles.h4} {...props} />
  );

  return (
    <Flex
      backgroundColor="lately.background"
      direction="column"
      width="100%"
      {...rest}
    >
      <Stack spacing={{ base: 6, lg: 10 }}>
        <Heading as="h2" {...textStyles.h2}>
          Cost
        </Heading>
        <Stack spacing={4}>
          <Subheading>Is there a cost?</Subheading>
          <ButtonGrid>
            <NextLink
              href={generateRefinementAsUrl('cost_label', cost)}
              passHref
            >
              <Button variant="feature" as={Link}>
                {cost}
              </Button>
            </NextLink>
          </ButtonGrid>
        </Stack>
        {howMuch && (
          <Stack spacing={2}>
            <Subheading>How much?</Subheading>
            <Text {...textStyles.body} wordBreak="break-word">
              {howMuch}
            </Text>
          </Stack>
        )}
        {comments && (
          <Stack spacing={2}>
            <Subheading>Comments:</Subheading>
            <Text {...textStyles.body} wordBreak="break-word">
              {comments}
            </Text>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
};

export default ServiceCost;
