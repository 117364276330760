import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Box, Image, Text, Link as ChakraLink, Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import { Button } from '@/components/ui';
import type { ImageProps } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type BlogTileProps = {
  title: string;
  url: string;
  description: string;
  categories: string[];
  filterByCategory?: (categoryUpdated: {
    name: string;
    isSelected: boolean;
  }) => void;
  heroImage?: ImageProps;
};

type BlogTileTemplateProps = BlogTileProps & FlexProps;

const BlogTile: React.FC<BlogTileTemplateProps> = ({
  title,
  description,
  url,
  heroImage,
  categories,
  filterByCategory,
  ...rest
}) => {
  const router = useRouter();

  return (
    <Box
      width={{ base: '100%', md: '328px', lg: '340px' }}
      maxWidth={{ base: '340px', md: '328px', lg: '340px' }}
      {...rest}
    >
      <Link href={url} passHref>
        <ChakraLink>
          <Box
            width="100%"
            height="auto"
            maxHeight={{ base: '216px', md: '216px', lg: '228px' }}
            overflow="hidden"
            borderRadius="16px"
          >
            {heroImage && (
              <Image
                src={heroImage.url}
                alt={heroImage.alt || ''}
                width={340}
                height={190}
                style={{ objectFit: 'cover' }}
              />
            )}
          </Box>
          <Text
            as="h4"
            marginTop={{ base: 6, md: 8 }}
            marginBottom={4}
            {...textStyles.h4}
          >
            {title}
          </Text>
        </ChakraLink>
      </Link>
      <Text>{description}</Text>
      <Flex marginTop={{ base: 6, md: 8 }} marginBottom={4} flexWrap="wrap">
        {categories.map((category) => (
          <Button
            aria-label={`Filter blog page by the ${category} category`}
            key={category}
            variant="textLink"
            marginRight={2}
            marginBottom={2}
            role="button"
            onClick={() => {
              if (filterByCategory) {
                filterByCategory({ name: category, isSelected: true });
              } else {
                router.push(
                  {
                    pathname: '/blog',
                    query: { filter: category },
                  },
                  `/blog?filter=${category.replace(' ', '+')}`,
                  { shallow: false }
                );
              }
            }}
          >
            <Text
              {...textStyles.smallData}
              fontWeight="bold"
              backgroundColor="lately.core0210%"
              paddingX={2}
              paddingY={1}
              width="fit-content"
            >
              {category}
            </Text>
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default BlogTile;
