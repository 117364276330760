import React from 'react';
import { rem } from 'polished';
import { Box, Flex, Heading, Icon, Image, Stack, Text } from '@chakra-ui/core';

import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import type { HomeHeaderProps } from '@/components/ui';

const HomeHeaderCampaign: React.FC<HomeHeaderProps> = React.memo(
  function HomeHeader({
    heading,
    description,
    button1,
    button2,
    imageLargeUrl,
    imageSmallUrl,
    ...rest
  }) {
    return (
      <Flex
        backgroundColor="lately.core"
        width="100%"
        flexDirection={{ base: 'column', md: 'row' }}
        borderRadius={{ base: 0, lg: '2px' }}
        {...rest}
      >
        <Stack
          marginTop={{ base: 8, md: 12, lg: 16, xl: 20 }}
          marginBottom={{ base: 4, md: 16, lg: 16 }}
          marginX={{ base: 4, md: 8, lg: 12 }}
          maxWidth={{ base: '100%', md: '50%', lg: '66%', xl: '50%' }}
          flexGrow={1}
        >
          {/* Text */}
          <Box>
            <Box marginLeft={2} marginBottom={{ base: 8, lg: 12 }}>
              <Heading
                as="h1"
                {...textStyles.h1campaign}
                letterSpacing={{ base: '0', lg: '-0.01em' }}
                display="inline"
                backgroundColor="white"
                boxShadow="-10px 0 0 #FFF" /* box shadow is emulating padding */
                padding={rem('5px')}
                paddingLeft={0}
              >
                <Box
                  as="span"
                  boxShadow="10px 0px 0px 5px #fff" /* box shadow is emulating padding */
                >
                  {heading}
                </Box>
              </Heading>
            </Box>
            <Flex flexWrap="wrap" marginBottom={rem('-10px')}>
              {button1 && (
                <Button
                  as="a"
                  href={button1.href}
                  marginRight={5}
                  marginBottom={rem('10px')}
                >
                  {button1.label}
                </Button>
              )}
              {button2 && (
                <Button
                  as="a"
                  href={button2.href}
                  variant="secondary"
                  backgroundColor="#e6e8ed"
                  marginBottom={rem('10px')}
                  borderColor="lately.core02"
                  borderWidth="2px"
                >
                  {button2.label}
                </Button>
              )}
            </Flex>
            {description && (
              <Flex alignItems="center" marginTop={{ base: 8, lg: 12 }}>
                <Icon name="locationCircle" size="50px" mr="10px" />
                <Text
                  {...textStyles.body}
                  fontWeight="bold"
                  fontSize={{ base: rem('18px'), lg: rem('20px') }}
                >
                  {description}
                </Text>
              </Flex>
            )}
          </Box>
        </Stack>

        {/* Image Desktop */}
        <Box
          display={{ base: 'none', xl: 'block' }}
          marginLeft={{ base: 'auto', md: '' }}
          maxWidth={{ base: '100%', md: '50%', lg: '33%', xl: '50%' }}
          height="100%"
          overflow="hidden"
        >
          <Image
            alt=""
            src={imageLargeUrl}
            ignoreFallback
            maxWidth="fit-content"
            width="auto"
            height="100%"
          />
        </Box>
        {/* Image Mobile */}
        <Box
          display={{ base: 'block', xl: 'none' }}
          margin={{ base: '', md: 'auto' }}
          marginLeft={{ base: 'auto', md: '' }}
          maxWidth={{ base: '100%', md: '50%', lg: '33%', xl: '50%' }}
          height="100%"
          overflow="hidden"
        >
          <Image
            alt=""
            src={imageSmallUrl}
            ignoreFallback
            maxWidth="fit-content"
            width="auto"
            height="100%"
          />
        </Box>
      </Flex>
    );
  }
);

export default HomeHeaderCampaign;
