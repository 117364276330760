import React from 'react';
import NextLink from 'next/link';
import { Box, Divider, Flex, Heading, Icon, Tag, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import getPlainText from '@/utils/getPlainText';
import * as textStyles from '@/theme/textStyles';
import Button from '@/components/ui/Button';
import { SavedContentState } from '@/utils/saved-content';

type LocationsProps = {
  locations: string[];
  maxLocations: number;
} & FlexProps;

const Locations: React.FC<LocationsProps> = ({
  locations,
  maxLocations,
  ...rest
}) => {
  if (!locations) return null;
  const locationsVisible = locations.slice(0, maxLocations).join(' | ');
  const remainder = locations.length - maxLocations;
  let locationsDescription = locationsVisible;
  if (remainder > 0) {
    locationsDescription = `${locationsVisible} | + ${remainder} more`;
  }
  return (
    <Flex {...rest}>
      <Text {...textStyles.body} color="lately.black40%">
        {locationsDescription}
      </Text>
    </Flex>
  );
};

export enum SearchResultTypes {
  Services = 'Services',
  Questions = 'Answers to common questions',
}

const mapTypeToButtonDescription = (resultsType: SearchResultTypes): string => {
  const descriptionMap = {
    [SearchResultTypes.Services]: 'View service',
    [SearchResultTypes.Questions]: 'Read more',
  };
  return descriptionMap[resultsType];
};

type SearchResultCardProps = {
  resultType: SearchResultTypes;
  isNewlyAdded: boolean;
  name: string;
  description: string;
  url: string;
  locations?: string[];
  email?: string;
  phone?: string;
  savedState: SavedContentState;
  onToggleSave: () => void;
} & FlexProps;

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  resultType,
  isNewlyAdded,
  name,
  description,
  url,
  locations,
  email,
  phone,
  savedState,
  onToggleSave,
  ...rest
}) => {
  // Strip Markdown from the description and truncate it before displaying it

  return (
    <Flex flexDirection="column" {...rest}>
      <Divider
        color="lately.core0210%"
        marginTop={0}
        marginBottom={{ base: 6, md: 10 }}
      />
      <Flex flexDirection="row" marginBottom={{ base: 6, md: 10 }}>
        <Flex
          flexDirection="column"
          marginRight={{ base: 0, md: 4, lg: 12 }}
          width="100%"
          flexGrow={1}
        >
          <Flex flexDirection="row" marginBottom={2} {...textStyles.body}>
            <Tag
              borderRadius="2px"
              color="lately.text"
              backgroundColor="lately.answers"
              marginRight={2}
            >
              {resultType}
            </Tag>
            {isNewlyAdded && (
              <Tag
                borderRadius="2px"
                color="lately.background"
                backgroundColor="lately.forms02"
              >
                Newly added
              </Tag>
            )}
          </Flex>
          <Heading {...textStyles.h3} marginBottom={2}>
            {name}
          </Heading>
          {getPlainText(description, 300)}
          {/* For large screens */}
          {!!locations && (
            <Locations
              locations={locations}
              maxLocations={4}
              display={{ base: 'none', lg: 'inherit' }}
            />
          )}
          {/* For small screens */}
          {!!locations && (
            <Locations
              locations={locations}
              maxLocations={3}
              display={{ base: 'inherit', lg: 'none' }}
            />
          )}
          <Flex
            marginTop={{ base: 4, lg: 6 }}
            marginBottom={-2}
            flexDirection="row"
            width="100%"
            flexWrap="wrap"
          >
            <NextLink href={url} passHref>
              <Button
                as="a"
                href={url}
                marginRight={{ base: 2, md: 3 }}
                marginBottom={2}
              >
                {mapTypeToButtonDescription(resultType)}
              </Button>
            </NextLink>
            <Button
              as="button"
              isLoading={savedState === SavedContentState.Pending}
              display={{ base: 'inherit', md: 'none' }}
              variant="secondary"
              marginRight={{ base: 2, md: 3 }}
              marginBottom={2}
              onClick={onToggleSave}
            >
              {savedState === SavedContentState.None
                ? 'Save for later'
                : 'Remove'}
            </Button>
            {email && (
              <Button
                as="a"
                href={`mailto:${email}`}
                variant="secondary"
                marginRight={{ base: 2, md: 3 }}
                marginBottom={2}
              >
                <Icon name="email" marginRight={2} />
                <Text>Email</Text>
              </Button>
            )}
            {phone && (
              <Button
                as="a"
                variant="secondary"
                marginBottom={2}
                href={`tel:${phone}`}
              >
                {phone}
              </Button>
            )}
          </Flex>
        </Flex>
        <Box flexShrink={0}>
          <Button
            as="button"
            isLoading={savedState === SavedContentState.Pending}
            display={{ base: 'none', md: 'flex' }}
            variant="secondary"
            marginRight={{ base: 2, md: 3 }}
            marginBottom={{ base: 2, md: 0 }}
            onClick={onToggleSave}
          >
            {savedState === SavedContentState.None
              ? 'Save for later'
              : 'Remove'}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchResultCard;
