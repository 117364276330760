import React from 'react';
import { rem } from 'polished';
import NextLink from 'next/link';
import { Box, Flex, Heading, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Button } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';

export type HubsListProps = {
  url: string;
  title: string;
  description: string;
  imageUrl: string;
}[];

type Props = {
  heading: string;
  hubs: HubsListProps;
} & FlexProps;

const HubListSection: React.FC<Props> = ({ heading, hubs, ...rest }) => {
  return (
    <FlexGridRow {...rest}>
      <FlexGridItem>
        <Stack spacing={{ base: 4, md: 6, lg: 10 }} width="100%">
          <Heading as="h2" {...textStyles.h2}>
            {heading}
          </Heading>
          <Flex
            direction="row"
            flexWrap="wrap"
            marginX={{ base: 0, md: -2, lg: -3 }}
            marginY={{ base: 0, md: -2, lg: -3 }}
          >
            {hubs.map((hub) => {
              return (
                <Flex
                  key={hub.title}
                  direction="column"
                  paddingX={{ base: 0, md: 2, lg: 3 }}
                  paddingY={{ base: 0, md: 2, lg: 3 }}
                  width={{ base: '100%', md: '50%', lg: '25%' }}
                >
                  <Flex direction="column" flexGrow={1} paddingY={6}>
                    <Box
                      backgroundImage={`url(${hub.imageUrl})`}
                      backgroundPosition="left center"
                      backgroundRepeat="no-repeat"
                      backgroundSize="contain"
                      height={rem('48px')}
                      marginBottom={rem('28px')}
                      width="100%"
                    />
                    <Stack
                      flexGrow={1}
                      paddingRight={{ base: 0, md: 10, lg: 0 }}
                      spacing={{ base: 2, lg: 4 }}
                    >
                      <Heading as="h3" {...textStyles.h4}>
                        <NextLink href={hub.url} passHref>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            _hover={{
                              color: 'lately.highlights03',
                              textDecoration: 'none',
                            }}
                          >
                            {hub.title}
                          </Link>
                        </NextLink>
                      </Heading>
                      <Text {...textStyles.body}>{hub.description}</Text>
                    </Stack>
                    <NextLink href={hub.url} passHref>
                      <Button
                        as={Link}
                        alignSelf="flex-start"
                        marginTop={{ base: 4, lg: 6 }}
                        width="auto"
                      >
                        Learn more
                        {/* Add additional context for screen readers and web crawlers */}
                        {/* e.g. "Learn more about services that help you Manage your money" */}
                        <span className="visually-hidden">
                          about services that help you {hub.title}
                        </span>
                      </Button>
                    </NextLink>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Stack>
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default HubListSection;
