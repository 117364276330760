import React from 'react';
import { rem } from 'polished';
import { Box, Flex, Heading, Icon, Image, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type HomeHeaderProps = {
  isCampaign: boolean;
  heading: string;
  description?: string;
  button1?: {
    label: string;
    href: string;
  };
  button2?: {
    label: string;
    href: string;
  };
  imageLargeUrl?: string;
  imageSmallUrl?: string;
} & FlexProps;

const HomeHeader: React.FC<HomeHeaderProps> = React.memo(function HomeHeader({
  heading,
  description,
  button1,
  button2,
  imageLargeUrl,
  imageSmallUrl,
  ...rest
}) {
  return (
    <Flex
      backgroundColor="lately.core"
      width="100%"
      flexDirection={{ base: 'column', md: 'row' }}
      borderRadius={{ base: 0, lg: '2px' }}
      {...rest}
    >
      <Stack
        marginTop={{ base: 8, md: 10 }}
        marginBottom={{ base: 4, md: rem('88px'), lg: rem('94px') }}
        marginX={{ base: 4, md: 8, lg: 12 }}
        maxWidth={{ base: '100%', md: '50%', lg: '66%', xl: '50%' }}
        flexGrow={1}
      >
        {/* Text */}
        <Box>
          {description && (
            <Flex alignItems="center" marginBottom={rem('30px')}>
              <Icon name="locationCircle" size="50px" mr="10px" />
              <Text
                {...textStyles.body}
                fontWeight="bold"
                fontSize={{ base: rem('18px'), lg: rem('20px') }}
              >
                {description}
              </Text>
            </Flex>
          )}
          <Heading
            as="h1"
            {...textStyles.h1}
            letterSpacing={{ base: '0', lg: '-0.01em' }}
            paddingRight={{ base: 0, md: 6, lg: 0 }}
            marginBottom={{ base: 8, lg: 12 }}
          >
            {heading}
          </Heading>
          <Flex flexWrap="wrap" marginBottom={rem('-10px')}>
            {button1 && (
              <Button
                as="a"
                href={button1.href}
                marginRight={5}
                marginBottom={rem('10px')}
              >
                {button1.label}
              </Button>
            )}
            {button2 && (
              <Button
                as="a"
                href={button2.href}
                variant="secondary"
                backgroundColor="#e6e8ed"
                marginBottom={rem('10px')}
              >
                {button2.label}
              </Button>
            )}
          </Flex>
        </Box>
      </Stack>

      {/* Image */}
      <Box
        display={{ base: 'none', lg: 'block' }}
        margin={{ base: '', md: 'auto' }}
        marginLeft={{ base: 'auto', md: '' }}
        marginRight={{ base: '', md: '' }}
        maxWidth={{ base: rem('375px'), md: '100%' }}
      >
        <Image alt="" src={imageLargeUrl} ignoreFallback float="right" />
      </Box>
      <Box
        display={{ base: 'block', lg: 'none' }}
        margin={{ base: '', md: 'auto' }}
        marginLeft={{ base: 'auto', md: '' }}
        marginRight={{ base: '', md: '' }}
        maxWidth={{ base: rem('375px'), md: '100%' }}
      >
        <Image alt="" src={imageSmallUrl} ignoreFallback float="right" />
      </Box>
    </Flex>
  );
});

export default HomeHeader;
