import { useEffect, useState } from 'react';

// Dynamically import the "url-polyfill" polyfill for browsers that need it
const useUrlPolyfill = () => {
  const [urlPolyfill, setUrlPolyfill] = useState<
    typeof import('url-polyfill') | null
  >(null);
  /**
   * If a browser doesn't yet support the URL() constructor, you can access a URL object using the Window interface's Window.URL property.
   * Be sure to check to see if any of your target browsers require this to be prefixed.
   * https://developer.mozilla.org/en-US/docs/Web/API/URL
   */
  useEffect(() => {
    // If it's supported, URL will be a function
    if (typeof URL === 'function') {
      return;
    }

    const importUrlPolyfill = async () => {
      setUrlPolyfill(await import('url-polyfill'));
    };
    importUrlPolyfill();
  }, []);

  return urlPolyfill;
};

export default useUrlPolyfill;
