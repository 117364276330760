import { useCallback, useState } from 'react';

const useShare = (textToShare: string) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const onShare = useCallback(() => {
    // If the browser supports the Web Share API then use it (mostly mobile browsers)
    if (navigator.share) {
      navigator.share({ text: textToShare }).catch((e) => {
        // Ignore errors because "AbortError" is thrown if user cancels share
        // eslint-disable-next-line no-console
        console.log('Error in navigator.share usage:', e);
      });
      return;
    }
    // Otherwise open the "copy to clipboard" modal
    setShowShareModal(true);
  }, [textToShare]);

  const onCloseShareModal = useCallback(() => {
    setShowShareModal(false);
  }, []);

  return {
    onShare,
    showShareModal,
    onCloseShareModal,
  };
};

export default useShare;
