import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Text,
} from '@chakra-ui/core';
import type { FlexProps, AccordionItemProps } from '@chakra-ui/core';
import orderBy from 'lodash.orderby';

import { getRefinementSortOrder } from '@/utils/search';
import * as textStyles from '@/theme/textStyles';
import RefinementList from './RefinementList';

const transformItems = (items: unknown[]) => {
  return orderBy(items, ['label', getRefinementSortOrder], ['asc', 'desc']);
};

type FilterGroupProps = {
  groupName: string;
  attributeName: string;
} & Pick<AccordionItemProps, 'defaultIsOpen'>;

const FilterGroup: React.FC<FilterGroupProps> = React.memo(
  function FilterGroup({ groupName, attributeName, ...rest }) {
    return (
      <AccordionItem marginBottom={1} borderStyle="none" {...rest}>
        {({ isExpanded }) => (
          <>
            <AccordionHeader
              backgroundColor="lately.core0210%"
              borderRadius="2px"
              paddingX={2}
              color="lately.highlights03"
              _hover={{ backgroundColor: 'lately.core0205%' }}
            >
              <Text {...textStyles.h5} flex="1" textAlign="left">
                {groupName}
              </Text>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel
              paddingX={2}
              paddingY={0}
              maxHeight="50vh"
              overflowY="auto"
            >
              <RefinementList
                isOpen={isExpanded ?? false}
                attribute={attributeName}
                limit={1000}
                transformItems={transformItems}
              />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);

type Props = FlexProps;

const FilterResults: React.FC<Props> = React.memo(function FilterResults({
  children,
  ...rest
}) {
  return (
    <Flex {...rest} width="100%" flexDirection="column">
      <Box paddingY={2} marginBottom={4} borderRadius="2px">
        <Text {...textStyles.h4}>Filter results:</Text>
      </Box>
      <Accordion allowMultiple defaultIndex={[0]}>
        <FilterGroup groupName="Type" attributeName="type" defaultIsOpen />
        <FilterGroup groupName="Local gov. Area" attributeName="LGA.name" />
        <FilterGroup groupName="Suburb" attributeName="Suburb.name" />
        <FilterGroup groupName="Category" attributeName="service_types.name" />
        <FilterGroup groupName="Language" attributeName="languages.name" />
        <FilterGroup
          groupName="Accessibility"
          attributeName="Venue.accessibilities.name"
        />
        <FilterGroup groupName="Cost" attributeName="cost_label" />
        <FilterGroup groupName="Booking needed" attributeName="booking_label" />
        <FilterGroup
          groupName="Referral needed"
          attributeName="referral_label"
        />
        <FilterGroup
          groupName="How service is provided"
          attributeName="service_delivery_formats.name"
        />
      </Accordion>
    </Flex>
  );
});

export default FilterResults;
