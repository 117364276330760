import React, { useState } from 'react';
import { rem } from 'polished';
import NextLink from 'next/link';
import { Flex, Heading, Link, Text } from '@chakra-ui/core';
import type { FlexProps, HeadingProps } from '@chakra-ui/core';

import { generateRefinementAsUrl } from '@/utils/search';
import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type PopularCategoriesProps = {
  heading: string;
  description?: string;
  headingProps?: HeadingProps;
  numberOfCategoriesToShow: number;
  isModal?: boolean;
  popularCategories: {
    id: string;
    name: string;
    isCommunityService?: boolean;
  }[];
} & FlexProps;

const PopularCategories: React.FC<PopularCategoriesProps> = ({
  heading,
  description = '',
  isModal = false,
  headingProps = {},
  numberOfCategoriesToShow,
  popularCategories,
  ...rest
}) => {
  // Only show the first 9 popular categories until the user expands the list
  const [expandedCategories, setExpandedCategories] = useState(false);
  const categoriesToShow = expandedCategories
    ? popularCategories
    : popularCategories.slice(0, numberOfCategoriesToShow);

  return (
    <Flex direction="column" {...rest}>
      <Heading
        as="h2"
        {...textStyles.h2}
        marginBottom={isModal ? { base: 4, lg: 6 } : { base: 2, lg: 4 }}
        width="100%"
        {...headingProps}
      >
        {heading}
      </Heading>
      {!isModal && (
        <Text
          {...textStyles.body}
          marginBottom={{ base: 4, lg: 6 }}
          maxWidth={{ base: '100%', lg: '65%' }}
        >
          {description}
        </Text>
      )}
      <Flex
        direction="row"
        flexShrink={0}
        flexWrap="wrap"
        marginX={{ base: -2, lg: -3 }}
        marginY={{ base: -1, lg: -2 }}
      >
        {categoriesToShow.map((category) => {
          return (
            <Flex
              key={category.id}
              paddingX={{ base: 2, lg: 3 }}
              paddingY={{ base: 1, lg: 2 }}
              width={{ base: '100%', md: '50%', lg: '33.33%' }}
            >
              <NextLink
                href={generateRefinementAsUrl(
                  'service_types.name',
                  category.name
                )}
                passHref
              >
                <Button
                  as={Link}
                  variant="feature"
                  justifyContent="flex-start"
                  textAlign="left"
                  width="100%"
                  height="100%"
                  paddingX={{ base: 4, lg: 6 }}
                  paddingY={{ base: rem('10px'), lg: rem('18px') }}
                >
                  <Text flexGrow={1}>{category.name}</Text>
                </Button>
              </NextLink>
            </Flex>
          );
        })}
      </Flex>
      <Flex flexShrink={0} width="100%" justifyContent="center">
        {popularCategories.length > numberOfCategoriesToShow && (
          <Button
            variant="secondary"
            onClick={() => setExpandedCategories(!expandedCategories)}
            marginTop={{ base: 2, lg: 4 }}
          >
            {expandedCategories ? 'See less' : 'See more'}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default PopularCategories;
