import React from 'react';
import type { FlexProps } from '@chakra-ui/core';

import { Image, TextBlock } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import type { ImageProps, TextProps } from '@/components/ui';

export type ImageTextSectionProps = {
  data: {
    image?: ImageProps;
    textBlock?: TextProps;
  };
} & FlexProps;

const ImageTextSection: React.FC<ImageTextSectionProps> = ({
  data: { image, textBlock },
  ...rest
}) => {
  return (
    <FlexGridRow {...rest}>
      {image && (
        <FlexGridItem
          colWidth={{ base: 4, md: 2, lg: 7 }}
          pr={{ base: 0, md: 8, lg: 16 }}
          flexDirection="column"
        >
          <Image {...image} />
        </FlexGridItem>
      )}
      {textBlock && (
        <FlexGridItem
          colWidth={{ base: 4, md: 2, lg: 4 }}
          colOffset={{ base: 0, lg: 1 }}
          flexDirection="column"
          marginTop={{ base: 6, md: 0 }}
          pr={{ base: 0, md: 8, lg: 16 }}
        >
          <TextBlock {...textBlock} />
        </FlexGridItem>
      )}
    </FlexGridRow>
  );
};

export default ImageTextSection;
