/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { forwardRef } from 'react';
import { Box, Icon, PseudoBox, Spinner } from '@chakra-ui/core';
import type { IconProps, PseudoBoxProps } from '@chakra-ui/core';

import useButtonStyle from './styles';

/**
 * Code copied from Chakra UI v0 and modified to adjust theming
 * https://github.com/chakra-ui/chakra-ui/blob/v0/packages/chakra-ui/src/Button/index.js
 */

type ButtonIconProps = {
  icon: string | React.ComponentType;
} & IconProps;

const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, ...props }) => {
  if (typeof icon === 'string') {
    return (
      <Icon
        focusable={false}
        aria-hidden="true"
        name={icon}
        color="currentColor"
        {...props}
      />
    );
  }
  return (
    <Box
      as={icon}
      data-custom-icon
      aria-hidden="true"
      color="currentColor"
      {...props}
    />
  );
};

export type ButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'textLink'
    | 'glossaryItem'
    | 'feature'
    | 'unstyled';
  type?: 'button' | 'reset' | 'submit';
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  loadingText?: React.ReactNode;
  isFullWidth?: boolean;
  leftIcon?: string | React.ComponentType;
  rightIcon?: string | React.ComponentType;
  iconSpacing?: PseudoBoxProps['margin'];
  href?: string;
  target?: string;
  rel?: string;
} & Omit<PseudoBoxProps, 'size'>;

const Button: React.FC<ButtonProps> = forwardRef(
  (
    {
      as: Comp = 'button',
      variant = 'primary',
      type = 'button',
      isActive,
      isDisabled,
      isLoading,
      loadingText,
      isFullWidth,
      leftIcon,
      rightIcon,
      iconSpacing = 2,
      href,
      children,
      ...rest
    },
    ref
  ) => {
    const buttonStyleProps = useButtonStyle(variant);
    const _isDisabled = isDisabled || isLoading;

    return (
      <PseudoBox
        disabled={_isDisabled}
        aria-disabled={_isDisabled}
        ref={ref}
        href={href}
        as={Comp}
        type={type}
        width={isFullWidth ? 'full' : undefined}
        data-active={isActive ? 'true' : undefined}
        {...buttonStyleProps}
        {...rest}
      >
        {leftIcon && !isLoading && (
          <ButtonIcon mr={iconSpacing} icon={leftIcon} />
        )}
        {isLoading && (
          <Spinner
            position={loadingText ? 'relative' : 'absolute'}
            mr={loadingText ? iconSpacing : 0}
            color="currentColor"
          />
        )}
        {isLoading
          ? loadingText || (
              <Box as="span" opacity={0}>
                {children}
              </Box>
            )
          : children}
        {rightIcon && !isLoading && (
          <ButtonIcon mr={-1} ml={iconSpacing} icon={rightIcon} />
        )}
      </PseudoBox>
    );
  }
);

export default Button;
