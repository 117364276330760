import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import type { InstantSearchProps } from 'react-instantsearch-dom';

const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID || '',
  process.env.ALGOLIA_SEARCH_API_KEY || ''
);
const indexName = `${process.env.ALGOLIA_INDEX_PREFIX}_service_and_category`;

type ServiceSearchProps = Omit<
  InstantSearchProps,
  'indexName' | 'searchClient'
>;

const ServiceAndCategorySearch: React.FC<ServiceSearchProps> = ({
  searchState,
  onSearchStateChange,
  children,
  ...rest
}) => (
  <InstantSearch
    onSearchStateChange={onSearchStateChange}
    indexName={indexName}
    searchClient={searchClient}
    searchState={searchState}
    {...rest}
  >
    {children}
  </InstantSearch>
);

export default ServiceAndCategorySearch;
