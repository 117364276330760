import React from 'react';
import { Box, Stack } from '@chakra-ui/core';
import type { StackProps } from '@chakra-ui/core';

import {
  FaqsSection,
  TwoColTextSection,
  TextSection,
  ImageTextSection,
  ImageSection,
  VideoSection,
} from '@/components/sections';
import { SectionOption, SectionsProps, assertNever } from '@/utils/types';

const getSection = (section: SectionOption) => {
  switch (section.kind) {
    case 'imageText': {
      return <ImageTextSection {...section} />;
    }
    case 'image': {
      return <ImageSection {...section} />;
    }
    case 'faqs': {
      return <FaqsSection {...section} />;
    }
    case 'twoColText': {
      return <TwoColTextSection {...section} />;
    }
    case 'textBlock': {
      return <TextSection {...section} />;
    }
    case 'video': {
      return <VideoSection {...section} />;
    }
    default:
      return assertNever(section);
  }
};

type DynamicSectionProps = {
  sections: SectionsProps;
} & StackProps;

const DynamicSection: React.FC<DynamicSectionProps> = ({
  sections,
  ...rest
}) => {
  // Create array of section components
  const dynamicSections = sections.map((section) => {
    const { id } = section;
    return (
      // Box is needed as a wrapper so the Stack parent spacing is applied
      <Box key={id} width="100%">
        {getSection(section)}
      </Box>
    );
  });
  return <Stack {...rest}>{dynamicSections}</Stack>;
};

export default DynamicSection;
