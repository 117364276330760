import type { BoxProps } from '@chakra-ui/core';
import type {
  FaqsSectionProps,
  TwoColTextSectionProps,
  TextSectionProps,
  ImageTextSectionProps,
  ImageSectionProps,
  VideoSectionProps,
} from '@/components/sections';

export type {
  CategoriesProps,
  HubsListProps,
  FaqsSectionProps,
  TwoColTextSectionProps,
  TextSectionProps,
  ImageTextSectionProps,
  ImageSectionProps,
  RelatedServicesProps,
  VideoSectionProps,
} from '@/components/sections';

export type { PopularCategoriesProps } from '@/components/ui';

export type Dictionary<T> = { [key: string]: T | undefined };

export const assertNever = (x: never): never => {
  throw new Error(`Unexpected object: ${x}`);
};

export type PageProps = {
  title?: string;
  description?: string;
  created_at?: string;
};

export type ServiceType = {
  id: string;
  title: string;
  description: string;
  created_at: string;
};

export type AccordionItemDataType = { name: string; url: string };
export type NavItemType = {
  name: string;
  links?: Array<AccordionItemDataType>;
  url?: string;
} & BoxProps;

// a tagged union type so that the front-end component can tell whether a section is an FaqsSection, a TextSection, etc.
export type SectionOption =
  | ({ kind: 'imageText'; id: string } & ImageTextSectionProps)
  | ({ kind: 'image'; id: string } & ImageSectionProps)
  | ({ kind: 'faqs'; id: string } & FaqsSectionProps)
  | ({ kind: 'twoColText'; id: string } & TwoColTextSectionProps)
  | ({ kind: 'textBlock'; id: string } & TextSectionProps)
  | ({ kind: 'video'; id: string } & VideoSectionProps);

export type SectionsProps = SectionOption[];
