/** @jsx jsx */
import React, { useRef, useState } from 'react';
import {
  Flex,
  Heading,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/core';
import { rem } from 'polished';
import { css, jsx } from '@emotion/core';

import { spacing } from '@/theme/chakra';
import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import useViewportHeight from '@/utils/useViewportHeight';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  textToShare: string;
};

const defaultTitle = 'Share this page';
const defaultDescription =
  "To share this page, copy and paste the link below.\nSelect the 'Copy to clipboard' option, then paste the link in an email, a message or social media post.";

const ShareModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title = defaultTitle,
  description = defaultDescription,
  textToShare,
}) => {
  // When the user presses "Copy", select the text and copy it
  const [copied, setCopied] = useState(false);
  const textEl = useRef<HTMLTextAreaElement | null>(null);
  const onCopy = () => {
    if (textEl.current === null) return;
    textEl.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  // Reset copied state on close
  const handleClose = () => {
    setCopied(false);
    onClose();
  };

  // Use the viewport height so the buttons aren't covered by mobile browser UI
  const viewportHeight = useViewportHeight({ limit: 'debounce', wait: 50 });

  // Use CSS calc with the modal margins to calculate the height/width
  // 100% height/width with margins would cause the modal to overflow the screen
  const margin = { base: 0, md: 8, lg: 24 };
  const size = {
    md: `calc(100% - ${spacing['8']} * 2)`,
    lg: `calc(100% - ${spacing['24']} * 2)`,
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backgroundColor="lately.black40%" />
      <ModalContent
        backgroundColor="#efeff4"
        borderRadius="2px"
        height={{ base: viewportHeight, ...size }}
        marginX="auto"
        marginY={margin}
        minHeight={{ base: '70vh', md: 0 }}
        maxWidth={rem('1036px')}
        overflowX="hidden"
        overflowY="auto"
        paddingX={{ base: 4, md: 10, lg: 16 }}
        paddingY={{ base: 6, md: 10, lg: 16 }}
        position="relative"
        width={{ base: '100%', ...size }}
      >
        <Button
          aria-label="Close"
          onClick={handleClose}
          borderRadius="2px"
          height={rem('48px')}
          paddingX={0}
          paddingY={0}
          position="absolute"
          right={{ base: 4, md: 2, lg: 4 }}
          top={{ base: 4, md: 2, lg: 4 }}
          width={rem('48px')}
        >
          <Icon name="close" color="white" size={rem('14px')} />
        </Button>
        <Flex direction="column" flexGrow={1}>
          <Heading
            as="h1"
            color="lately.core02"
            marginTop={{ base: 4, md: 0 }}
            maxWidth={rem('597px')}
            paddingRight={20}
            {...textStyles.h2}
          >
            {title}
          </Heading>
          <Stack
            marginTop={{ base: 4, lg: 6 }}
            maxWidth={rem('597px')}
            spacing={3}
          >
            {description.split('\n').map((paragraph, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={i} {...textStyles.body}>
                {paragraph}
              </Text>
            ))}
          </Stack>
          <Textarea
            ref={textEl}
            resize="none"
            value={textToShare}
            onChange={() => {}}
            flexGrow={1}
            marginTop={{ base: 6, md: 8, lg: 12 }}
            minHeight={rem('80px')}
            padding={{ base: 4, lg: 6 }}
          />
          <Flex
            flexWrap="wrap"
            justifyContent="flex-end"
            paddingTop={{ base: 10, lg: 16 }}
            css={css`
              margin: -${rem('20px')};
              & > * {
                margin: ${rem('20px')};
              }
            `}
          >
            <Button
              variant="textLink"
              color="lately.core02"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              leftIcon={copied ? 'check' : 'copy'}
              minWidth={rem('230px')}
              onClick={onCopy}
            >
              {copied ? 'Copied' : 'Copy to clipboard'}
            </Button>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
