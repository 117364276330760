import React from 'react';
import { Image as ChakraImage, AspectRatioBox } from '@chakra-ui/core';
import type { AspectRatioBoxProps } from '@chakra-ui/core';

export type ImageProps = {
  url: string;
  alt?: string;
  width?: number;
  height?: number;
} & AspectRatioBoxProps;

const Image: React.FC<ImageProps> = ({
  url,
  alt = '',
  width = 7,
  height = 4,
  ...rest
}) => {
  return (
    <AspectRatioBox maxW="100%" ratio={width / height} {...rest}>
      <ChakraImage src={url} alt={alt ?? ''} ignoreFallback />
    </AspectRatioBox>
  );
};

export default Image;
