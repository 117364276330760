import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { rem } from 'polished';
import throttle from 'lodash.throttle';
import { connectSearchBox, Configure } from 'react-instantsearch-dom';
import { Box, Flex, Icon, useDisclosure } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import type { SearchBoxProvided } from 'react-instantsearch-core';

import Button from '@/components/ui/Button';
import SearchResultHits from './SearchResultHits';
import ConnectedSearchBox from './ConnectedSearchBox';

type QueryListenerProps = {
  children: (query: string) => JSX.Element;
} & SearchBoxProvided;

const QueryListener = connectSearchBox<QueryListenerProps>(
  ({ currentRefinement, children }) => {
    return children(currentRefinement);
  }
);

type SearchInputProps = {
  inputPlaceholder: string;
  isInModal?: boolean;
  onSubmit?: React.FormEventHandler;
  onSelectResult: (query: string) => void;
} & FlexProps;

const SearchInput: React.FC<SearchInputProps> = React.memo(
  function SearchInput({
    inputPlaceholder,
    isInModal = false,
    onSubmit,
    onSelectResult,
    ...rest
  }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Manage the height of the dropdown box
    const [dropdownHeight, setDropdownHeight] = useState(0);
    const dropdownMaxHeight = 480;
    // TODO: make these numbers less magical
    const dropdownDistanceFromPageBottom = isInModal ? 136 : 96;

    const containerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleCloseDropdown = useCallback((forceClose?: boolean): void => {
      // delay the check for focus so the dom has time to update
      setTimeout(() => {
        if (!dropdownRef.current) return;
        const isFocused = dropdownRef.current.contains(document.activeElement);
        if (!isFocused || forceClose) {
          onClose();
        }
      }, 200);
    }, []);
    // Used to force the dropdown closed when an item is selected or form submitted
    const wrapForDropdownClose = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (fn: any) => (args: any) => {
        if (fn) fn(args);
        handleCloseDropdown(true);
      },
      [handleCloseDropdown]
    );
    const wrappedOnSubmit = useMemo(() => {
      return wrapForDropdownClose(onSubmit);
    }, [wrapForDropdownClose, onSubmit]);
    const wrappedOnSelectResult = useMemo(() => {
      return wrapForDropdownClose(onSelectResult);
    }, [wrapForDropdownClose, onSelectResult]);

    // update the height of the dropdown box
    useEffect(() => {
      if (!isOpen) {
        setDropdownHeight(0);
        return undefined;
      }

      const updateHeight = () => {
        if (!containerRef.current) return;
        const windowHeight = window.innerHeight;
        const containerRect = containerRef.current.getBoundingClientRect();
        const distanceFromTop = containerRect.top + containerRect.height;
        const boxHeight =
          windowHeight - distanceFromTop - dropdownDistanceFromPageBottom;

        setDropdownHeight(Math.min(boxHeight, dropdownMaxHeight));
      };
      updateHeight();

      const throttledUpdate = throttle(updateHeight, 100);
      window.addEventListener('scroll', throttledUpdate);
      window.addEventListener('resize', throttledUpdate);
      return () => {
        window.removeEventListener('scroll', throttledUpdate);
        window.removeEventListener('resize', throttledUpdate);
      };
    }, [isOpen]);

    return (
      <Flex {...rest} position="relative" as="form" onSubmit={wrappedOnSubmit}>
        <Flex ref={containerRef} flexGrow={1} position="relative">
          <ConnectedSearchBox
            inputPlaceholder={inputPlaceholder}
            handleOpenDropdown={onOpen}
            handleCloseDropdown={handleCloseDropdown}
          />
          <QueryListener>
            {(query) => {
              // Only show the suggestions if the query is at least 2 chars long
              const isVisible = isOpen && query.length > 1;
              return (
                <Box
                  ref={dropdownRef}
                  border="none"
                  borderBottomRightRadius="2px"
                  borderBottomLeftRadius="2px"
                  display={isVisible ? 'block' : 'none'}
                  position="absolute"
                  top={{ base: '48px', lg: '60px' }}
                  left="0px"
                  width="100%"
                  zIndex={10}
                  backgroundColor="lately.background"
                  borderTopColor="lately.black20"
                  borderTopWidth="2px"
                  borderTop="solid"
                  boxShadow="0px 20px 20px 5px rgba(36,36,36,0.06)"
                  height={`${dropdownHeight}px`}
                  minHeight={20}
                  overflowY="auto"
                >
                  <Configure hitsPerPage={isVisible ? 10 : 0} />
                  <SearchResultHits
                    onSelect={wrappedOnSelectResult}
                    onBlur={handleCloseDropdown}
                  />
                </Box>
              );
            }}
          </QueryListener>
        </Flex>
        <Button
          variant="unstyled"
          aria-label="Submit"
          type="submit"
          backgroundColor="lately.core02"
          border={0}
          borderRadius="2px"
          color="lately.background"
          display={{ base: 'none', md: 'flex' }}
          flexShrink={0}
          marginLeft={{ base: 2, lg: 4 }}
          paddingX={{ base: 3, lg: rem('14px') }}
        >
          <Icon
            fontSize={{ base: rem('24px'), lg: rem('32px') }}
            name="search"
          />
        </Button>
      </Flex>
    );
  }
);

export default SearchInput;
