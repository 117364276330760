import React from 'react';
import type { ReactHTML } from 'react';
import Markdown from 'markdown-to-jsx';
import { Flex, Heading } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import * as textStyles from '@/theme/textStyles';
import { MarkdownComponent } from '@/components/ui';

type DisclaimerProps = {
  title: string;
  description: string;
} & FlexProps;

const Disclaimer: React.FC<DisclaimerProps> = ({
  title,
  description,
  ...rest
}) => (
  <Flex
    borderRadius="2px"
    direction="column"
    overflow="hidden"
    width="100%"
    {...rest}
  >
    <Flex backgroundColor="lately.black10%" paddingX={6} paddingY={4}>
      <Heading as="h2" {...textStyles.h5}>
        {title}
      </Heading>
    </Flex>
    <Flex backgroundColor="lately.black4%" paddingX={6} paddingY={4}>
      <Markdown
        options={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          createElement(type, props: any, children) {
            const { key } = props;
            return (
              <MarkdownComponent
                type={type as keyof ReactHTML}
                key={key}
                props={props}
              >
                {children}
              </MarkdownComponent>
            );
          },
        }}
      >
        {description}
      </Markdown>
    </Flex>
  </Flex>
);

export default Disclaimer;
