import { rem } from 'polished';

import * as textStyles from '@/theme/textStyles';

/**
 * Code copied from Chakra UI v0 and modified to adjust theming
 * https://github.com/chakra-ui/chakra-ui/blob/v0/packages/chakra-ui/src/Button/styles.js
 */

const baseProps = {
  ...textStyles.h5,
  alignItems: 'center',
  appearance: 'none',
  borderRadius: '2px',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  outline: 'none',
  paddingX: 6,
  paddingY: 3,
  position: 'relative',
  transition: 'all 250ms',
  userSelect: 'none',
  verticalAlign: 'middle',
  whiteSpace: 'normal',
  textAlign: 'center',
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    backgroundColor: 'lately.black10%',
    color: 'lately.black40%',
    cursor: 'not-allowed',
  },
};

const primaryVariantProps = () => {
  const base = {
    backgroundColor: 'lately.core02',
    color: 'lately.background',
  };
  const hover = {
    backgroundColor: 'lately.highlights03',
    textDecoration: 'none',
  };
  return {
    ...base,
    _hover: hover,
    _active: hover,
  };
};

const secondaryVariantProps = () => {
  const base = {
    backgroundColor: 'lately.core0210%',
    color: 'lately.highlights03',
  };
  const hover = {
    backgroundColor: 'lately.core02',
    color: 'lately.background',
    textDecoration: 'none',
  };
  return {
    ...base,
    _hover: hover,
    _active: hover,
  };
};

const textLinkVariantProps = () => {
  const base = {
    color: 'lately.text',
    paddingX: 0,
    textDecoration: 'underline',
  };
  const hover = {
    textDecoration: 'none',
  };
  const disabled = {
    color: '#85868D',
    cursor: 'not-allowed',
  };
  return {
    ...base,
    _hover: hover,
    _active: hover,
    _disabled: disabled,
  };
};

const glossaryItemVariantProps = () => {
  const base = {
    ...textStyles.body,
    color: 'lately.core02',
    paddingX: 2,
    paddingY: 2,
    textDecoration: 'underline',
  };
  const hover = {
    textDecoration: 'none',
  };
  const disabled = {
    cursor: 'not-allowed',
    opacity: 0.3,
  };
  return {
    ...base,
    _hover: hover,
    _active: hover,
    _disabled: disabled,
  };
};

const featureVariantProps = () => {
  const base = {
    backgroundColor: 'lately.core',
    color: 'lately.highlights03',
    paddingX: { base: 3, lg: rem('14px') },
    paddingY: 3,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  };
  const hover = {
    backgroundColor: 'lately.highlights04',
    textDecoration: 'none',
  };
  return {
    ...base,
    _hover: hover,
    _active: hover,
  };
};

const unstyledVariantProps = () => {
  return {
    background: 'none',
    border: 0,
    color: 'inherit',
    display: 'inline',
    font: 'inherit',
    lineHeight: 'inherit',
    margin: 0,
    paddingX: 0,
    paddingY: 0,
    textAlign: 'inherit',
    userSelect: 'inherit',
    _hover: {},
    _active: {},
    _disabled: {},
  };
};

const variantProps = (variant: string) => {
  switch (variant) {
    case 'primary':
      return primaryVariantProps();
    case 'secondary':
      return secondaryVariantProps();
    case 'textLink':
      return textLinkVariantProps();
    case 'glossaryItem':
      return glossaryItemVariantProps();
    case 'feature':
      return featureVariantProps();
    case 'unstyled':
      return unstyledVariantProps();
    default:
      return {};
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useButtonStyle = (variant: string): any => {
  return { ...baseProps, ...variantProps(variant) };
};

export default useButtonStyle;
