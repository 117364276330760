import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Box, Collapse, Flex, PseudoBox, useDisclosure } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import sessionStorageWrapper from 'localforage-sessionstoragewrapper';
import localforage from 'localforage';

import gridConfig from '@/theme/grid';
import { breakpoints } from '@/theme/chakra';
import useStickyfill from '@/utils/useStickyfill';
import { SearchModal, SiteNotice } from '@/components/ui';
import { useSearchModal } from '@/components/providers';
import { FlexGrid, FlexGridItem, FlexGridRow } from '@/components/layout';

import type { PopularCategoriesProps } from '@/utils/types';
import FeedbackButton from '@/components/ui/FeedbackButton/FeedbackButton';
import type { FooterProps } from '../Footer';
import type { PrimaryNavigationProps } from '../PrimaryNavigation';
import Footer from '../Footer';
import PrimaryNavigation from '../PrimaryNavigation';

export type PageProps = {
  notice?: {
    text: string;
    link?: {
      text: string;
      url: string;
    };
  };
  footerProps: FooterProps;
  primaryNavigationProps: PrimaryNavigationProps;
  popularCategoriesProps: Omit<
    PopularCategoriesProps,
    'numberOfCategoriesToShow'
  >;
  blogPostRecentProps?: {
    heading?: string;
    button: {
      label?: string;
      href?: string;
    };
  };
  feedbackForm?: {
    URL: string | null;
  };
} & FlexProps;

const Page: React.FC<PageProps> = React.memo(function Page({
  notice,
  footerProps,
  primaryNavigationProps,
  popularCategoriesProps,
  feedbackForm,
  children,
  ...rest
}) {
  const siteNotice = useDisclosure(true);
  // this is needed so that the collapse animation can happen when the notice is closed
  // and to prevent the flash of the notice closingon every page load
  const [showNotice, setShowNotice] = useState(true);
  const hideNoticeKey = '_hideNoticeKey';

  // Determine on page load whether the site notice should be visible
  useEffect(() => {
    if (!window || notice === undefined) {
      return undefined;
    }

    // Using sessionStorageWrapper as a custom driver
    localforage.defineDriver(sessionStorageWrapper).then(() => {
      // Forcing use of sessionStorage driver
      // Example from: https://github.com/localForage/localForage-sessionStorageWrapper
      // eslint-disable-next-line no-underscore-dangle
      localforage.setDriver(sessionStorageWrapper._driver).then(() => {
        // Check for locally stored data on whether the site notice should be visible
        localforage.getItem(hideNoticeKey).then((hideNotice) => {
          if (hideNotice) {
            // If notice should be closed then hide it and close it
            setShowNotice(false);
            siteNotice.onClose();
          }
        });
      });
    });
    return undefined;
  }, []);

  const handleCloseNotice = useCallback(() => {
    siteNotice.onClose();
    setTimeout(() => {
      setShowNotice(false);
      localforage.setItem(hideNoticeKey, true);
    }, 300);
  }, []);

  // Load polyfill for `position: sticky` on browsers that need it
  const stickyfill = useStickyfill();
  const stickyEl = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (stickyfill === null || stickyEl.current === null) {
      return undefined;
    }

    const { current } = stickyEl;
    const isLgBreakpoint = () =>
      window.matchMedia(`(min-width: ${breakpoints.lg})`).matches;
    const onResize = () => {
      if (isLgBreakpoint()) {
        stickyfill.addOne(current);
      } else {
        stickyfill.removeOne(current);
      }
    };
    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      stickyfill.removeOne(current);
    };
  }, [stickyfill, stickyEl.current]);

  return (
    <Flex
      alignItems="center"
      backgroundColor="lately.background"
      direction="column"
      minHeight="100%"
      {...rest}
    >
      <Box
        ref={stickyEl}
        position={{ base: 'relative', lg: 'sticky' }}
        zIndex={50}
        top={0}
        width="100%"
      >
        <PrimaryNavigation {...primaryNavigationProps} />
      </Box>
      <PseudoBox
        as="main"
        id="main"
        display="flex"
        tabIndex={-1}
        flexGrow={1}
        flexShrink={0}
        width="100%"
        _focus={{ outline: 'none' }}
      >
        <Flex
          alignItems="center"
          direction="column"
          marginX="auto"
          maxWidth="contentMax"
          width="100%"
        >
          <FlexGrid flexGrow={1}>
            {notice !== undefined && (
              <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
                <FlexGridItem direction="column" alignItems="stretch">
                  {showNotice && (
                    <Collapse isOpen={siteNotice.isOpen}>
                      <SiteNotice
                        link={notice.link}
                        marginBottom={1}
                        onClose={handleCloseNotice}
                      >
                        {notice.text}
                      </SiteNotice>
                    </Collapse>
                  )}
                </FlexGridItem>
              </FlexGridRow>
            )}
            {children}
            <FlexGridRow
              ignoreMargins={{ base: 1, lg: 1 / 2 }}
              marginTop="auto"
              paddingBottom={{
                base: 0,
                lg: `${parseFloat(gridConfig.margin.lg) / 2}rem`,
              }}
            >
              <FlexGridItem>
                <Footer {...footerProps} />
              </FlexGridItem>
            </FlexGridRow>
          </FlexGrid>
        </Flex>
      </PseudoBox>
      {feedbackForm && feedbackForm.URL ? (
        <FeedbackButton url={feedbackForm.URL} />
      ) : null}
    </Flex>
  );
});

// Extracts only the state we need out of the context to prevent unnecessary re-renders of the main component
const ConnectedPage: React.FC<PageProps> = React.memo(function ConnectedPage(
  props
) {
  const searchModal = useSearchModal();
  return (
    <>
      <Page {...props} />
      <SearchModal
        isOpen={searchModal.isOpen}
        onClose={searchModal.onClose}
        updateExistingSearch={searchModal.updateExistingSearch}
        popularCategories={props.popularCategoriesProps}
        mobileDefaultPage={searchModal.mobileDefaultPage}
      />
    </>
  );
});

export default ConnectedPage;
