import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import type { CurrentRefinementsProvided } from 'react-instantsearch-core';

import Button from '@/components/ui/Button';

const CustomClearFiltersButton: React.FC<CurrentRefinementsProvided> = ({
  items,
  refine,
  children,
  ...rest
}) => {
  return (
    <Button
      variant="secondary"
      onClick={() => {
        refine(items);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

const ClearFiltersButton = connectCurrentRefinements(CustomClearFiltersButton);

export default ClearFiltersButton;
