/* eslint-disable no-underscore-dangle */
/** @jsx jsx */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { rem } from 'polished';
import { css, jsx } from '@emotion/core';
import { Configure, connectRefinementList } from 'react-instantsearch-dom';
import {
  Collapse,
  Flex,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/core';
import {
  CustomMarker,
  GeoSearch,
  GoogleMapsLoader,
  withGoogleMaps,
} from 'react-instantsearch-dom-maps';

import { breakpoints, spacing } from '@/theme/chakra';
import { isMobile, isTablet } from '@/utils/media';
import { Button, SearchResultsMapCard, ServiceSearch } from '@/components/ui';
import type { SearchState } from '@/utils/search';
import type { ServiceHit } from '@/components/ui/SearchResultCard/SearchResultCardHits';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  pageSearchState: SearchState;
};

type MapProps = {
  onSelectService: (service: ServiceHit, marker: google.maps.Marker) => void;
  searchState: SearchState;
  refinementList: SearchState['refinementList'];
};

/// Virtually render any selected filters so that they are applied to the map
const VirtualRefinementList = connectRefinementList(() => null);

const VirtualFilters = ({
  refinementList,
}: {
  refinementList: SearchState['refinementList'];
}) => (
  <>
    {refinementList
      ? Object.keys(refinementList).map((k) => (
          <VirtualRefinementList
            key={k}
            attribute={k}
            defaultRefinement={
              Array.isArray(refinementList[k as keyof typeof refinementList])
                ? (refinementList[k as keyof typeof refinementList] as string[])
                : [refinementList[k as keyof typeof refinementList] as string]
            }
          />
        ))
      : null}
  </>
);

type MapInstanceProps = {
  googleMapsInstance: google.maps.Map;
  shouldForceZoom: boolean;
  zoom?: number;
};
// Used to hook into the google maps instance set up by Algolia
// Allows tweaking of functionality such as forcing a zoom level after mount
const MapInstance: React.FC<MapInstanceProps> = ({
  googleMapsInstance: instance,
  shouldForceZoom = false,
  zoom = 18,
}) => {
  useEffect(() => {
    const forceZoom = () => {
      instance.setZoom(zoom);
    };

    const onMapReady = () => {
      const initialZoom = instance.getZoom();
      if (shouldForceZoom && initialZoom !== zoom) setTimeout(forceZoom, 0);
    };

    google.maps.event.addListenerOnce(instance, 'idle', onMapReady);
  }, []);

  return null;
};

const MapInstanceWrapped: React.FC<Omit<
  MapInstanceProps,
  'googleMapsInstance'
>> = withGoogleMaps(MapInstance);

// Utility component to display a Google Maps instance with search results on it
// The component is separated and memo-ized (using React.memo) because any re-render of the map would reset the map viewport
// See: https://github.com/algolia/react-instantsearch/blob/dbff0f9fbdc951214ab4de474cb534baf9c46663/packages/react-instantsearch-dom-maps/src/Provider.js#L114
const Map: React.FC<MapProps> = React.memo(
  ({ searchState, refinementList, onSelectService }) => {
    return (
      <ServiceSearch>
        <Configure {...searchState} />
        <VirtualFilters refinementList={refinementList} />
        <GoogleMapsLoader apiKey={process.env.GOOGLE_MAPS_API_KEY}>
          {(google: unknown) => {
            return (
              <GeoSearch
                google={google}
                enableRefine={false}
                enableRefineOnMapMove={false}
                // Co-ordinates of Melbourne according to Google
                initialPosition={{ lat: -37.8136, lng: 144.9631 }}
                initialZoom={10}
              >
                {({ hits }: { hits: ServiceHit[] }) => (
                  <>
                    {/* if there's only one hit force a lower zoom level */}
                    <MapInstanceWrapped shouldForceZoom={hits.length === 1} />
                    {hits.map((hit) => (
                      <CustomMarker
                        key={hit.objectID}
                        hit={hit}
                        onClick={({
                          marker,
                        }: {
                          event: MouseEvent;
                          marker: google.maps.Marker;
                        }) => {
                          onSelectService(hit, marker);
                        }}
                      >
                        <Flex
                          alignItems="center"
                          height={rem('50px')}
                          justifyContent="center"
                          position="relative"
                          width={rem('42px')}
                        >
                          <Icon
                            name="location"
                            color="white"
                            marginBottom={rem('10px')}
                            size={rem('20px')}
                            zIndex={1}
                          />
                          <Image
                            src={require('@/images/map-marker-frame.svg')}
                            height="100%"
                            left={0}
                            position="absolute"
                            top={0}
                            width="100%"
                            ignoreFallback
                          />
                        </Flex>
                      </CustomMarker>
                    ))}
                  </>
                )}
              </GeoSearch>
            );
          }}
        </GoogleMapsLoader>
      </ServiceSearch>
    );
  }
);

const SearchResultsMapModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  pageSearchState,
}) => {
  // Adjust the search state passed in by the page to only return results with co-ords
  // Also max out the "hitsPerPage" option so that as many locations are shown as possible
  const searchState = useMemo(
    () => ({
      query: pageSearchState.query,
      page: 0,
      hitsPerPage: 1000,
      filters: pageSearchState.filters
        ? `(${pageSearchState.filters}) AND has_coords`
        : 'has_coords',
    }),
    [pageSearchState]
  );

  // Use CSS calc with the modal margins to calculate the height/width
  // 100% height/width with margins would cause the modal to overflow the screen
  const margin = { base: 0, md: 8, lg: 24 };
  const size = {
    base: '100%',
    md: `calc(100% - ${spacing['8']} * 2)`,
    lg: `calc(100% - ${spacing['24']} * 2)`,
  };

  // Track the currently selected service to display the relevant information
  const [selected, setSelected] = useState<ServiceHit | null>(null);
  // Unselect the service when the modal is closed
  useEffect(() => {
    if (!isOpen) setSelected(null);
  }, [isOpen]);

  // When a service is selected, start tracking the marker so we can position our popover element
  const [markerPos, setMarkerPos] = useState({ x: -1000, y: -1000 });
  const mapRef = useRef<google.maps.Map | undefined>(undefined);
  const mapContainerEl = useRef<HTMLDivElement | undefined>(undefined);
  const mapListenersRef = useRef<google.maps.MapsEventListener[]>([]);
  const mapIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const onSelectService = useCallback(
    (service: ServiceHit, marker: google.maps.Marker) => {
      const map = marker.getMap() as google.maps.Map;
      mapRef.current = map;

      setSelected(service);

      // Remove any existing map event listeners
      mapListenersRef.current.forEach((l) => l.remove());
      // Cancel any existing interval timer
      if (mapIntervalRef.current) {
        clearInterval(mapIntervalRef.current);
        mapIntervalRef.current = null;
      }

      // Track the position of the service marker to update the position of our popover
      const onMapPositionChanged = () => {
        if (!mapContainerEl.current) return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const markerElement = (marker as any).element as HTMLDivElement;
        const markerRect = markerElement.getBoundingClientRect();
        const containerRect = mapContainerEl.current.getBoundingClientRect();
        setMarkerPos({
          x: markerRect.left + markerRect.width / 2 - containerRect.left,
          y: markerRect.top - containerRect.top,
        });
      };
      onMapPositionChanged();

      // Whenever the map is moved, start checking the marker position 30 times per second
      const onBoundsChanged = () => {
        if (!mapIntervalRef.current) {
          onMapPositionChanged();
          mapIntervalRef.current = setInterval(onMapPositionChanged, 1000 / 30);
        }
      };

      // Whenever the map comes to rest, stop checking the marker position
      const onIdle = () => {
        onMapPositionChanged();
        if (mapIntervalRef.current) {
          clearInterval(mapIntervalRef.current);
          mapIntervalRef.current = null;
        }
      };

      // Set up the event listeners
      mapListenersRef.current = [
        map.addListener('bounds_changed', onBoundsChanged),
        map.addListener('idle', onIdle),
      ];
    },
    []
  );

  // When a map marker is selected, pan the map to display it
  const modalEl = useRef<HTMLDivElement | undefined>(undefined);
  const popoverEl = useRef<HTMLDivElement | undefined>(undefined);
  useEffect(() => {
    if (
      !selected ||
      !popoverEl.current ||
      !modalEl.current ||
      !mapRef.current ||
      !selected._geoloc
    ) {
      return;
    }

    const map = mapRef.current;
    const { lat, lng } = selected._geoloc;
    const center = new google.maps.LatLng({ lat, lng });

    // On mobile just center the place marker on the map
    if (isMobile()) {
      map.panTo(center);
      return;
    }

    // On tablet/desktop, we need to adjust the center so the top of our popover aligns with the bottom of the close button
    // This is an attempt to ensure the popover and marker both fit on the screen
    const zoom = map.getZoom();
    const projection = map.getProjection();
    if (!projection) return;

    // Get all the necessary pixel values we need
    const modalHeight = modalEl.current.getBoundingClientRect().height;
    const popoverHeight = popoverEl.current.getBoundingClientRect().height;
    const markerHeightPx = 50;
    const closeButtonHeightPx = 48;
    const modalPaddingPx = isTablet() ? 24 : 32;
    const spaceBetweenMarkerAndPopoverPx = 16;

    // Calculate how many pixels we need to offset the marker to correctly align our popover
    const offsetY =
      modalHeight / 2 -
      (popoverHeight +
        closeButtonHeightPx +
        modalPaddingPx +
        spaceBetweenMarkerAndPopoverPx +
        markerHeightPx);

    // Adjust our center co-ordinate using the offset before panning to it
    // Based on: https://stackoverflow.com/a/10722973
    const centerPoint = projection.fromLatLngToPoint(center);
    centerPoint.y += offsetY / 2 ** zoom;
    map.panTo(projection.fromPointToLatLng(centerPoint));
  }, [selected]);

  // Remove any map event listeners when a service is de-selected
  useEffect(() => {
    if (!selected) mapListenersRef.current.forEach((l) => l.remove());
  }, [selected]);

  // Remove any map event listeners when this component is unmounted
  useEffect(() => {
    return () => mapListenersRef.current.forEach((l) => l.remove());
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="lately.black40%" />
      <ModalContent
        ref={modalEl}
        backgroundColor="lately.background"
        borderRadius="2px"
        marginX="auto"
        marginY={margin}
        height={size}
        maxWidth={rem('1036px')}
        overflow="hidden"
        position="relative"
        width={size}
        css={css`
          .ais-GeoSearch {
            left: 0;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
          }

          .ais-GeoSearch-map {
            height: 100%;
          }

          // Move the zoom controls to the bottom right of the modal
          // Adjust the distance from the edge to match the close button
          .gm-bundled-control {
            bottom: ${spacing['4']};
            left: auto !important;
            margin: 0 !important;
            right: ${spacing['4']};
            top: auto !important;

            @media (min-width: ${breakpoints.md}) {
              bottom: ${spacing['6']};
              right: ${spacing['6']};
            }

            @media (min-width: ${breakpoints.lg}) {
              bottom: ${spacing['8']};
              right: ${spacing['8']};
            }

            & > div:first-of-type {
              position: relative !important;
            }
          }
        `}
      >
        <Button
          aria-label="Close"
          onClick={onClose}
          borderRadius="2px"
          height={rem('48px')}
          paddingX={0}
          paddingY={0}
          position="absolute"
          right={{ base: 4, md: 6, lg: 8 }}
          top={{ base: 4, md: 6, lg: 8 }}
          width={rem('48px')}
          zIndex={1}
        >
          <Icon name="close" color="white" size={rem('14px')} />
        </Button>
        <Flex direction="column" flexGrow={1}>
          <Flex
            direction="column"
            flexGrow={1}
            position="relative"
            ref={mapContainerEl}
          >
            <Map
              searchState={searchState}
              refinementList={pageSearchState.refinementList}
              onSelectService={onSelectService}
            />
            {selected && (
              <SearchResultsMapCard
                ref={popoverEl}
                display={{ base: 'none', md: 'flex' }}
                left={markerPos.x}
                position="absolute"
                top={markerPos.y - 16}
                transform="translateX(-50%) translateY(-100%)"
                width={rem('374px')}
                willChange="left, top"
                id={String(selected.id)}
                href={`/services/${selected.slug}`}
                title={selected.title ?? ''}
                provider={selected?.provider ?? ''}
                description={selected.description ?? ''}
                onClose={() => setSelected(null)}
              />
            )}
          </Flex>
          <Collapse isOpen={selected !== null}>
            <SearchResultsMapCard
              display={{ base: 'flex', md: 'none' }}
              id={String(selected?.id)}
              href={`/services/${selected?.slug ?? ''}`}
              title={selected?.title ?? ''}
              provider={selected?.provider ?? ''}
              description={selected?.description ?? ''}
              onClose={() => setSelected(null)}
            />
          </Collapse>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SearchResultsMapModal;
