import removeMarkdown from 'remove-markdown';

// Strip Markdown from the text and truncate it if needed before displaying it
const getPlainText = (text: string, maxLength?: number) => {
  const plainText = removeMarkdown(text);
  if (maxLength && plainText.length > maxLength) {
    return `${plainText.slice(0, maxLength - 3)}...`;
  }
  return plainText;
};

export default getPlainText;
