import React from 'react';
import { rem } from 'polished';
import { Divider, Flex, Heading, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps, HeadingProps } from '@chakra-ui/core';

import * as textStyles from '@/theme/textStyles';

export type ContactDetailsProps = {
  website?: string;
  emailAddresses: string[];
  phoneNumbers: {
    label?: string;
    number: string;
  }[];
  postalAddress?: string;
} & FlexProps;

const ContactDetails: React.FC<ContactDetailsProps> = ({
  website,
  emailAddresses,
  phoneNumbers,
  postalAddress,
  ...rest
}) => {
  if (
    !website &&
    !emailAddresses.length &&
    !phoneNumbers.length &&
    !postalAddress
  )
    return null;

  const hasPhoneLabels = phoneNumbers.some((p) => Boolean(p.label));

  const Subheading: React.FC<HeadingProps> = (props) => (
    <Heading as="h3" {...textStyles.h4} {...props} />
  );

  return (
    <Flex
      backgroundColor="lately.core02"
      borderRadius={{ base: 0, md: '2px' }}
      color="lately.background"
      direction="column"
      width="100%"
      {...rest}
    >
      <Stack spacing={{ base: 6, lg: 10 }}>
        <Heading as="h2" {...textStyles.h2}>
          Contact details
        </Heading>
        {website && (
          <Stack spacing={4}>
            <Subheading>Website:</Subheading>
            <Text {...textStyles.h5} wordBreak="break-word">
              {website}
            </Text>
            <Link
              href={website}
              isExternal
              {...textStyles.h5}
              textDecoration="underline"
              _hover={{ textDecoration: 'none' }}
            >
              Visit website
            </Link>
          </Stack>
        )}
        {emailAddresses.length > 0 && (
          <Stack spacing={4}>
            <Subheading>Email address:</Subheading>
            <Stack spacing={2}>
              {emailAddresses.map((email) => (
                <Link
                  key={email}
                  href={`mailto:${email}`}
                  {...textStyles.h5}
                  textDecoration="underline"
                  wordBreak="break-word"
                  _hover={{ textDecoration: 'none' }}
                >
                  {email}
                </Link>
              ))}
            </Stack>
          </Stack>
        )}
        {phoneNumbers.length > 0 && (
          <Stack spacing={4}>
            <Stack isInline spacing={6}>
              {hasPhoneLabels && (
                <Subheading width={{ base: 'auto', lg: '60%' }}>
                  Contact person:
                </Subheading>
              )}
              <Subheading
                display={{
                  base: hasPhoneLabels ? 'none' : 'block',
                  lg: 'block',
                }}
              >
                Phone:
              </Subheading>
            </Stack>
            <Stack spacing={{ base: 6, lg: 2 }}>
              {phoneNumbers.map((phone) => (
                <Flex
                  key={phone.number}
                  direction={{ base: 'column', lg: 'row' }}
                >
                  <Text
                    {...textStyles.h5}
                    display={{
                      base: phone.label ? 'block' : 'none',
                      lg: hasPhoneLabels ? 'block' : 'none',
                    }}
                    flexShrink={0}
                    marginBottom={{ base: 2, lg: 0 }}
                    marginRight={{ base: 0, lg: 6 }}
                    width={{ base: 'auto', lg: '60%' }}
                    wordBreak="break-word"
                  >
                    {phone.label}
                  </Text>
                  <Link
                    href={`tel:${phone.number}`}
                    {...textStyles.h5}
                    flexShrink={1}
                    textDecoration="underline"
                    wordBreak="break-word"
                    _hover={{ textDecoration: 'none' }}
                  >
                    {phone.number}
                  </Link>
                </Flex>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
      {postalAddress && (
        <Stack marginTop={{ base: rem('72px'), lg: 10 }} spacing={6}>
          <Divider
            borderColor="lately.background"
            borderBottomWidth="2px"
            marginTop={0}
            opacity={0.2}
          />
          <Stack spacing={1}>
            <Subheading {...textStyles.h5}>Postal address:</Subheading>
            <Text {...textStyles.body} wordBreak="break-word">
              {postalAddress}
            </Text>
          </Stack>
        </Stack>
      )}
    </Flex>
  );
};

export default ContactDetails;
