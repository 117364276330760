import React from 'react';
import { Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { TextBlock } from '@/components/ui';
import { FlexGridRow, FlexGridItem } from '@/components/layout';
import type { TextProps } from '@/components/ui';

// Labelled tuple type, see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-0.html#labeled-tuple-elements
export type TwoColTextSectionProps = {
  data: [left: TextProps, right?: TextProps];
} & FlexProps;

const TwoColTextSection: React.FC<TwoColTextSectionProps> = ({
  data,
  ...rest
}) => {
  const [leftColumn, rightColumn] = data;

  if (rightColumn !== undefined) {
    return (
      <FlexGridRow {...rest}>
        <FlexGridItem
          colWidth={{ base: 4, md: 2, lg: 6 }}
          pr={{ base: 0, md: 8, lg: 16 }}
          flexDirection="column"
        >
          <TextBlock {...leftColumn} />
        </FlexGridItem>
        {rightColumn && (
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 6 }}
            flexDirection="column"
            marginTop={{ base: 6, md: 0 }}
            pr={{ base: 0, md: 8, lg: 16 }}
          >
            <TextBlock {...rightColumn} />
          </FlexGridItem>
        )}
      </FlexGridRow>
    );
  }

  return (
    <Flex {...rest}>
      <TextBlock {...leftColumn} maxWidth={{ base: '560px' }} />
    </Flex>
  );
};

export default TwoColTextSection;
