import React from 'react';
import { Box, Flex, IconButton, Link } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { rem } from 'polished';
import gridConfig from '@/theme/grid';
import * as textStyles from '@/theme/textStyles';

type Props = {
  link?: {
    text: string;
    url: string;
  };
  onClose: () => void;
} & FlexProps;

const SiteNotice: React.FC<Props> = ({ children, link, onClose, ...rest }) => {
  return (
    <Flex
      backgroundColor="lately.core02"
      borderRadius={{ base: 0, lg: '2px' }}
      color="lately.background"
      width="100%"
      {...rest}
    >
      <Box
        {...textStyles.h5}
        flexGrow={1}
        paddingX={{
          base: gridConfig.margin.base,
          md: gridConfig.margin.md,
          lg: `${parseFloat(gridConfig.margin.lg) / 2}rem`,
        }}
        paddingY={4}
      >
        {children}
        {link && (
          <>
            &nbsp;
            <Link
              href={link.url}
              textDecoration="underline"
              _hover={{
                color: '#D3D4DB',
                textDecoration: 'none',
              }}
            >
              {link.text}
            </Link>
          </>
        )}
      </Box>
      <IconButton
        aria-label="Close"
        variant="unstyled"
        icon="close"
        display="flex"
        flexShrink={0}
        fontSize={rem('13px')}
        height="auto"
        padding={5}
        _hover={{
          color: '#D3D4DB',
        }}
        onClick={onClose}
      />
    </Flex>
  );
};

export default SiteNotice;
