import { breakpoints } from '@/theme/chakra';

export const isDesktop = () => {
  const query = `(min-width: ${breakpoints.lg})`;
  const is = window.matchMedia(query).matches;
  return is;
};

export const isTablet = () => {
  const query = `(min-width: ${breakpoints.md})`;
  const is = window.matchMedia(query).matches;
  return is && !isDesktop();
};

export const isMobile = () => {
  const query = `(min-width: ${breakpoints.sm})`;
  const is = window.matchMedia(query).matches;
  return is && !isTablet() && !isDesktop();
};
