import React from 'react';
import Head from 'next/head';

export type MetaTagProps = {
  title: string;
  description: string;
  image?: string;
  skipTitleSuffix?: boolean;
};

const generateMetaTags = ({
  title,
  description,
  image = require('@/images/meta-default.png'),
  skipTitleSuffix = false,
}: MetaTagProps) => {
  const fullTitle = skipTitleSuffix ? title : `${title} - Precious Time`;
  const fullImageUrl = image.startsWith('/')
    ? `${process.env.SITE_URL}${image}`
    : image;

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta
        name="facebook-domain-verification"
        content="d9zmajc9t24q1lc03j0y7qfhqzf7gt"
      />
      <meta name="title" content={fullTitle} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={fullImageUrl} />
    </Head>
  );
};

export const get404MetaTags = (description: string) => {
  return generateMetaTags({
    title: 'Page not found',
    description: description,
  });
};

export default generateMetaTags;
