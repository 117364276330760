import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Flex, Icon, Image, Link, Text, useDisclosure } from '@chakra-ui/core';
import NextLink from 'next/link';
import type { FlexProps } from '@chakra-ui/core';
import { rem } from 'polished';

import gridConfig from '@/theme/grid';
import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import { useSavedContent, useSearchModal } from '@/components/providers';
import type { NavItemType } from '@/utils/types';
import useHasScrolled from '@/utils/useHasScrolled';

import MenuDrawer from './MenuDrawer';
import { NavItemLink, NavItemSelect } from './NavItems';

export type PrimaryNavigationProps = {
  navItems: NavItemType[];
  logoImageUrl: string;
  description: string;
  activeRoute?: string;
  hasSavedItems?: boolean;
  onOpenSearchModal?: () => void;
} & FlexProps;

/**
 * PrimaryNavigationContainer
 * This is a separate component so that the `useHasScrolled` hook doesn't re-render the entire tree
 */

const PrimaryNavigationContainer: React.FC<FlexProps> = React.memo(
  function PrimaryNavigationContainer({ children, ...rest }) {
    const hasScrolled = useHasScrolled({ limit: 'throttle', wait: 100 });
    return (
      <Flex
        as="nav"
        display="flex"
        bg="lately.background"
        width="100%"
        alignItems="center"
        flexShrink={0}
        alignContent="center"
        direction="column"
        boxShadow={{
          base: '',
          lg: hasScrolled ? '0px 0px 24px 5px rgba(36, 36, 36, 0.06)' : '',
        }}
        transition="box-shadow 0.3s"
        {...rest}
      >
        {children}
      </Flex>
    );
  }
);

/**
 * PrimaryNavigation
 */

const PrimaryNavigation: React.FC<PrimaryNavigationProps> = React.memo(
  function PrimaryNavigation({
    navItems,
    logoImageUrl,
    description,
    activeRoute = '',
    hasSavedItems = false,
    onOpenSearchModal = () => {},
    children,
    ...rest
  }) {
    const [currentOpenMenu, setCurrentOpenMenu] = useState<string>('');

    const menuDrawer = useDisclosure();
    return (
      <PrimaryNavigationContainer {...rest}>
        <Flex
          maxWidth="contentMax"
          paddingX={gridConfig.margin}
          paddingRight={{ base: 0, md: 0 }}
          width="100%"
          height={{ base: rem('56px'), lg: rem('96px') }}
          position="relative"
        >
          {/* SKIP TO MAIN CONTENT */}
          <Link
            {...textStyles.h5}
            alignItems="center"
            display="flex"
            href="#main"
            backgroundColor="lately.background"
            left={{ base: 0, lg: 12 }}
            height="100%"
            paddingX={{ base: 2, md: 12, lg: rem('135px') }}
            position="absolute"
            textDecoration="underline"
            top="-9999px"
            zIndex={1}
            _focus={{ boxShadow: 'outline', top: 0 }}
          >
            Skip to main content
          </Link>
          {/* LOGO */}
          <Flex flexGrow={0} flexShrink={1}>
            <Flex alignItems="center" marginY={{ base: 'auto', md: 'inherit' }}>
              <NextLink href="/" passHref>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link display="block">
                  <Image
                    alt="Precious Time Logo"
                    src={logoImageUrl}
                    ignoreFallback
                    margin="auto"
                    height={{
                      sm: rem('30px'),
                      lg: rem('38px'),
                      xl: rem('43px'),
                    }}
                    minWidth={rem('86.391px')}
                  />
                </Link>
              </NextLink>
              <Text
                {...textStyles.smallData}
                display={{ base: 'none', xl: 'inline' }}
                paddingLeft={rem('24px')}
              >
                {description}
              </Text>
            </Flex>
          </Flex>

          {/* ALL MENU ITEMS */}
          <Flex
            flexGrow={1}
            flexShrink={0}
            display={{ base: 'none', lg: 'flex' }}
            width={{ base: 'auto' }}
            justifyContent="flex-end"
            alignItems="center"
          >
            {navItems.map((navItem) => {
              let isActive = false;
              if (
                navItem.url &&
                activeRoute !== '' &&
                activeRoute.startsWith(navItem.url)
              ) {
                isActive = true;
              }

              if (navItem.links !== undefined) {
                return (
                  <NavItemSelect
                    key={navItem.name}
                    navItem={navItem}
                    isActive={isActive}
                    isOpen={navItem.name === currentOpenMenu}
                    onSetOpen={(name: string, open: boolean) => {
                      if (open) {
                        setCurrentOpenMenu(name);
                      } else {
                        setCurrentOpenMenu('');
                      }
                    }}
                  />
                );
              }

              return (
                <NavItemLink
                  key={navItem.name}
                  navItem={navItem}
                  isActive={isActive}
                  hasBadge={navItem.url === '/saved' && hasSavedItems}
                />
              );
            })}
            {/* SEARCH BUTTON */}
            <Button
              aria-label="Search"
              onClick={onOpenSearchModal}
              marginLeft={rem('15px')}
              marginY="auto"
              height={rem('42px')}
              width={rem('42px')}
              paddingX={0}
              paddingY={0}
              variant="primary"
            >
              <Icon
                name="search"
                width={rem('24px')}
                height={rem('24px')}
                aria-label="Search"
              />
            </Button>
          </Flex>

          {/* MENU BUTTON AND DRAWER FOR MOBILE */}
          <Flex
            display={{ base: 'flex', lg: 'none' }}
            flexGrow={1}
            flexShrink={0}
            justifyContent="flex-end"
            flexWrap="nowrap"
          >
            <Button
              display={{ base: 'flex', lg: 'none' }}
              onClick={menuDrawer.onOpen}
              alignItems="center"
              flexWrap="nowrap"
              height="100%"
              px={4}
              py={2}
              mr={0}
              variant="secondary"
              borderRadius={0}
            >
              <Text {...textStyles.body} fontWeight={700}>
                Menu
              </Text>
            </Button>
            <Button
              display={{ base: 'flex', lg: 'none' }}
              aria-label="Search"
              onClick={onOpenSearchModal}
              height="100%"
              px={4}
              py={4}
              variant="primary"
              borderRadius={0}
            >
              <Icon
                name="search"
                width={rem('24px')}
                height={rem('24px')}
                aria-label="Search"
              />
            </Button>
            <MenuDrawer
              navItems={navItems}
              isOpen={menuDrawer.isOpen}
              onClose={menuDrawer.onClose}
              logoImageUrl={logoImageUrl}
              hasSavedBadge={hasSavedItems}
            />
          </Flex>
        </Flex>
      </PrimaryNavigationContainer>
    );
  }
);

/**
 * ConnectedPrimaryNavigation
 * Extracts only the state we need out of the context to prevent unnecessary re-renders of the main component
 */

const ConnectedPrimaryNavigation: React.FC<PrimaryNavigationProps> = React.memo(
  function ConnectedPrimaryNavigation(props) {
    const searchModal = useSearchModal();

    // Get the current path name and remove the query string
    const router = useRouter();
    const activeRoute = (router?.asPath ?? '').split('?')[0];

    // Check whether the saved content has any items in it
    const savedContent = useSavedContent();
    const hasSavedItems = savedContent.savedItems.length > 0;

    return (
      <PrimaryNavigation
        {...props}
        activeRoute={activeRoute}
        hasSavedItems={hasSavedItems}
        onOpenSearchModal={searchModal.onOpen}
      />
    );
  }
);

export default ConnectedPrimaryNavigation;
