import React from 'react';
import type { ReactHTML } from 'react';
import { List, Heading, Text } from '@chakra-ui/core';
import type {
  BoxProps,
  HeadingProps,
  LinkProps,
  PseudoBoxProps,
} from '@chakra-ui/core';

import * as textStyles from '@/theme/textStyles';
import { BulletpointListItem, MarkdownLink } from '@/components/ui';

type MarkdownComponentProps = {
  type: keyof ReactHTML;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  headingProps?: HeadingProps;
  h1Props?: HeadingProps;
  h2Props?: HeadingProps;
  h3Props?: HeadingProps;
  h4Props?: HeadingProps;
  h5Props?: HeadingProps;
  pProps?: BoxProps;
  spanProps?: BoxProps;
  aProps?: LinkProps;
  ulProps?: BoxProps;
  liProps?: PseudoBoxProps;
};

const MarkdownComponent: React.FC<MarkdownComponentProps> = ({
  type,
  props,
  children,
  headingProps,
  h1Props,
  h2Props,
  h3Props,
  h4Props,
  h5Props,
  pProps,
  spanProps,
  aProps,
  ulProps,
  liProps,
}) => {
  const { href } = props;
  switch (type) {
    case 'h1':
      return (
        <Heading as="h1" {...textStyles.h1} {...headingProps} {...h1Props}>
          {children}
        </Heading>
      );
    case 'h2':
      return (
        <Heading as="h2" {...textStyles.h2} {...headingProps} {...h2Props}>
          {children}
        </Heading>
      );
    case 'h3':
      return (
        <Heading as="h3" {...textStyles.h3} {...headingProps} {...h3Props}>
          {children}
        </Heading>
      );
    case 'h4':
      return (
        <Heading as="h4" {...textStyles.h4} {...headingProps} {...h4Props}>
          {children}
        </Heading>
      );
    case 'h5':
      return (
        <Heading as="h5" {...textStyles.h5} {...headingProps} {...h5Props}>
          {children}
        </Heading>
      );
    case 'p':
      return (
        <Text {...textStyles.body} marginBottom={6} {...pProps}>
          {children}
        </Text>
      );
    case 'span':
      return (
        <Text {...textStyles.body} marginBottom={6} {...spanProps}>
          {children}
        </Text>
      );
    case 'a':
      return (
        <MarkdownLink href={href} {...aProps}>
          {children}
        </MarkdownLink>
      );
    case 'ul':
      return (
        <List spacing={3} {...ulProps}>
          {children}
        </List>
      );
    case 'li':
      return (
        <BulletpointListItem marginBottom={3} key={children} {...liProps}>
          {children}
        </BulletpointListItem>
      );
    default:
      return <>{React.createElement(type, props, children)}</>;
  }
};

export default MarkdownComponent;
