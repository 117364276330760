import { useEffect, useState } from 'react';

type Stickyfill = typeof import('stickyfilljs');

// Dynamically import the "stickyfilljs" polyfill for browsers that need it
const useStickyfill = () => {
  const [stickyfill, setStickyfill] = useState<Stickyfill | null>(null);

  useEffect(() => {
    if (window.CSS?.supports?.('position', 'sticky')) {
      return;
    }

    const importStickyFill = async () => {
      setStickyfill(await import('stickyfilljs'));
    };
    importStickyFill();
  }, []);

  return stickyfill;
};

export default useStickyfill;
