/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react';
import { keyframes, jsx } from '@emotion/core';
import throttle from 'lodash.throttle';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  PseudoBox,
  Text,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import { rem } from 'polished';
import NextLink from 'next/link';

import * as textStyles from '@/theme/textStyles';
import { Button } from '@/components/ui';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

type QuestionProps = {
  questions: string[];
};

const FormattedQuestions: React.FC<QuestionProps> = ({ questions }) => {
  return (
    <>
      {questions.map((q) => {
        return (
          <Text
            key={q}
            {...textStyles.body}
            fontStyle="italic"
            marginBottom={4}
          >
            {`“${q}”`}
          </Text>
        );
      })}
    </>
  );
};

type CardHeaderProps = {
  heading: string;
};

const CardHeader: React.FC<CardHeaderProps> = ({ heading }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-start"
      marginBottom={4}
    >
      <Box flexShrink={1}>
        <Heading as="h2" {...textStyles.smallData} marginBottom={2}>
          Conversation starters
        </Heading>
        <Box position="relative" width="100%">
          <Heading as="h3" {...textStyles.h4} animation={`${fadeIn} 1s`}>
            {heading}
          </Heading>
        </Box>
      </Box>
      <Image
        src={require('@/images/illustration-conversation-card.svg')}
        alt=""
        height={rem('56px')}
        width={rem('56px')}
        marginLeft={5}
        ignoreFallback
        flexShrink={0}
      />
    </Flex>
  );
};

export type ConversationCardProps = {
  heading: string;
  description: string;
  questions: string[];
} & FlexProps;

const ConversationCard: React.FC<ConversationCardProps> = ({
  heading,
  description,
  questions,
  children,
  ...rest
}) => {
  const [startingHeight, setStartingHeight] = useState<number>(1);
  const [endingHeight, setEndingHeight] = useState<number>(1);
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  const [isSideA, setIsSideA] = useState(true);
  const handleToggle = () => setIsSideA(!isSideA);

  const cardDescription = useRef<HTMLDivElement | null>(null);
  const cardQuestions = useRef<HTMLDivElement | null>(null);

  const updateCardDescriptionHeight = () => {
    if (!window || cardDescription.current === null) return;
    const descriptionHeight = cardDescription.current.clientHeight;
    setStartingHeight(descriptionHeight);
  };

  const updateCardQuestionsHeight = () => {
    if (!window || cardQuestions.current === null) return;
    const questionsHeight = cardQuestions.current.clientHeight;
    setEndingHeight(questionsHeight);
  };

  const updateCardFocus = () => {
    // move the focus from the button clicked to the next bit of text that should be read
    if (isSideA && cardDescription.current !== null) {
      cardDescription.current.focus();
    }
    if (!isSideA && cardQuestions.current !== null) {
      cardQuestions.current.focus();
    }
  };

  useEffect(() => {
    // Set the initial starting and ending height of a card when collapsed or open
    updateCardDescriptionHeight();
    // update again when fonts have loaded in as they may change the height
    if ('fonts' in document) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/fonts#doing_operation_after_all_fonts_are_loaded
      // https://github.com/Microsoft/TypeScript/issues/30984
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document as any).fonts.ready.then(() => {
        updateCardDescriptionHeight();
        updateCardQuestionsHeight();
      });
    }
    // and update the height settings of the card container
    const updateHeights = () => {
      updateCardDescriptionHeight();
      updateCardQuestionsHeight();
    };
    const throttledUpdate = throttle(updateHeights, 100);
    window.addEventListener('resize', throttledUpdate);
    return () => {
      window.removeEventListener('resize', throttledUpdate);
    };
  }, []);

  useEffect(() => {
    // and update the focus and the height settings of the card container when the card is 'flipped'
    if (firstRender) return;
    updateCardDescriptionHeight();
    updateCardQuestionsHeight();
    updateCardFocus();
  }, [isSideA]);

  return (
    <Flex
      backgroundColor="lately.core"
      flexDirection="column"
      borderRadius="2px"
      paddingX={6}
      paddingY={8}
      {...rest}
    >
      <Flex
        // eslint-disable-next-line no-nested-ternary
        height={firstRender ? 'auto' : isSideA ? startingHeight : endingHeight}
        direction="column"
        transition="height 500ms"
        overflow="hidden"
      >
        <PseudoBox
          ref={cardDescription}
          flexShrink={0}
          width="100%"
          tabIndex={-1}
          _focus={{ outline: 'none' }}
        >
          {isSideA && (
            <>
              <CardHeader heading={heading} />
              <Text paddingBottom={6} animation={`${fadeIn} 1s`}>
                {description}
              </Text>
              <Flex animation={`${fadeIn} 1s`}>
                <Button onClick={() => handleToggle()}>
                  What should I say?
                </Button>
              </Flex>
            </>
          )}
        </PseudoBox>
        <PseudoBox
          ref={cardQuestions}
          flexShrink={0}
          width="100%"
          tabIndex={-1}
          _focus={{ outline: 'none' }}
        >
          {!isSideA && (
            <>
              <CardHeader heading="Try asking:" />
              <Box paddingBottom={4} animation={`${fadeIn} 1s`}>
                <FormattedQuestions questions={questions} />
              </Box>
              <Flex flexWrap="wrap" animation={`${fadeIn} 1s`}>
                <Button marginRight={4} onClick={() => handleToggle()}>
                  Close
                </Button>
                <NextLink href="/conversation-starters" passHref>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Button
                    as={Link}
                    variant="textLink"
                    {...textStyles.body}
                    display="block"
                    paddingX={rem('10px')}
                    paddingY={2}
                    _hover={{ textDecoration: 'none' }}
                  >
                    More conversations
                  </Button>
                </NextLink>
              </Flex>
            </>
          )}
        </PseudoBox>
      </Flex>
    </Flex>
  );
};

export default ConversationCard;
