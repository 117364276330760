import React, { useEffect, useRef, useState } from 'react';
import { rem } from 'polished';
import {
  Flex,
  Heading,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/core';

import { spacing } from '@/theme/chakra';
import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  surveyUrl: string;
};

const SurveyModal: React.FC<Props> = React.memo(function SearchModal({
  isOpen,
  onClose,
  surveyUrl,
  ...rest
}) {
  // Show loading text to allow time for the iframe to load
  const [isLoadingIFrame, setIsLoadingIFrame] = useState(true);
  const surveyRef = useRef<HTMLIFrameElement | null>(null);
  useEffect(() => {
    if (isOpen) {
      // setTimeout required for iframe ref to exist before running the onload function
      setTimeout(() => {
        if (surveyRef.current) {
          surveyRef.current.addEventListener('load', () => {
            setIsLoadingIFrame(false);
          });
        }
      }, 0);
    } else {
      setIsLoadingIFrame(true);
    }
  }, [isOpen]);

  // Use CSS calc with the modal margins to calculate the height/width
  // 100% height/width with margins would cause the modal to overflow the screen
  const margin = { base: 8, lg: 24 };
  const size = {
    base: `calc(100% - ${spacing['8']} * 2)`,
    lg: `calc(100% - ${spacing['24']} * 2)`,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay backgroundColor="lately.black40%" />
      <ModalContent
        backgroundColor="lately.background"
        borderRadius="2px"
        marginX="auto"
        marginY={margin}
        height={size}
        maxWidth={rem('1036px')}
        width={size}
      >
        <Flex
          alignItems="center"
          borderTopLeftRadius="2px"
          borderTopRightRadius="2px"
          color="lately.highlights03"
          direction="column"
          flexShrink={0}
          paddingY={{ base: 6, lg: 10 }}
          position="relative"
        >
          <Button
            aria-label="Close"
            onClick={onClose}
            borderRadius="2px"
            height={rem('48px')}
            paddingX={0}
            paddingY={0}
            position="absolute"
            right={{ base: 2, lg: 4 }}
            top={{ base: 2, lg: 4 }}
            width={rem('48px')}
          >
            <Icon name="close" color="white" size={rem('14px')} />
          </Button>
        </Flex>
        <Flex
          paddingTop={{ base: 6 }}
          paddingBottom={{ base: 6, lg: 10 }}
          height="100%"
        >
          {isLoadingIFrame && (
            <Flex
              paddingY={{ base: 6, lg: 10 }}
              position="relative"
              justifyContent="center"
              width="100%"
            >
              <Heading as="h2" {...textStyles.h2}>
                Loading…
              </Heading>
            </Flex>
          )}
          <iframe
            ref={surveyRef}
            title="Survey form"
            src={surveyUrl}
            width={isLoadingIFrame ? '0%' : '100%'}
            height={isLoadingIFrame ? '0%' : '100%'}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          />
        </Flex>
      </ModalContent>
    </Modal>
  );
});

export default SurveyModal;
